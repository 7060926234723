<template>
  <div class="complains-records">
    <header>
      <div class="title">投诉记录</div>
    </header>
    <main>
      <TableTab :tab="tab" :tabIndex="tabIndex" @switchTab="switchTab" />
      <el-table
        class="table"
        :data="tableData"
        border
        style="width: 100%"
        v-loading="Loading"
      >
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="phone" label="时间"> </el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column prop="phone" label="价格"> </el-table-column>
        <el-table-column prop="phone" label="面积"> </el-table-column>
        <el-table-column prop="address" label="委托需求" width="400px">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                v-if="editId !== scope.row.id"
                class="item"
                effect="dark"
                :content="scope.row.remarks"
                placement="bottom-start"
              >
                <div slot="content" style="max-width: 400px">
                  {{ scope.row.remarks }}
                </div>
                <div>
                  {{ scope.row.remarks || '-' }}
                </div>
              </el-tooltip>
              <el-input v-else v-model="editRemarks"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="地区"> </el-table-column>
        <el-table-column prop="customSourceName" label="状态" width="150px">
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          width="240px"
        >
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="details(scope.row)" size="small"
                >查看</el-button
              >
              <el-button type="text" @click="del(scope.row)" size="small"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 记录查看 -->
    <el-dialog
      title="记录查看"
      :visible.sync="remarkVisible"
      width="480px"
      :before-close="remarkHandleClose"
    >
      <div class="body">
        <p class="del">请确认是否删除该条投诉记录？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noDelete">再次申诉</el-button>
        <el-button class="btn" type="primary" @click="onRemark">确定</el-button>
      </span>
    </el-dialog>
    <!-- 记录删除 -->
    <el-dialog
      title="记录删除"
      :visible.sync="deleteVisible"
      width="480px"
      :before-close="deleteHandleClose"
    >
      <div class="body">
        <p class="del">请确认是否删除该条投诉记录？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noDelete">取消</el-button>
        <el-button class="btn" type="primary" @click="yesDelete"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  customPage,
  customDel,
} from 'services/broker'
import TableTab from 'components/TableTab.vue';
export default {
  name: 'ComplainsRecords',
  components: {
    TableTab,
  },
  data () {
    return {
      pickerOptions: {
        disabledDate (v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      name: '',
      phone: '',
      tag: '',
      tab: [
        {
          id: '',
          label: '全部',
          prompt: ''
        },
        {
          id: '1',
          label: '处理中',
          prompt: ''
        },
        {
          id: '2',
          label: '已完成',
          prompt: ''
        },
        {
          id: '3',
          label: '投诉失败',
          prompt: ''
        }
      ],
      tabIndex: '',
      label: '',


      plantList: [],
      time: '',
      value1: [],
      editId: null,
      editName: '',
      editTag: '',
      editRemarks: '',
      tableData: [],
      Loading: false,
      pageSize: 10,
      total: 0,
      day: null,
      dayTotal: 0,
      page: 1,
      selectId: null,
      selectRemark: '',
      remarkVisible: false,
      deleteVisible: false
    };
  },
  filters: {
    labelFilter (label) {
      if (label === '1') return '高意向'
      if (label === '2') return '中意向'
      if (label === '3') return '低意向'
      if (label === '4') return '无意向'
    }
  },
  watch: {
    deleteVisible (val) {
      if (!val) {
        this.deleteVisible = false
      }
    },
  },
  mounted () {
    console.log(this.$route.params);
    if (this.$route.params)
      this.tabIndex = this.$route.params.tabIndex
    this.getDayTotal()
    this.getList()
  },
  methods: {
    getDayTotal () {
      const now = new Date();
      const y = now.getFullYear();
      const m = ('0' + (now.getMonth() + 1)).slice(-2);
      const d = ('0' + now.getDate()).slice(-2);
      this.day = y + m + d
    },
    getList () {
      let params = {
        name: this.name,
        phone: this.phone,
        tag: this.tag,
        page: this.page,
        day: this.tabIndex ? this.day : ''
      }
      this.Loading = true
      customPage(params).then(res => {
        this.Loading = false
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    // tab切换
    switchTab (id) {
      this.tabIndex = id
      this.page = 1;
      this.name = ''
      this.phone = ''
      this.tag = ''
      this.getList()
    },
    // 查看
    details (item) {
      this.selectId = item.id
      this.remarkVisible = true
    },
    del (item) {
      this.selectId = item.id
      this.deleteVisible = true
    },
    remarkHandleClose () {
      this.remarkVisible = false
    },
    onRemark () {
      this.remarkVisible = false
    },
    deleteHandleClose () {
      this.deleteVisible = false
      this.selectId = null
    },
    // 拒绝删除
    noDelete () {
      this.deleteVisible = false
    },
    // 确认删除
    yesDelete () {
      customDel({ id: this.selectId }).then(res => {
        if (res.data.status === 200) {
          this.selectId = null
          this.deleteVisible = false
          this.page = 1
          this.getList()
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.complains-records {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
    }
    .search {
      display: flex;
      align-items: center;
      .inp {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .sel {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .picker {
        width: 270px;
        height: 38px;
        /deep/ .el-date-editor .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    .table {
      .look {
        position: relative;
        .picker {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          opacity: 0;
        }
      }
      .save {
        width: 100px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0;
      }
      .close {
        width: 100px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: #fff;
        font-size: 16px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
        border: 0;
      }
      /deep/ .el-input__inner {
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(245, 245, 245, 1);
        border: 0;
      }
    }
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 600;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .code {
    position: absolute;
    top: 30px;
    right: 40px;
    padding: 10px 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 110px;
      height: 114px;
    }
    p {
      &:first-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
      }
      &:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}

.label {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    margin-right: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
  }
  &-r {
    color: rgb(230, 0, 0) !important;
    &::before {
      background-color: rgb(245, 0, 0) !important;
    }
  }
  &-o {
    color: rgb(217, 109, 59) !important;
    &::before {
      background-color: rgb(217, 109, 59) !important;
    }
  }
  &-y {
    color: rgba(255, 176, 49, 1) !important;
    &::before {
      background-color: rgba(255, 176, 49, 1) !important;
    }
  }
  &-b {
    color: rgba(109, 87, 255, 1) !important;
    &::before {
      background-color: rgba(109, 87, 255, 1) !important;
    }
  }
  &-c {
    color: rgba(153, 153, 153, 1) !important;
    &::before {
      background-color: rgba(153, 153, 153, 1) !important;
    }
  }
}
/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #e6edfa;
}
/deep/ .el-button--text {
  color: #ec6600;
}
/deep/ .el-switch {
  display: flex !important;
  align-items: center;
  position: relative;
  .el-switch__label * {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .el-switch__label--left {
    position: absolute;
    left: 8px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  .el-switch__label--right {
    position: absolute;
    left: 16px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  &.is-checked .el-switch__core {
    &::after {
      top: 5px;
      left: 65px;
    }
  }
  .el-switch__core {
    width: 72px !important;
    height: 28px;
    opacity: 1;
    border-radius: 20px;
    &::after {
      top: 5px;
      left: 5px;
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .del {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .details-item {
    display: flex;
    margin-bottom: 20px;
    &:first-child {
      align-items: center;
      // justify-content: space-between;
    }
    .name {
      display: flex;
      align-items: center;
      margin-right: 100px;
    }
    label {
      width: 80px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(153, 153, 153, 1);
    }
    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .look-house {
      width: calc(100% - 100px);
      max-height: 320px;
      overflow: auto;
    }
    .link-up {
      width: calc(100% - 100px);
      position: relative;
      &::before {
        content: '';
        display: flex;
        width: 2px;
        height: 100%;
        background: #dddddd;
        position: absolute;
        left: 2px;
        z-index: 0;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &::before {
          content: '';
          display: flex;
          width: 7px;
          height: 7px;
          background: rgba(179, 179, 179, 1);
          border-radius: 50%;
          margin-right: 12px;
          z-index: 1;
        }
        .time {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(153, 153, 153, 1);
        }
        .link-up-type {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
          margin: 0 24px;
        }
        .note {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(153, 153, 153, 1);
        }
        .line {
        }
        &.active {
          &::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(236, 102, 0, 0.1);
            border: 3px solid rgba(236, 102, 0, 0.1);
            margin-left: -4px;
          }
          &::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(236, 102, 0, 1);
            margin-left: -4px;
            position: absolute;
            left: 3px;
          }
          .time {
            color: rgba(236, 102, 0, 1);
          }
          .link-up-type {
            color: rgba(236, 102, 0, 1);
          }
          .note {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  