import axios from '../plugins/common';

// import qs from 'qs';

// // form 表单传参
// const form = {
//   transformRequest: [
//     function (data) {
//       data = qs.stringify(data);
//       return data;
//     }
//   ],
//   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// };
// 按树形结构查询所有地区
const areaAllTree = () => axios.get('/area/all/tree');

// 查询所有地区
const areaAll = () => axios.get('/area/all');

// 根据上级查询所有
const getAreaByParent = params => axios.get('/area/getByParent', { params });

// 配套设施-根据类型查询
const facilitiesList = params =>
  axios.get('/build/facilities/list', { params });

// 房源特色-根据类型查询标签
const labelList = params => axios.get('/build/label/list', { params });

export { areaAllTree, areaAll, getAreaByParent, facilitiesList, labelList };
