<template>
  <div class="basic-message">
    <main class="main">
      <div class="main-list">
        <div class="main-list-item" v-for="item in list" :key="item.id">
          <div class="img">
            <img class="img1" :src="item.imgUrl" alt="" />
          </div>
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="time">{{ item.time }}</div>
            <div class="message">
              {{ item.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: 'BasicMessage',
  components: {
  },
  data () {
    return {
      pageSize: 10,
      total: 8,
      currentPage3: 1,
      list: [
        {
          id: 1,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 2,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 3,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 4,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 5,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 6,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 7,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦!请开始您的业务。恭喜....'
        },
        {
          id: 8,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '系统消息',
          time: '2023-10-11 12:54:25',
          message: '恭喜您! 您提交的实名认证已经通过，可以正常进行业务操作啦! 请开始您的业务。'
        }
      ],
      loseVisible: false
    }
  },
  mounted () { },
  methods: {
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
  }
};
</script>

<style lang="less" scoped>
.basic-message {
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  .main {
    width: 100%;
    height: 100%;
    &-list {
      width: 100%;
      overflow: auto;
      height: calc(100% - 60px);
      &-item {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(230, 227, 225, 1);
        .img {
          width: 48px;
          height: 48px;
          opacity: 1;
          background: rgba(247, 151, 25, 1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .content {
          width: calc(100% - 60px);
          .title {
            font-size: 22px;
            font-weight: 500;
            line-height: 32px;
            color: rgba(51, 51, 51, 1);
          }
          .time {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(153, 153, 153, 1);
          }
          .message {
            line-height: 23.17px;
            color: rgba(51, 51, 51, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
        }
      }
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
}
</style>