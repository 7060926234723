<template>
  <div class="map-mark" id="map-mark"></div>
</template>
    
<script>
/*eslint-disable*/
export default {
  name: 'MapMark',
  props: {
    adress: {
      type: Object,
      default: { name: '北京' }
    },
    point: Array
  },
  data () {
    return {
      ak: 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw'
    };
  },
  watch: {
    adress (val) {
      console.log(val);
      if (val.lng) {
        let point = new window.BMapGL.Point(val.lng, val.lat)
        // 初始化地图，设置中心点坐标和地图级别
        this.map.centerAndZoom(point, val.zoom)
      } else {
        var local = new window.BMapGL.LocalSearch(this.map, {
          renderOptions: { map: this.map },
          onSearchComplete: (res) => {
            console.log(res._pois);
            this.$nextTick(() => {
              this.map.clearOverlays(); //清除标注
              if (this.point.length > 0) this.addMark(...this.point)
            })
          },
        });
        local.search(val.name);
      }
    },
    point (val) {
      if (val) {
        this.addMark(...this.point)
      }
    }
  },
  mounted () {
    // 创建地图
    let vm = this
    let map = new window.BMapGL.Map("map-mark");
    map.centerAndZoom(this.adress.name, 12)
    //开启鼠标滚轮缩放
    map.enableScrollWheelZoom(true)
    map.setHeading(0)
    vm.map = map
    map.addEventListener('tilesloaded', function () {
      if (this.point) {
        this.addMark(...this.point)
      }
    });
  },
  methods: {
    // 地图添加点坐标
    addMark (lng, lat, zoom) {
      if (this.marker) this.map.removeOverlay(this.marker);
      this.marker = new BMapGL.Marker(new BMapGL.Point(lng, lat));
      let nowZoom = this.map.getZoom()
      this.map.centerAndZoom(new BMapGL.Point(lng, lat), zoom || nowZoom)
      this.map.addOverlay(this.marker);
      this.$emit('setPoint', [lng, lat])
    },
    clearMark () {
      this.map.removeOverLay(this.marker);
    }
  }
};
</script>
<style lang="less" scoped>
.map-mark {
  width: 100%;
  height: 100%;
}

/deep/ .anchorBL {
  display: none;
}
/deep/ .BMap_cpyCtrl {
  display: none;
}
</style>
    