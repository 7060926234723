<template>
  <div class="b-map" id="container">
    <slot></slot>
  </div>
</template>
    
<script>
/*eslint-disable*/
import { BMPGL } from 'plugins/bmp'
export default {
  name: 'BMap',
  provide () {
    getMap: this.getMap
  },
  props: {
    adress: {
      type: Object,
      default: { name: '北京' }
    },
    zoom: {
      type: Number,
      default: 12,
    }
  },
  data () {
    return {
      ak: 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw',
      point: [],
    };
  },
  watch: {
    adress (val) {
      console.log(val);
      if (val.lng) {
        let point = new window.BMapGL.Point(val.lng, val.lat)
        // 初始化地图，设置中心点坐标和地图级别
        this.map.centerAndZoom(point, val.zoom)
      } else {
        var local = new window.BMapGL.LocalSearch(this.map, {
          renderOptions: { map: this.map },
          onSearchComplete: (res) => {
            console.log(res._pois);
            this.$nextTick(() => {
              this.map.clearOverlays(); //清除标注
              if (this.point.length > 0) this.addMark(...this.point)
            })
          },
        });
        local.search(val.name);
      }
    },
    point (val) {
      console.log('---------', val);
      if (this.map) {
        let point = new window.BMapGL.Point(val[0], val[1])
        this.map.centerAndZoom(point, 16)
        this.marker = new window.BMapGL.Marker(point);
        this.map.addOverlay(this.marker);
      }
    }
  },
  beforeCreate () {
    window._bd_share_main = ""
    window.BMap_loadScriptTime = (new Date()).getTime()
  },
  mounted () {
    let vm = this
    // this.$nextTick(() => {
    //   this.getMap().then(Map => {
    let map = new window.BMapGL.Map("container");
    map.centerAndZoom(this.adress.name, 12)
    //开启鼠标滚轮缩放
    map.enableScrollWheelZoom(true)
    map.setHeading(0)
    vm.map = map
    map.addEventListener('tilesloaded', function () {
      if (this.point) {
        console.log('----', this.point);
        this.addMark(...this.point)
      }
    });
    map.addEventListener('click', function (e) {
      if (vm.marker) {
        vm.map.removeOverlay(vm.marker)
      }
      vm.addMark(e.latlng.lng, e.latlng.lat)
    });

    console.log(map)
    //   })
    // })

  },
  methods: {
    // 初始化地图
    initMap () {
      // 传入密钥获取地图回调。
      let vm = this
      BMPGL(this.ak).then((BMapGL) => {
        // 创建地图实例
        let map = new BMapGL.Map("container");
        // 创建点坐标 axios => res 获取的初始化定位坐标
        // let point = new BMapGL.Point(114.031761, 22.542826)
        // 初始化地图，设置中心点坐标和地图级别
        map.centerAndZoom('北京', 12)
        //开启鼠标滚轮缩放
        map.enableScrollWheelZoom(true)
        map.setHeading(0)
        this.map = map
        //地图点击事件
        map.addEventListener('click', function (e) {
          if (vm.marker) {
            vm.map.removeOverlay(vm.marker)
          }
          vm.addMark(e.latlng.lng, e.latlng.lat)
        });
      })
        .catch((err) => {
          console.log(err)
        })
    },
    getMap () {
      return new Promise(resolve => {
        if (BMapGL && BMapGL.Map) {
          let Map = BMapGL.Map;
          resolve(Map)
        } else {
          let timer = setInterval(() => {
            if (BMapGL && BMapGL.Map) {
              let Map = BMapGL.Map;
              resolve(Map)
              clearInterval(timer)
            }
          })
        }
      })
    },
    clearMark () {
      this.map.removeOverlay(this.marker);
    },
    // 地图添加点坐标
    addMark (lng, lat, zoom) {
      if (this.marker) this.map.removeOverlay(this.marker);
      this.marker = new BMapGL.Marker(new BMapGL.Point(lng, lat));
      let nowZoom = this.map.getZoom()
      this.map.centerAndZoom(new BMapGL.Point(lng, lat), zoom || nowZoom)
      this.map.addOverlay(this.marker);
      this.$emit('setPoint', [lng, lat])
      // this.point = [lng, lat]
    }
  }
};
</script>
<style lang="less" scoped>
.b-map {
  width: 100%;
  height: 100%;
}

/deep/ .anchorBL {
  display: none;
}
/deep/ .BMap_cpyCtrl {
  display: none;
}
</style>
    