<template>
  <div class="CropperDialog">
    <!-- 余额充值 -->
    <el-dialog
      title="图片裁剪"
      :close-on-click-modal="false"
      :visible.sync="showCropper"
      width="800px"
    >
      <div class="body">
        <vue-cropper
          v-if="showCropper"
          id="cropper"
          ref="cropper"
          :class="{ 'corpper-warp': showCropper }"
          v-bind="cropper"
          @imgMoving="imgMoving"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCropper = false">取消</el-button>
        <el-button class="btn" type="primary" @click="finish">上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    VueCropper
  },
  name: 'CropperDialog',
  props: {
  },
  data () {
    return {
      file: {},
      cropperUrl: '', // 裁切后的图片base64
      fileName: '', // 文件名，用于blob转化成file对象
      uploadFile: '', // 点击浏览获取的文件对象
      id: 'cropper-input-' + +new Date(),
      loading: false,
      showCropper: false,
      cropper: {
        img: '',
        info: true,
        size: 0.9,
        outputType: 'png',
        canScale: true,
        autoCrop: true,
        full: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 400,
        autoCropHeight: 300,
        fixedBox: false,
        maxImgSize: 720,
        // 开启宽度和高度比例
        fixed: true,
        fixedNumber: [4, 3],
        original: true,
        canMoveBox: true,
        canMove: true
      }
    };
  },
  watch: {
    showCropper (val) {
      if (!val) {
        this.cropper = {
          img: '',
          info: true,
          size: 0.9,
          outputType: 'png',
          canScale: true,
          autoCrop: true,
          full: true,
          // 只有自动截图开启 宽度高度才生效
          autoCropWidth: 400,
          autoCropHeight: 300,
          fixedBox: false,
          maxImgSize: 720,
          // 开启宽度和高度比例
          fixed: true,
          fixedNumber: [4, 3],
          original: true,
          canMoveBox: true,
          canMove: true
        }
      }
    }
  },
  mounted () {
  },
  destroyed () {
    console.log('裁剪组件注销');
  },
  methods: {
    async getCropperImg (img) {
      let that = this
      console.log('img--->', img);
      this.file = img
      try {
        let reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onloadend = function () {
          let dataURL = reader.result;
          that.showCropper = true
          that.cropper.img = dataURL
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 上传，
    /*
     * 将上传添加到这里来执行
     */
    finish () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        // 注此时的data是一个Blob数据，部分接口接收的是File转化的FormData数据
        let formData = new FormData();
        formData.append(
          "file",
          new File(
            [data], // 将Blob类型转化成File类型
            this.file.name, // 设置File类型的文件名称
            { type: this.file.type } // 设置File类型的文件类型
          )
        );
        this.$emit('subUploadSucceed', formData)
      })
    },
    imgMoving (e) {
      console.log(e);
    }
  }
};
  </script>
  <style lang="less" scoped>
.CropperDialog {
  /deep/ .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .el-dialog__headerbtn {
      font-size: 20px;
    }
  }
  /deep/ .el-dialog__body {
    padding: 20px 40px;
  }
  .body {
    #cropper {
      width: 100%;
      height: 540px;
      margin: 0 auto;
      background-image: none;
      background: #fff;
      z-index: 1002;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .btn {
      background: rgba(236, 102, 0, 1);
      border: 0;
    }
    /deep/ .el-button {
      height: 36px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
  