<template>
  <el-container class="home">
    <el-aside class="aside home_aside">
      <div class="logo">
        <img class="img1" src="../assets/logo.png" alt="" />
        <img src="../assets/亿楼管理系统.png" alt="亿楼管理系统" class="img2" />
      </div>
      <div class="nav">
        <el-menu
          :default-active="defActive"
          class="el-menu-vertical-demo"
          background-color="#F79719"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
          @select="select"
        >
          <el-menu-item
            :class="defActive === '/index' ? 'active' : ''"
            index="/index"
          >
            <i
              class="icon"
              :class="
                defActive === '/index'
                  ? 'icon-nav-home-active'
                  : 'icon-nav-home'
              "
            ></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="icon icon-nav-house"></i>
              <span>房源管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                class="menu-item"
                :class="defActive === '/myHousing' ? 'active' : ''"
                index="/myHousing"
                >我的房源</el-menu-item
              >
              <el-menu-item
                class="menu-item"
                :class="
                  defActive === '/publishHousing' ||
                  defActive === '/publishHousingOffice'
                    ? 'active'
                    : ''
                "
                index="/publishHousing"
                >发布房源</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="icon icon-nav-client"></i>
              <span>客户管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                class="menu-item"
                :class="defActive === '/customerList' ? 'active' : ''"
                index="/customerList"
                >客户列表</el-menu-item
              >
              <!-- <el-menu-item
                class="menu-item"
                :class="defActive === '/entrustLookHouse' ? 'active' : ''"
                index="/entrustLookHouse"
                >委托找房</el-menu-item
              > -->
              <el-menu-item
                class="menu-item"
                :class="defActive === '/tapeRecord' ? 'active' : ''"
                index="/tapeRecord"
                >带看记录</el-menu-item
              >
              <el-menu-item
                class="menu-item"
                :class="defActive === '/recordOfCommun' ? 'active' : ''"
                index="/recordOfCommun"
                >沟通记录</el-menu-item
              >
              <!-- <el-menu-item index="2-4">客户评价</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="icon icon-nav-finance"></i>
              <span>财务管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                class="menu-item"
                :class="defActive === '/bill' ? 'active' : ''"
                index="/bill"
                >我的钱包</el-menu-item
              >
              <el-menu-item
                class="menu-item"
                :class="defActive === '/gold' ? 'active' : ''"
                index="/gold"
                >龙币管理</el-menu-item
              >
              <!-- <el-menu-item
                class="menu-item"
                :class="defActive === '/invoice' ? 'active' : ''"
                index="/invoice"
                >发票管理</el-menu-item
              > -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="icon icon-nav-user"></i>
              <span>个人中心</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                class="menu-item"
                :class="defActive === '/basicInfo' ? 'active' : ''"
                index="/basicInfo"
                >基本信息</el-menu-item
              >
              <!-- <el-menu-item
                class="menu-item"
                :class="defActive === '/basicMessage' ? 'active' : ''"
                index="/basicMessage"
                >消息中心</el-menu-item
              > -->
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </el-aside>
    <el-container class="container">
      <el-header class="header">
        <Header />
      </el-header>
      <el-main class="main">
        <router-view class="page" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from '../components/Header.vue';
import { certificationStatus } from 'services/broker'
export default {
  name: 'Home',
  components: {
    Header
  },
  data () {
    return {
      defActive: '/index'
    }
  },
  watch: {
    $route: {
      handler (now) {
        this.defActive = now.path
      },
      immediate: true
    },
    defActive (val) {
      console.log('------', val);
    }
  },
  created () {
    // console.log('home-created', window.location.hash);
  },
  mounted () {
    // setTimeout(() => {
    //   // this.$autofit.init({
    //   //   // dh: 920,
    //   //   dw: 240,
    //   //   el: '.home_aside',
    //   //   resize: true,
    //   // }, false)
    //   // this.$autofit.init({
    //   //   dh: 920,
    //   //   dw: 1920,
    //   //   el: '.header',
    //   //   resize: true,
    //   // }, false)
    // }, 100)
    this.getStatus()
    // 判断token是否失效
    if (!localStorage.token)
      setTimeout(() => {
        window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
      }, 500)
    let vm = this
    this.$eventBus.$on('eventDefActive', () => {
      vm.defActive = vm.$route.path
    })
  },
  methods: {
    async getStatus () {
      // 判断认证状态
      const res = await certificationStatus()
      console.log('判断认证状态', res);
      if (res.data.status === 200)
        if (res.data.data.status === 1) this.$router.push('/user')
    },
    select (val) {
      this.getStatus()
      console.log(val);
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.header {
  height: 72px !important;
  // margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 0px 0px 12px 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04);
  padding: 0 42px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.main {
  width: 100%;
  height: calc(100% - 72px);
  // margin-right: 20px;
  padding: 20px;
  border-radius: 12px;
  .page {
    background: rgba(255, 255, 255, 1);
    // box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04);
  }
}
.aside {
  width: 240px !important;
  height: 100%;
  background: rgba(247, 151, 25, 1);
  // margin-right: 20px;
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .img1 {
      width: 44px;
      height: 44px;
      margin-bottom: 10px;
    }
    .img2 {
      width: 143px;
      height: 24px;
    }
  }
  .nav {
    // position: absolute;
    // left: 0;
    // right: -5px;
    height: calc(100% - 140px);
    padding-top: 30px;
    padding-left: 30px;
    overflow: auto;
    .menu-item {
      padding-left: 48px !important;
    }

    // 修改滚动条样式
    &::-webkit-scrollbar {
      width: 5px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 200, 255, 0.5);
      background: rgba(255, 255, 255, 1);
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(24, 50, 83, 0.5);
      border-radius: 0;
      background: #fa9623;
    }
  }
}
.container {
  height: 100%;
  display: block;
}

// 导航
.el-menu {
  border: 0;
}
.el-submenu__title * {
  font-size: 18px;
  color: rgba(255, 255, 255, 1) !important;
}
.el-menu-item {
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1) !important;
}
.el-menu-item.active {
  border-radius: 32px 0px 0px 32px;
  background: #faf8f5 !important;
  font-size: 18px;
  color: rgba(51, 34, 15, 1) !important;
  position: relative;
  &::before {
    content: '';
    width: 29px;
    height: 29px;
    display: block;
    position: absolute;
    top: -29px;
    right: 0;
    z-index: 999;
    background: radial-gradient(
      circle at 0% 0%,
      transparent 0,
      transparent 29px,
      #faf8f5 30px,
      #faf8f5
    );
  }
  &::after {
    content: '';
    width: 29px;
    height: 29px;
    display: block;
    position: absolute;
    z-index: 999;
    background: radial-gradient(
      circle at 0% 100%,
      transparent 0,
      transparent 29px,
      #faf8f5 30px,
      #faf8f5
    );
    top: 58px;
    right: 0;
  }
}
/deep/ .el-submenu__title i {
  color: #fff;
  margin-right: 8px;
}
/deep/ .el-menu-item-group__title {
  padding: 0;
}
/deep/ .el-submenu__title.active i {
  color: #000;
}
/deep/ .el-menu-item i {
  color: #fff;
  margin-right: 8px;
}
/deep/ .el-menu-item.active i {
  color: #000;
}
/deep/ .el-submenu__icon-arrow {
  font-size: 18px;
}
/deep/ .el-icon-arrow-down:before {
  content: '\e790' !important;
}

.el-menu-item.active + .el-menu-item {
  border-radius: 0px 32px 0px 0px;
  background: #fa9623;
}
</style>
