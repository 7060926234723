<template>
  <div class="bill">
    <div class="top">
      <div class="left">
        <div class="left-title">
          <span class="text">可用余额</span>
          <span
            :style="{
              transform: `rotate(${refresh}deg)`
            }"
            class="refresh el-icon-refresh"
            @click="withdrawSuccess"
          ></span>
          <span class="withdrawal" v-if="freezeAmountYuan"
            >提现中：{{ freezeAmountYuan }}元</span
          >
          <!-- <span class="warn">?</span> -->
          <div class="warn-dialog">
            <div class="title">尊敬的用户:</div>
            <div class="content">
              当前显示为账户可用余额 提现中的余额可以点击提现
              在提现记录中查看您正在提现 中的余额。
            </div>
          </div>
          <!-- <span class="warn">?</span>
          <div class="warn-dialog">
            <div class="title">尊敬的用户:</div>
            <div class="content">
              您所兑换的龙币，可以用于委托找房和客户首次电话沟通。龙币目前不支持退款以及提现的操作，请谨慎兑换
            </div>
            <div class="title">单次沟通费用：</div>
            <div class="content">电话沟通X龙币 委托找房X龙币</div>
          </div> -->
        </div>
        <div class="left-num">{{ canUseBalanceYuan }}元</div>
        <div class="left-btn flex-align">
          <!-- <el-button class="recharge" type="primary" @click="topUpBalance"
            >充值</el-button
          > -->
          <el-button class="withdraw" @click="toWithdraw">提现</el-button>
          <el-button class="withdraw" @click="toPush('/withdrawalRecord')"
            >提现记录</el-button
          >
        </div>
      </div>
      <div class="right">
        <div class="pie" id="pie"></div>
        <div class="pie-item">
          <div class="item">
            <div class="name" style="padding-left: 22px">总支出</div>
            <div class="num">
              <span style="font-weight: normal; font-size: 24px">￥</span
              >{{ totalBill }}
            </div>
            <div class="percentage"></div>
          </div>
          <div class="item" v-for="item in typeAmounts" :key="item.type">
            <div class="name phone" :class="item.type === 2 ? 'gold' : 'other'">
              {{ item.typeName }}
            </div>
            <div
              class="num"
              :style="{
                color: item.type === 2 ? '#ff8b33' : '#3676f5'
              }"
            >
              <span style="font-weight: normal; font-size: 24px">￥</span
              >{{ item.total }}
            </div>
            <div class="percentage" v-show="totalBill">
              {{ parseInt((item.total / totalBill) * 100) }}%
            </div>
          </div>
        </div>
        <div class="search">
          <el-date-picker
            v-model="totalMonth"
            format="yyyy-MM"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            @change="getCoinBillTotal"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <div class="bottom">
      <header>
        <div class="title">
          <div>账单明细</div>
          <!-- <el-button class="btn" @click="toPush('/billingTitle')"
            >导出账单</el-button
          > -->
        </div>
        <div class="search">
          <el-select
            class="sel"
            style="margin-right: 10px"
            v-model="type"
            placeholder="请选择"
            @change="(page = 1), getCoinBillPage()"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="充值" :value="1"> </el-option>
            <el-option label="购买龙币" :value="2"> </el-option>
            <el-option label="其它" :value="3"> </el-option>
          </el-select>
          <el-date-picker
            v-model="pageMonth"
            format="yyyy-MM"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            :clearable="false"
            @change="(page = 1), getCoinBillPage()"
          >
          </el-date-picker>
        </div>
      </header>
      <main>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="calc(100% - 100px)"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="time" label="时间"> </el-table-column>
          <el-table-column prop="id" label="流水号"> </el-table-column>
          <el-table-column prop="amount" label="金额（元）"> </el-table-column>
          <el-table-column prop="typeName" label="项目"> </el-table-column>
        </el-table>
        <div class="main-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            v-show="pageSize < total"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </main>
    </div>
    <!-- 余额充值 -->
    <BalanceDialog ref="BalanceDialog" :balanceYuan="canUseBalanceYuan" />
    <!-- 提现 -->
    <WithdrawDepositDialog
      ref="WithdrawDepositDialog"
      :balanceYuan="canUseBalanceYuan"
      @withdrawSuccess="withdrawSuccess"
    />
  </div>
</template>
  
  <script>
import * as echarts from 'echarts';
import {
  billTotal,
  billPage,
  billBalance
} from 'services/pay'
import BalanceDialog from 'components/BalanceDialog.vue'
import WithdrawDepositDialog from 'components/WithdrawDepositDialog.vue';
export default {
  name: 'Bill',
  components: {
    BalanceDialog,
    WithdrawDepositDialog
  },
  data () {
    return {
      balance: 0,
      balanceYuan: 0,
      canUseBalanceYuan: 0,
      freezeAmountYuan: 0,
      refresh: 0,
      month: "",
      totalMonth: '',
      pageMonth: '',
      type: '',
      totalBill: 0,
      typeAmounts: [],
      keywords: '',
      value: '',
      value1: [],
      tableData: [],
      pageSize: 10,
      total: 0,
      page: 1,
      multipleSelection: []
    };
  },
  watch: {},
  filters: {
  },
  mounted () {
    // 获取当月
    const now = new Date();
    const y = now.getFullYear();
    const m = ('0' + (now.getMonth() + 1)).slice(-2);
    this.month = y + m
    this.totalMonth = y + m
    this.pageMonth = y + m
    this.getCoinBillTotal()
    this.getCoinBillPage()
    this.getCoinWallet()
  },
  methods: {
    withdrawSuccess () {
      this.refresh -= 180
      this.getCoinWallet()
    },
    // 查询余额钱包
    getCoinWallet () {
      billBalance().then(res => {
        if (res.data.status === 200) {
          let { balance, balanceYuan, canUseBalanceYuan, freezeAmountYuan } = res.data.data
          this.balance = balance
          this.balanceYuan = balanceYuan
          this.canUseBalanceYuan = canUseBalanceYuan
          this.freezeAmountYuan = freezeAmountYuan
        }
      })
    },
    // 按月查询余额总量
    getCoinBillTotal () {
      if (!this.totalMonth) this.totalMonth = this.month
      billTotal({ month: this.totalMonth }).then(res => {
        if (res.data.status === 200) {
          let { total, typeAmounts } = res.data.data
          this.totalBill = total
          if (typeAmounts.length > 0) {
            this.typeAmounts = typeAmounts.map(item => {
              return {
                ...item,
                value: item.total,
                name: item.typeName
              }
            })
          } else {
            this.typeAmounts = [{ value: 0, total: 0, amount: 0, name: '购买龙币', type: 2, typeName: '购买龙币' }, { value: 0, total: 0, amount: 0, name: '其它', type: 3, typeName: '其它' }]
          }
          let arr = []
          arr[0] = this.typeAmounts.filter(e => e.type === 3)[0];
          arr[1] = this.typeAmounts.filter(e => e.type === 2)[0];
          console.log('arr', arr);
          this.setPie(arr)
        }
      })
    },
    // 分页查询账单
    getCoinBillPage () {
      let params = {
        page: this.page,
        month: this.pageMonth,
        type: this.type
      }
      billPage(params).then(res => {
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    toWithdraw () {
      this.$refs.WithdrawDepositDialog.balanceVisible = true
    },
    setPie (arr) {
      var chartDom = document.getElementById('pie');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          show: false
        },
        color: arr.filter(item => item).map(item => {
          if (item.type === 3) {
            return new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: '#5EB4FF'
              },
              {
                offset: 1,
                color: '#3676F5'
              }
            ])
          }
          if (item.type === 2) {
            return new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: '#FF8B33'
              },
              {
                offset: 1,
                color: '#FFC880'
              }
            ])
          }
        }),
        graphic: [
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '35%',
            z: 10,
            style: {
              text: '支出',
              textAlign: 'center',
              fill: '#333333', //文字的颜色
              fontSize: 24,
              lineHeight: 16
            }
          },
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '50%',
            z: 10,
            style: {
              text: '汇总',
              textAlign: 'center',
              fill: '#333333', //文字的颜色
              fontSize: 24,
              lineHeight: 16
            }
          },
        ],

        series: [
          {
            color: [
              new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                {
                  offset: 0,
                  color: '#FFF9F2'
                },
                {
                  offset: 1,
                  color: '#f9f0e5'
                }
              ])
            ], //内圆颜色
            type: 'pie',
            radius: '67%',
            center: ['50%', '50%'], //设置圆的位置，两个圆的位置必须一致
            data: [
              { value: 0, name: '' } //只设置一个值占整圆
            ],
            emphasis: {
              scale: false //鼠标悬停到内圆时，取消放大效果
            },
            label: {
              show: false //关闭牵引线
            }
          },
          {
            name: 'Access From',
            type: 'pie',
            radius: ['73%', '93%'],
            avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              scale: false, //鼠标悬停到内圆时，取消放大效果
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      };

      option && myChart.setOption(option);
    },
    handleCurrentChange (val) {
      this.page = val
      this.getCoinBillPage()
    },
    makeBilling (item) {
      console.log(item);
      this.$router.push({
        path: '/billingInfo'
      })
    },
    handleSelectionChange (val) {
      console.log(val);
      this.multipleSelection = val
    },
    // 余额充值
    topUpBalance () {
      this.$refs.BalanceDialog.balanceVisible = true
    },
    toPush (path) {
      this.$router.push({
        path
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.bill {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(250, 248, 245, 1) !important;
  .top {
    width: 100%;
    height: 240px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .left {
      width: 32%;
      height: 100%;
      margin-right: 20px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      padding: 35px 30px;
      background: url(../../assets/available-balance.png) #fff no-repeat;
      background-position: bottom right;
      &-title {
        display: flex;
        align-items: center;
        position: relative;
        .text {
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 34px;
          color: rgba(102, 102, 102, 1);
          margin-right: 8px;
        }
        .refresh {
          margin-left: 8px;
          font-size: 28px;
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
          transition: all 0.2s linear 0s;
          &.active {
            transform: rotate(-360deg);
          }
        }
        .withdrawal {
          margin-left: 8px;
          font-size: 14px !important;
          color: rgba(51, 51, 51, 1);
        }
        .warn {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid rgba(236, 102, 0, 1);
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
        }
        .warn:hover + .warn-dialog {
          display: block;
        }
        .warn-dialog {
          position: absolute;
          display: none;
          top: 30px;
          padding: 16px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 0 0 6px 0 rgba(13, 4, 9, 0.2);
          z-index: 100;
          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 36px;
            color: rgba(0, 0, 0, 1);
          }
          .content {
            font-size: 14px;
            font-weight: 400;
            line-height: 36px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      &-num {
        font-size: 46px;
        font-weight: 600;
        line-height: 56px;
        color: rgba(0, 0, 0, 1);
        font-family: DIN;
        margin: 4px 0 22px;
      }
      &-btn {
        .recharge {
          width: 96px;
          height: 42px;
          margin-right: 16px;
          border-radius: 6px;
          font-size: 18px;
          padding: 0;
          border: 0;
          background: linear-gradient(
            270deg,
            rgba(255, 201, 107, 1) 0%,
            rgba(255, 134, 41, 1) 100%
          );
        }
        .withdraw {
          width: 96px;
          height: 42px;
          opacity: 1;
          border-radius: 6px;
          font-size: 18px;
          padding: 0;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(255, 165, 97, 1);
          color: #ec6600;
        }
      }
    }
    .right {
      width: calc(68% - 20px);
      height: 100%;
      border-radius: 12px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      padding: 20px 24px;
      display: flex;
      position: relative;
      .pie {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        margin: 15px 40px 0 50px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.1) !important;
      }
      .pie-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .item {
          display: flex;
          align-items: center;
          margin-top: 12px;
          .name {
            width: 125px;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            color: rgba(51, 51, 51, 1);
            text-align: center;
            margin-right: 24px;
            &.gold {
              display: flex;
              align-items: center;
              &::before {
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                margin-right: 32px;
                border-radius: 50%;
                opacity: 1;
                background: #ff8b33;
              }
            }
            &.other {
              display: flex;
              align-items: center;
              &::before {
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                margin-right: 32px;
                border-radius: 50%;
                opacity: 1;
                background: #3676f5;
              }
            }
          }
          .num {
            width: 100px;
            text-align: center;
            font-family: DIN;
            font-size: 28px;
            font-weight: 600;
            line-height: 38px;
            color: rgba(0, 0, 0, 1);
            margin-right: 40px;
          }
          .percentage {
            font-family: DIN;
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      .search {
        position: absolute;
        top: 20px;
        right: 24px;
      }
    }
  }
  .bottom {
    width: 100%;
    height: calc(100% - 260px);
    background-color: #fff;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 0 24px;
      border-bottom: 1px solid #f7f5f2;
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        color: rgba(51, 51, 51, 1);
        display: flex;
        align-items: center;
        .btn {
          border-radius: 4px;
          background: #ffede0;
          border: 1px solid #ffede0;
          font-size: 16px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
          margin-left: 16px;
        }
      }
    }
    main {
      padding: 20px 24px;
      width: 100%;
      height: calc(100% - 71px);
      overflow: auto;
      .main-page {
        display: flex;
        justify-content: end;
        margin-top: 24px;
        /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
          background: #ffe6d4;
          font-size: 14px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
        }
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    .picker {
      width: 270px;
      height: 38px;
      /deep/ .el-date-editor .el-range-separator {
        width: 24px;
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
</style>
  