<template>
  <div class="withdrawalRecord">
    <header>
      <div class="title" @click="back">
        <div class="el-icon-arrow-left icon"></div>
        <div>提现记录</div>
      </div>
      <div class="search">
        <el-select
          class="sel"
          style="margin-right: 10px"
          v-model="status"
          placeholder="请选择"
          @change="search"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option label="待审核" :value="1"> </el-option>
          <el-option label="已转账" :value="2"> </el-option>
          <el-option label="已拒绝" :value="3"> </el-option>
          <el-option label="转账失败" :value="4"> </el-option>
        </el-select>
        <!-- <el-date-picker
          v-model="pageMonth"
          format="yyyy-MM"
          value-format="yyyyMM"
          type="month"
          placeholder="选择月"
          @change="search"
        >
        </el-date-picker> -->
      </div>
    </header>
    <main>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="calc(100% - 100px)"
      >
        <el-table-column prop="time" label="时间"> </el-table-column>
        <el-table-column prop="id" label="提现单号"> </el-table-column>
        <el-table-column prop="channelName" label="转账渠道"> </el-table-column>
        <el-table-column prop="account" label="收款人账号"> </el-table-column>
        <el-table-column prop="amountYuan" label="提现金额(元)">
        </el-table-column>
        <el-table-column prop="typeName" label="状态">
          <template slot-scope="scope">
            <span class="" :style="{ color: setColor(scope.row.status) }">{{
              scope.row.statusName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="statusName" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="del(scope.row)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="10 < total"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 提现记录删除 -->
    <el-dialog title="记录删除" :visible.sync="deleteVisible" width="480px">
      <div class="body">
        <p class="del">请确认是否删除该条提现记录？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="yesDelete"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { pageWalletWithdraw } from 'services/pay'
export default {
  name: 'WithdrawalRecord',
  components: {},
  data () {
    return {
      page: 1,
      total: 0,
      month: '',
      totalMonth: '',
      pageMonth: '',
      tableData: [],
      selItem: {},
      deleteVisible: false
    };
  },
  watch: {},
  filters: {
  },
  mounted () {
    // 获取当月
    const now = new Date();
    const y = now.getFullYear();
    const m = ('0' + (now.getMonth() + 1)).slice(-2);
    this.month = y + m
    this.totalMonth = y + m
    this.pageMonth = y + m
    this.getList()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    setColor (status) {
      switch (status) {
        case 1:
          return '#E69617'; // 待审核
        case 2:
          return '#3EB012'; // 已通过
        case 3:
          return '#D93B3B'; // 已拒绝
        case 4:
          return '#6D57FF'; // 待提现
        default:
          return '#333333'
      }
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    search () {
      this.page = 1;
      this.getList()
    },
    getList () {
      let params = {
        page: this.page,
        status: this.status
      }
      pageWalletWithdraw(params).then(res => {
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    del (item) {
      this.selItem = item
      this.deleteVisible = true
    },
    yesDelete () {
      this.page = 1;
      this.getList()
      this.deleteVisible = false
      return this.$message({
        type: 'success',
        message: '删除成功'
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.withdrawalRecord {
  width: 100%;
  height: 100%;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        font-size: 20px;
        margin-right: 8px;
      }
      .btn {
        border-radius: 4px;
        background: #ffede0;
        border: 1px solid #ffede0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
        margin-left: 16px;
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    overflow: auto;
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  