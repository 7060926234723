/* eslint-disable */
import axios from '../plugins/file';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 上传建筑房源图片
const uploadBuild = async data =>
  axios.post('/file/common/upload/img/build', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export { uploadBuild };
