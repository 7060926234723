<template>
  <div>
    <div
      class="list-item"
      :class="item.state ? 'active' : ''"
      v-for="item in plantList"
      :key="item.id"
      @click="onListItem(item)"
    >
      <div class="img">
        <img :src="item.imgUrl" alt="" />
      </div>
      <div class="item-content">
        <div class="item-content-title">
          <p>{{ item.titel }}</p>
        </div>
        <div class="item-content-main">
          <div class="label">
            <i class="icon icon-plant-details-location"></i>
            龙岗区坪地低碳城
          </div>
          <div class="count" :title="item.count">{{ item.count }}</div>
        </div>
      </div>
      <div class="count-btn">
        <i class="el-icon-circle-check" v-if="item.state"></i>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'PublishHouseListItem',
  props: {
    plantList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data () {
    return {
      activeId: ''
    }
  },
  mounted () { },
  methods: {
    onListItem (item) {
      this.$emit('onListItem', item)
    }
  }
};
  </script>
  <style lang="less" scoped>
.list-item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-right: 20px;
  cursor: pointer;
  .img {
    width: 160px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-right: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item-content {
    width: calc(80% - 184px);
    height: 35%;
    box-sizing: border-box;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        width: calc(100% - 64px);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .message {
        color: red;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
    &-main {
      margin: 8px 0;
      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin: 15px 0;
        &:first-child {
          margin-bottom: 4px;
        }
      }

      .count {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: start;
        color: #f79719;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 15px 0;
      }
    }
  }
  .count-btn {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #f79719;
  }
  &.active {
    background-color: #fff9f2;
  }
}

// 已上线
.b-6D57FF {
  background-color: #6d57ff;
}
// 审核中
.b-FFB031 {
  background-color: #ffb031;
}
// 已成交
.b-3EB012 {
  background-color: #3eb012;
}
// 已下架
.b-999 {
  background-color: #999;
}
// 审核失败
.b-D93B3B {
  background-color: #d93b3b;
}
</style>
    