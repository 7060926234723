<template>
  <div class="entrust-look-house">
    <header>
      <div class="title">
        <span>委托找房</span>
        <el-button class="btn" @click="toPush('/complainsRecords')"
          >投诉记录</el-button
        >
        <span class="warn">?</span>
        <div class="warn-dialog">
          <div class="title">尊敬的用户:</div>
          <div class="content">
            1.接受委托会消耗198龙币，接受后，将展示委托人真实号码，没有沟通限制。
          </div>
          <div class="content">
            2.亿楼不为本次委托的交易结果做担保行为，接受委托后，即视为您与亿楼的委托交易完成。
          </div>
          <div class="content">
            3.如果接受后，号码为空号，您可以点击虚假投诉，后台核实后，将返还您本次消费的龙币。
          </div>
        </div>
      </div>
      <div class="search">
        <!-- <el-input class="inp" placeholder="请输入内容" v-model="keywords">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input> -->
        <el-select
          class="sel"
          v-model="status"
          placeholder="请选择"
          @change="search"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-date-picker
          class="picker"
          v-model="value1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker> -->
      </div>
    </header>
    <main>
      <el-table
        class="table"
        :data="tableData"
        border
        style="width: 100%"
        v-loading="Loading"
      >
        <el-table-column prop="name" label="姓名" width="100px">
        </el-table-column>
        <el-table-column prop="time" label="委托时间" width="180px">
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="140px">
        </el-table-column>
        <el-table-column prop="priceLabel" label="价格" width="140px">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.priceLabel"
                placement="bottom-start"
              >
                <div>
                  {{ scope.row.priceLabel }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="areaLabel" label="面积" width="140px">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.areaLabel"
                placement="bottom-start"
              >
                <div>
                  {{ scope.row.areaLabel }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="委托需求">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.detail"
                placement="bottom-start"
              >
                <div slot="content" style="max-width: 400px">
                  {{ scope.row.detail }}
                </div>
                <div>
                  {{ scope.row.detail }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地区" width="100px">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                effect="dark"
                :content="`${scope.row.province}-${scope.row.city}-${scope.row.district}`"
                placement="bottom-start"
              >
                <div>
                  {{ scope.row.province }}-{{ scope.row.city }}-{{
                    scope.row.district
                  }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100px">
          <template slot-scope="scope">
            <div class="flex">
              {{
                scope.row.status === 1
                  ? '待处理'
                  : scope.row.status === 2
                  ? '已拒绝'
                  : '已接受'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          width="240px"
        >
          <template slot-scope="scope">
            <div style="position: relative">
              <el-button
                v-if="scope.row.status === 1"
                type="text"
                @click="submit(scope.row)"
                size="small"
                >处理委托</el-button
              >
              <el-button
                v-if="scope.row.status === 3"
                type="text"
                @click="phone(scope.row)"
                size="small"
                >虚假投诉</el-button
              >
              <el-button
                v-if="scope.row.status !== 1"
                type="text"
                @click="del(scope.row)"
                size="small"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>

    <!-- 虚假投诉 -->
    <el-dialog
      :modal="true"
      title="虚假投诉"
      :visible.sync="feedbackVisible"
      width="560px"
      :before-close="feedbackHandleClose"
    >
      <div class="body">
        <div class="li">一. 投诉类型</div>
        <div class="main">
          <el-radio-group v-model="radio">
            <el-radio :label="1">空号</el-radio>
            <el-radio :label="2">虚假委托</el-radio>
          </el-radio-group>
        </div>
        <div class="li">二. 投诉理由</div>
        <div class="main">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入您的意见和反馈"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="feedbackVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="feedbackVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 处理委托 -->
    <el-dialog
      title="处理委托"
      :visible.sync="submitVisible"
      width="480px"
      :before-close="submitHandleClose"
    >
      <div class="body">
        <p class="del">是否消耗100龙币接受委托？</p>
        <p class="del">龙币余额（{{ balance || 0 }}）</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="entrust(false)">拒绝</el-button>
        <el-button class="btn" type="primary" @click="entrust(true)"
          >接受</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog title="删除记录" :visible.sync="deleteVisible" width="480px">
      <div class="body">
        <p class="del">删除后不可恢复，确定要删除？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="onDelete">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  entrustFactoryPage,
  entrustFactoryHandle,
  entrustFactoryDel,
} from 'services/broker'
import { coinWallet } from 'services/pay'
export default {
  name: 'EntrustLookHouse',
  data () {
    return {
      keywords: '',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '待处理'
      }, {
        value: 2,
        label: '已拒绝'
      }, {
        value: 3,
        label: '已接受'
      }],
      status: '',
      value1: [],
      tableData: [],
      Loading: false,
      pageSize: 10,
      total: 0,
      page: 1,
      top: 0,
      feedbackVisible: false,
      selectId: null,
      submitVisible: false,
      deleteVisible: false,
      radio: 1,
      textarea: '',
      balance: null,
      giveBalance: null,
      userBalance: null,
    };
  },
  watch: {
  },
  mounted () {
    this.getList()
    this.getCoinWallet()
  },
  methods: {
    // 查询龙币钱包
    getCoinWallet () {
      coinWallet().then(res => {
        if (res.data.status === 200) {
          let { balance, giveBalance, userBalance } = res.data.data
          this.balance = balance
          this.giveBalance = giveBalance
          this.userBalance = userBalance
        }
      })
    },
    search () {
      this.page = 1;
      this.getList()
    },
    getList () {
      let params = {
        page: this.page,
        status: this.status
      }
      this.Loading = true
      entrustFactoryPage(params).then(res => {
        this.Loading = false
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    submit (item) {
      this.selectId = item.id
      this.submitVisible = true
    },
    phone (item) {
      this.textarea = null
      this.radio = 1
      this.feedbackVisible = true
      this.selectId = item.id
    },
    del (item) {
      this.selectId = item.id
      this.deleteVisible = true
    },
    submitHandleClose () {
      this.submitVisible = false
      this.selectId = null
    },
    feedbackHandleClose () {
      this.feedbackVisible = false
    },

    // 拒绝委托
    entrust (accept) {
      let params = {
        id: this.selectId,
        accept
      }
      entrustFactoryHandle(params).then(res => {
        if (res.data.status === 200) {
          this.getList()
          this.submitVisible = false
          this.selectId = null
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },
    // 删除
    onDelete () {
      entrustFactoryDel({ id: this.selectId }).then(res => {
        if (res.data.status === 200) {
          this.page = 1
          this.getList()
          this.deleteVisible = false
          this.selectId = null
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },

    toPush (path) {
      this.$router.push({
        path
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.entrust-look-house {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      position: relative;

      .btn {
        border-radius: 4px;
        background: #ffede0;
        border: 1px solid #ffede0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
        margin-left: 16px;
      }
      .warn {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid rgba(236, 102, 0, 1);
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(236, 102, 0, 1);
        margin-left: 16px;
        cursor: pointer;
      }
      .warn:hover + .warn-dialog {
        display: block;
      }
      .warn-dialog {
        position: absolute;
        display: none;
        top: 30px;
        width: 400px;
        padding: 16px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 0 6px 0 rgba(13, 4, 9, 0.2);
        z-index: 100;
        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 36px;
          color: rgba(0, 0, 0, 1);
        }
        .content {
          font-size: 14px;
          font-weight: 400;
          line-height: 36px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    .search {
      display: flex;
      align-items: center;
      .inp {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .sel {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .picker {
        width: 270px;
        height: 38px;
        /deep/ .el-date-editor .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    .table {
      /deep/ .el-input__inner {
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(245, 245, 245, 1);
        border: 0;
      }
    }
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 600;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .code {
    position: absolute;
    top: 30px;
    right: 40px;
    padding: 10px 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 110px;
      height: 114px;
    }
    p {
      &:first-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
      }
      &:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}
/deep/ .el-switch {
  display: flex !important;
  align-items: center;
  position: relative;
  .el-switch__label * {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .el-switch__label--left {
    position: absolute;
    left: 8px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  .el-switch__label--right {
    position: absolute;
    left: 16px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  &.is-checked .el-switch__core {
    &::after {
      top: 5px;
      left: 65px;
    }
  }
  .el-switch__core {
    width: 72px !important;
    height: 28px;
    opacity: 1;
    border-radius: 20px;
    &::after {
      top: 5px;
      left: 5px;
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .del {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .textarea {
    margin-top: 8px;
    /deep/ .el-textarea__inner {
      height: 100px;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }

  .li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .main {
    margin: 8px 0;
    padding-left: 26px;
    /deep/ .el-radio__label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
      border-color: rgba(236, 102, 0, 1);
      background: #fff;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner::after {
      background-color: rgba(236, 102, 0, 1);
    }
    /deep/ .el-textarea__inner {
      height: 98px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
    /deep/ .el-input__inner {
      height: 42px;
      line-height: 42px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  