<template>
  <div class="record-of-commun">
    <header>
      <div class="title">沟通记录</div>
      <div class="search">
        <el-input
          class="inp"
          placeholder="请输入号码"
          v-model="phone"
          @change="search"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-select
          class="sel"
          v-model="status"
          placeholder="请选择"
          @change="search"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </header>
    <main>
      <el-table
        class="table"
        :data="tableData"
        v-loading="Loading"
        border
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column type="index" label="序号" width="60px">
        </el-table-column>
        <el-table-column
          prop="time"
          sortable="custom"
          label="通话时间"
          width="180px"
        >
        </el-table-column>
        <el-table-column prop="fromNumber" label="来电号码" width="150px">
        </el-table-column>
        <el-table-column
          prop="callDurationFormat"
          label="沟通时长"
          width="150px"
        >
        </el-table-column>
        <el-table-column prop="statusName" label="状态" width="150px">
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                effect="dark"
                :hide-after="10000"
                :content="scope.row.remarks"
                placement="bottom-start"
              >
                <div slot="content" style="max-width: 400px">
                  {{ scope.row.remarks }}
                </div>
                <div>
                  {{ scope.row.remarks }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          width="240px"
        >
          <template slot-scope="scope">
            <div style="position: relative">
              <el-button type="text" @click="edit(scope.row)" size="small"
                >编辑备注</el-button
              >
              <el-button
                v-if="scope.row.showPhone"
                type="text"
                @click="$event => getPhoneCode(scope.row, $event)"
                size="small"
                >回电</el-button
              >
              <el-button
                v-else
                type="text"
                @click="getPhone(scope.row)"
                size="small"
                >获取号码</el-button
              >
              <el-dropdown @command="a => handleCommand(a, scope.row)">
                <span class="el-dropdown-link"> ... </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="del">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 回电弹窗 -->
    <div v-if="visible" class="code" :style="{ top: top - 80 + 'px' }">
      <p>{{ codePhone }}</p>
      <div class="c" ref="qrCodeDiv"></div>
      <p>扫码回电更快捷</p>
    </div>
    <!--编辑备注弹窗 -->
    <el-dialog
      title="编辑备注"
      :visible.sync="remarkVisible"
      width="480px"
      :before-close="remarkHandleClose"
    >
      <div class="body">
        <el-input
          class="textarea"
          type="textarea"
          :rows="2"
          :max="50"
          :maxlength="50"
          show-word-limit
          placeholder="请输入内容"
          v-model="selectRemark"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="remarkVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="onRemark">确认</el-button>
      </span>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      title="删除记录"
      :visible.sync="deleteVisible"
      width="480px"
      :before-close="deleteHandleClose"
    >
      <div class="body">
        <p class="del">删除后不可恢复，确定要删除？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="onDelete">确定</el-button>
      </span>
    </el-dialog>
    <!-- 获取手机弹窗 -->
    <el-dialog title="获取号码" :visible.sync="getPhoneVisible" width="480px">
      <div class="body">
        <p class="del">是否消耗98龙币获取号码？</p>
        <p class="del">龙币余额（{{ balance }}）</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="getPhoneVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="onPhone">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  callRecordPage,
  callRecordEdit,
  callRecordDel,
  callRecordMissed
} from 'services/broker'
import { coinWallet } from 'services/pay'
import QRCode from 'qrcodejs2';//引入生成二维码插件
export default {
  name: 'RecordOfCommun',
  data () {
    return {
      time: '',
      pickerOptions: {
        disabledDate (v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      phone: '',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 2,
        label: '已接通'
      }, {
        value: 1,
        label: '未接通'
      }, {
        value: 3,
        label: '未接通/已回电'
      }],
      status: '',
      value1: [],
      tableData: [],
      Loading: false,
      pageSize: 10,
      total: 0,
      page: 1,
      top: 0,
      visible: false,
      selectId: null,
      codePhone: null,
      selectRemark: '',
      remarkVisible: false,
      deleteVisible: false,
      getPhoneVisible: false,
      balance: null,
      giveBalance: null,
      userBalance: null,
    };
  },
  watch: {
    deleteVisible (val) {
      if (!val) {
        this.deleteVisible = false
      }
    }
  },
  filters: {
    typeFilter (val) {
      if (val.type) {
        return val.type === '1' ? '已接通' : val.type === '2' ? '未接通' : '未接通/已回电'
      }
    }
  },
  mounted () {
    this.getList()
    this.getCoinWallet()
  },
  methods: {
    // 查询龙币钱包
    getCoinWallet () {
      coinWallet().then(res => {
        if (res.data.status === 200) {
          let { balance, giveBalance, userBalance } = res.data.data
          this.balance = balance
          this.giveBalance = giveBalance
          this.userBalance = userBalance
        }
      })
    },
    search () {
      this.page = 1;
      this.getList()
    },
    getList () {
      let params = {
        sortType: this.sortType,
        phone: this.phone,
        status: this.status,
        page: this.page,
      }
      this.Loading = true
      callRecordPage(params).then(res => {
        this.Loading = false
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    sortChange (row) {
      const { column, prop, order } = row
      console.log(`当前页: `, column, prop, order);
      if (order === 'ascending') this.sortType = 1
      if (order === 'descending') this.sortType = 2
      if (!order) this.sortType = null
      this.getList()

    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    edit (item) {
      this.selectId = item.id
      this.selectRemark = item.remark
      this.remarkVisible = true
    },
    handleCommand (command, item) {
      if (command === 'del') this.del(item)
    },
    del (item) {
      this.selectId = item.id
      this.deleteVisible = true
    },
    getPhoneCode (item, $event) {
      if (this.selectId !== item.id) {
        this.visible = true
        this.codePhone = item.fromNumber
        this.selectId = item.id
        this.top = $event.clientY
        console.log(this.top);
        this.$nextTick(() => {
          this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
          new QRCode(this.$refs.qrCodeDiv, {
            text: `http://www.longmap.com/pc/test/${this.codePhone}`,//二维码链接，参数是否添加看需求
            width: 110,//二维码宽度
            height: 110,//二维码高度
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
          });
        })
      } else {
        this.selectId = null
        this.codePhone = null
        this.visible = false
      }
    },
    getPhone (item) {
      this.selectId = item.id
      this.getPhoneVisible = true
    },
    remarkHandleClose () {
      this.remarkVisible = false
    },
    onRemark () {
      let params = {
        id: this.selectId,
        remarks: this.selectRemark
      }
      callRecordEdit(params).then(res => {
        if (res.data.status === 200) {
          this.getList()
          this.remarkVisible = false
          this.selectId = null
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },
    deleteHandleClose () {
      this.deleteVisible = false
      this.selectId = null
    },
    onDelete () {

      callRecordDel({ id: this.selectId }).then(res => {
        if (res.data.status === 200) {
          this.page = 1
          this.getList()
          this.deleteVisible = false
          this.selectId = null
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },
    onPhone () {
      let params = {
        id: this.selectId,
      }
      callRecordMissed(params).then(res => {
        if (res.data.status === 200) {
          this.getList()
          this.getPhoneVisible = false
          this.selectId = null
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.record-of-commun {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
    }
    .search {
      display: flex;
      align-items: center;
      .inp {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .sel {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .picker {
        width: 270px;
        height: 38px;
        /deep/ .el-date-editor .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    .table {
      .look {
        position: relative;
        .picker {
          width: 30px;
          height: 14px;
          position: absolute;
          left: 0;
          opacity: 0;
        }
      }
    }
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .code {
    position: absolute;
    z-index: 9999;
    top: 30px;
    right: 40px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    .c {
      width: 110px;
      height: 114px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      &:first-child {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
      }
      &:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 2px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}
/deep/ .el-switch {
  display: flex !important;
  align-items: center;
  position: relative;
  .el-switch__label * {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .el-switch__label--left {
    position: absolute;
    left: 8px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  .el-switch__label--right {
    position: absolute;
    left: 16px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  &.is-checked .el-switch__core {
    &::after {
      top: 5px;
      left: 65px;
    }
  }
  .el-switch__core {
    width: 72px !important;
    height: 28px;
    opacity: 1;
    border-radius: 20px;
    &::after {
      top: 5px;
      left: 5px;
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .del {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .textarea {
    margin-top: 8px;
    /deep/ .el-textarea__inner {
      height: 100px;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  