<template>
  <div class="user">
    <!--用户认证头部-->
    <UserHeader ref="header" />
    <!-- 用户认证步骤条 -->
    <UserSteps :steps="steps" />
    <!-- 用户认证 -->
    <main>
      <!-- 1.填写信息 -->
      <div class="content-1" v-if="steps === 1">
        <!-- 身份类型 -->
        <!-- <p class="title">身份类型</p>
        <div class="status">
          <label for="">选择身份</label>
          <el-radio-group v-model="status">
            <el-radio :label="1">经纪人</el-radio>
            <el-radio :label="2">业主</el-radio>
          </el-radio-group>
        </div> -->
        <p class="title">个人实名信息</p>
        <div class="user-info">
          <div class="user-info-item flex-align">
            <label for="" class="required">姓名</label>
            <el-input
              class="inp"
              v-model="form.name"
              placeholder="请输入姓名"
            ></el-input>
          </div>
          <div class="user-info-item flex-align">
            <label for="" class="required">联系电话</label>
            <el-input
              class="inp"
              v-model.trim="form.phone"
              placeholder="请输入联系电话"
            ></el-input>
          </div>
          <div class="user-info-item flex-align">
            <label for="" class="required">联系地址</label>
            <div class="adress">
              <!-- <el-cascader
              class="inp"
              v-model="address"
              :props="optionProps"
              :options="addressOptions"
            ></el-cascader> -->
              <el-select
                v-model="form.provinceId"
                placeholder="选择省"
                @change="id => getAddress(id, 1)"
                style="width: 160px; margin-right: 12px"
              >
                <el-option
                  v-for="item in provinceOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.cityId"
                placeholder="选择市"
                @change="id => getAddress(id, 2)"
                style="width: 160px; margin-right: 12px"
              >
                <el-option
                  v-for="item in cityOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.areaId"
                placeholder="选择区县"
                style="width: 160px"
              >
                <el-option
                  v-for="item in areaOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="user-info-item flex-align">
            <label for="" class="required">详细地址</label>
            <el-input
              class="inp"
              v-model="form.address"
              placeholder="请输入详细地址"
            ></el-input>
          </div>
          <div class="user-info-item flex-align" v-if="status === 1">
            <label for="" class="required">所属公司</label>
            <el-input
              class="inp"
              v-model="form.company"
              placeholder="请输入"
            ></el-input>
          </div>
          <div class="user-info-item">
            <label for="" class="required">个人照片</label>
            <div class="picture">
              <p>
                照片将展示于您发布房源的详情页面，支持JPG/PNG/JPEG格式的图片，文件不超过5MB
              </p>
              <div class="img">
                <el-upload
                  class="flex-align head-img"
                  ref="head-img"
                  :action="uploadIp + '/file/common/upload/img/1'"
                  :headers="headers"
                  accept="image/*"
                  list-type="picture-card"
                  :limit="1"
                  :file-list="headFileList"
                  :before-upload="beforeUpload"
                  :on-change="handleHeadPreview"
                  :on-remove="handleHeadRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <div class="img-box">
                  <img
                    src="https://img.js.design/assets/img/651f6d556af3165b9e8220b6.png#58abc429deff372e4d93521bbb33b1b5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="user-info-item" v-if="status === 1">
            <label for="" class="required">从业资格证</label>
            <div class="picture">
              <p>
                证书只用于身份认证，我们承诺对你信息严格保密，支持JPG/PNG/JPEG格式的图片，文件不超过5MB
              </p>
              <div class="img">
                <el-upload
                  class="flex-align"
                  ref="certifieImg1"
                  :action="uploadIp + '/file/common/upload/img/0'"
                  :headers="headers"
                  accept="image/*"
                  list-type="picture-card"
                  :limit="1"
                  :file-list="certifieFileList"
                  :before-upload="beforeUpload"
                  :on-change="handleCertifiedPreview"
                  :on-remove="handleCertifiedRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <div class="img-box">
                  <img
                    src="https://img.js.design/assets/img/651f709224d1cb4fb44de851.png#54edd210a7c739c71dabe6e682f96d3a"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="user-info-item" v-else>
            <label for="" class="required">业主证明</label>
            <div class="picture">
              <p>
                可上传房屋产权证、房屋相关合同、社区盖章文件等用于证明您的业主身份，上传证明有助于更快通过身份认证审核，支持JPG/PNG/JPEG格式的图片，文件不超过5MB
              </p>
              <div class="img">
                <el-upload
                  class="flex-align"
                  ref="certifieImg2"
                  :action="uploadIp + '/file/common/upload/img/0'"
                  :headers="headers"
                  accept="image/*"
                  list-type="picture-card"
                  :limit="1"
                  :file-list="certifieFileList"
                  :before-upload="beforeUpload"
                  :on-change="handleCertifiedPreview"
                  :on-remove="handleCertifiedRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <div class="img-box">
                  <img
                    src="https://img.js.design/assets/img/651f709224d1cb4fb44de851.png#54edd210a7c739c71dabe6e682f96d3a"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <p>
            提交认证表示您已同意<span @click="open">《亿楼网用户协议》</span>
          </p>
          <div class="flex-align">
            <!-- <el-button class="cancel">取消</el-button> -->
            <el-button class="next" @click="next(1)">下一步</el-button>
          </div>
        </div>
      </div>
      <!-- 3.身份认证 -->
      <div class="content-3" v-if="steps === 2">
        <p class="title">用户认证</p>
        <p class="p1">
          您的<span>{{ status === 1 ? '经纪人' : '业主' }}</span
          >身份正在认证中，预计2个工作日内完成认证
        </p>
      </div>
      <!-- 4.认证结果 -->
      <div class="content-4" v-if="steps === 3">
        <p class="title">认证结果</p>
        <div v-if="userInfo.status === 4">
          <p class="p1">
            您的{{
              status === 1 ? '经纪人' : '业主'
            }}身份认证<span>失败</span>，失败原因如下：
          </p>
          <p class="p1">{{ certificationFailReason }}</p>
        </div>
        <div v-if="userInfo.status === 3">
          <p class="p1">
            您的{{ status === 1 ? '经纪人' : '业主' }}身份认证<span
              style="color: #3eb012"
              >成功</span
            >!
          </p>
        </div>
        <el-button class="button" @click="deleteVisible = true"
          >修改认证信息</el-button
        >
      </div>

      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>

      <!-- 修改认证信息 -->
      <el-dialog
        title="修改认证信息"
        :visible.sync="deleteVisible"
        width="480px"
      >
        <div class="body">
          <p class="del">修改认证信息需取消之前的认证，是否修改认证信息？</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteVisible = false">取 消</el-button>
          <el-button class="btn" type="primary" @click="edit">确定</el-button>
        </span>
      </el-dialog>
      <!-- 邀请相关 -->
      <el-dialog
        title="提示"
        :visible.sync="inviteRegisteirVisible"
        width="480px"
      >
        <div class="body">
          <p class="del">{{ content }}</p>
        </div>
      </el-dialog>
    </main>
  </div>
</template>
  
  <script>
import { getAreaByParent } from 'services/common'
import { ownerSubmit, brokerSubmit, ownerLast, brokerLast, certificationStatus } from 'services/broker'
import UserHeader from 'components/user/UserHeader.vue'
import UserSteps from 'components/user/UserSteps.vue'
export default {
  name: 'User',
  components: {
    UserHeader,
    UserSteps
  },
  data () {
    return {
      uploadIp: window.uploadIp,
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      userInfo: {},
      steps: 1,
      status: 2,
      form: {
        name: '',
        phone: '',
        headImg: '',
        address: '',
        certifiedImg: '',
        provinceId: null,
        cityId: null,
        areaId: null,
        company: ''
      },
      address: [],
      addressOptions: [],
      optionProps: {
        checkStrictly: true,
        value: "id",
        label: "name",
        children: "child"
      },
      headFileList: [],
      certifieFileList: [],
      certificationFailReason: '',
      dialogImageUrl: '',
      dialogVisible: false,
      payVisible: false,
      deleteVisible: false,
      inviteRegisteirVisible: false,
      content: '',

      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
    };
  },
  watch: {
    'form.provinceId': {
      handler (val) {
        console.log(val);
        if (val) {
          this.form.cityId = null
        }
      },
    },
    'form.cityId': {
      handler () {
        this.form.areaId = null
        this.areaOptions = []
      },
    },
    'form.areaId': {
      handler () {
        this.form.streetId = null
        this.streetOptions = []
      },
    },
    headFileList (val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          this.$refs['head-img'].$el.lastChild.style.display = 'none'
        })
      } else {
        console.log(this.$refs['head-img']);
        this.$refs['head-img'].$el.lastChild.style.display = 'inline-block'
      }
    },
    certifieFileList (val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          if (this.$refs['certifieImg1']) this.$refs['certifieImg1'].$el.lastChild.style.display = 'none'
          if (this.$refs['certifieImg2']) this.$refs['certifieImg2'].$el.lastChild.style.display = 'none'
        })
      } else {

        this.$nextTick(() => {
          console.log('this.$refs.certifieImg2', this.$refs['certifieImg2']);
          if (this.$refs['certifieImg1'])
            this.$refs['certifieImg1'].$el.lastChild.style.display = 'inline-block'
          if (this.$refs['certifieImg2'])
            this.$refs['certifieImg2'].$el.lastChild.style.display = 'inline-block'
        })
      }
    }
  },
  async mounted () {
    // 获取地区树形数据
    this.getAddress()
    // 判断认证状态
    const res = await certificationStatus()
    console.log(res, this.$route.query);
    this.judgeStatus(res)
  },
  methods: {
    // 获取省市区街道
    getAddress (id, treeNode) {
      getAreaByParent({ parentId: id }).then(res => {
        if (res.data.status === 200) {
          if (!treeNode) this.provinceOptions = res.data.data
          if (treeNode === 1) this.cityOptions = res.data.data
          if (treeNode === 2) this.areaOptions = res.data.data
          if (treeNode === 3) this.streetOptions = res.data.data
        }
      })
    },
    judgeStatus (res) {
      if (res.data.status === 200) {
        let { status, certificationFailReason } = res.data.data;
        this.userInfo = res.data.data
        // this.status = identityType ? identityType : 1
        this.status = 2
        // 当处于已经认证中时，且是邀请注册页面入口跳转，弹窗龙币只限新用户领取，您已经是亿楼的老朋友了
        if (status !== 1) {

          if (this.$route.query?.phone) {
            this.content = '弹窗龙币只限新用户领取，您已经是亿楼的老朋友了'
            this.inviteRegisteirVisible = true
          }
        }
        if (status === 2) {
          this.steps = 2
        }
        if (status === 3) {
          this.steps = 3
        }
        if (status === 4) {
          this.steps = 3
          this.certificationFailReason = certificationFailReason
        }

      }
    },
    handleHeadRemove () {
      this.headFileList = []
      this.form.headImg = '';
    },
    handleCertifiedRemove () {
      this.certifieFileList = []
      this.form.certifiedImg = '';
    },
    beforeUpload (file) {
      let imgSize = Number(file.size / 1024 / 1024);
      console.log(imgSize);
      if (imgSize > 5) {
        this.$message({
          message: '文件不超过5MB',
          type: 'warning'
        });
        return false
      } else {
        return true
      }
    },
    handleHeadPreview (file) {
      console.log(file);
      if (file.status === 'success' && file.response.status === 200) {
        this.form.headImg = file.response.data.fileUrl;
        this.headFileList = [{ url: window.los + file.response.data.fileUrl + '?' + Date.now() }]
      }
    },
    handleCertifiedPreview (file) {
      if (file.status === 'success' && file.response.status === 200) {
        this.form.certifiedImg = file.response.data.fileUrl;
        this.certifieFileList = [{ url: window.los + file.response.data.fileUrl + '?' + Date.now() }]
      }
    },
    // 下一步
    next (num) {
      // 提交认证
      if (num === 1) {
        console.log(this.address)
        if (this.status === 1) {
          this.submit1()
        }
        if (this.status === 2) {
          this.submit2()
        }
      }
      // 修改认证
      if (num === 3) {
        this.steps = 1
      }
    },
    // 经纪人提交
    submit1 () {
      // 区分是密码登录还是验证码登录
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.form.name && this.form.name.length > 2) {
        return this.$message.warning('请输入姓名，且长度大于2')
      } else if (!this.form.phone) {
        return this.$message.warning('请输入联系电话！')
      } else if (!reg.test(this.form.phone)) {
        this.$message.warning('请正确输入手机号')
      } else if (!this.form.provinceId) {
        return this.$message.warning('请选择省！')
      } else if (!this.form.cityId) {
        return this.$message.warning('请选择市！')
      } else if (!this.form.areaId) {
        return this.$message.warning('请选择区县！')
      } else if (!this.form.address) {
        return this.$message.warning('请输入详细地址！')
      } else if (!this.form.company) {
        return this.$message.warning('请输入所属公司！')
      } else if (!this.form.headImg) {
        return this.$message.warning('请上传个人照片！')
        // } else if (!this.form.certifiedImg) {
        //   return this.$message.warning('请上传从业资格证！')
      } else {
        const { name, phone, address, company, headImg, certifiedImg, provinceId, cityId, areaId } = this.form
        let params = {
          provinceId,
          cityId,
          areaId,
          name, phone, address, company, headImg, certifiedImg
        }
        brokerSubmit(params).then(res => {
          console.log(res);
          if (res.data.status === 200) {
            this.steps = this.steps + 1
          }
        })
      }
    },
    // 业主提交
    submit2 () {
      // 区分是密码登录还是验证码登录
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.form.name && this.form.name.length > 2) {
        return this.$message.warning('请输入姓名，且长度大于2')
      } else if (!this.form.phone) {
        return this.$message.warning('请输入联系电话！')
      } else if (!reg.test(this.form.phone)) {
        this.$message.warning('请正确输入手机号')
      } else if (!this.form.provinceId) {
        return this.$message.warning('请选择省！')
      } else if (!this.form.cityId) {
        return this.$message.warning('请选择市！')
      } else if (!this.form.areaId) {
        return this.$message.warning('请选择区县！')
      } else if (!this.form.address) {
        return this.$message.warning('请输入详细地址！')
      } else if (!this.form.headImg) {
        return this.$message.warning('请上传个人照片！')
        // } else if (!this.form.certifiedImg) {
        //   return this.$message.warning('请上传从业资格证！')
      } else {
        const { name, phone, address, company, headImg, certifiedImg, provinceId, cityId, areaId } = this.form
        let params = {
          provinceId,
          cityId,
          areaId,
          name, phone, address, company, headImg, certifiedImg
        }
        ownerSubmit(params).then(res => {
          console.log(res);
          if (res.data.status === 200) {
            this.steps = this.steps + 1
          }
        })
      }
    },
    // 修改认证信息
    edit () {
      let vm = this
      const req = vm.status == 1 ? brokerLast() : ownerLast()
      req.then(async res => {
        if (res.data.status === 200) {
          console.log(res)
          let { provinceId, cityId, headImg, certifiedImg } = res.data.data
          await vm.getAddress()
          await vm.getAddress(provinceId, 1)
          await vm.getAddress(cityId, 2)
          vm.form = res.data.data
          vm.deleteVisible = false
          vm.headFileList = [{ url: window.los + headImg + '?' + Date.now() }]
          vm.certifieFileList = [{ url: window.los + certifiedImg + '?' + Date.now() }]
          vm.steps = 1
        }
      })
    },
    payClose () {
      this.payVisible = false
    },
    open () {
      let routeUrl = this.$router.resolve({
        path: '/userAgreement'
      })
      window.open(routeUrl.href, '_blank');
    }
  }
};
  </script>
  <style lang="less" scoped>
main {
  width: 100%;
  height: calc(100% - 300px);
  padding: 24px calc(50% - 600px) 56px;
  .content-1 {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 40px 60px 80px;
    overflow: auto;
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 24px;
    }
    .status {
      padding-left: 120px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      label {
        width: 100px;
        text-align: end;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(153, 153, 153, 1);
        margin-right: 56px;
      }
      /deep/ .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      /deep/ .el-radio__label {
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner::after {
        width: 10px;
        height: 10px;
        background: rgba(236, 102, 0, 1);
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: rgba(236, 102, 0, 0.3);
        background: rgba(236, 102, 0, 0.3);
      }
    }
    .user-info {
      &-item {
        padding-left: 120px;
        display: flex;
        // align-items: center;
        margin-bottom: 28px;
        label {
          width: 100px;
          text-align: end;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(153, 153, 153, 1);
          margin-right: 56px;
        }
        .inp {
          width: 504px;
          height: 40px;
          opacity: 1;
          border-radius: 4px;
          background: rgba(255, 255, 255, 1);
        }
        .picture {
          width: calc(100% - 136px);
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(153, 153, 153, 1);
            margin-bottom: 12px;
          }
          .img {
            display: flex;
            align-items: center;
            /deep/ .el-upload--picture-card {
              width: 140px;
              height: 140px;
            }
            .img-box {
              width: 140px;
              height: 140px;
              position: relative;
              border: 1px solid rgba(245, 243, 240, 1);
              border-radius: 4px;
              margin-left: 32px;
              &::after {
                content: '示意图';
                text-align: center;
                line-height: 32px;
                width: 140px;
                height: 32px;
                bottom: 0;
                opacity: 1;
                border-radius: 0px 0px 4px 4px;
                background: rgba(0, 0, 0, 0.5);
                position: absolute;
                color: #fff;
              }
              img {
                width: 140px;
                height: 140px;
                opacity: 1;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
    .btn {
      margin-top: 72px;
      margin-left: 256px;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 16px;
        span {
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
        }
      }
      .cancel {
        width: 160px;
        height: 60px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 237, 224, 1);
        border: 1px solid rgba(255, 237, 224, 1);
        font-size: 20px;
        font-weight: 600;
        color: rgba(236, 102, 0, 1);
      }
      .next {
        width: 160px;
        height: 60px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        font-size: 20px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);

        border: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
  .content-2 {
    width: 100%;
    height: 100%;
    padding: 100px 270px;
    background-color: #fff;
    overflow: auto;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 44px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 30px;
    }
    .p1 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 24px;
      span {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        color: rgba(236, 102, 0, 1);
      }
    }
    .code {
      width: 100%;
      height: 274px;
      opacity: 1;
      background: rgba(250, 248, 245, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      &-item {
        &:last-child {
          margin-left: 80px;
        }
        .img {
          width: 180px;
          height: 180px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(238, 238, 238, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 13px;
          box-sizing: border-box;
          margin-bottom: 12px;
          img {
            width: 154px;
            height: 154px;
          }
        }
        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .content-3 {
    width: 100%;
    height: 100%;
    padding: 100px 304px;
    background-color: #fff;
    overflow: auto;
    text-align: center;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 44px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 30px;
    }
    .p1 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 24px;
      span {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .content-4 {
    width: 100%;
    height: 100%;
    padding: 100px 370px;
    background-color: #fff;
    overflow: auto;
    text-align: center;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 44px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 30px;
    }
    .p1 {
      text-align: left;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 24px;
      span {
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        color: rgba(236, 102, 0, 1);
      }
    }
    .button {
      margin: 0 auto;
    }

    /deep/ .el-popper {
      padding: 10px !important;
      .el-popconfirm__action {
        display: flex;
        align-items: center;
      }
    }
  }
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.body {
  font-size: 18px;
  line-height: 24px;
  color: rgba(51, 51, 51, 1);
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
.button {
  border: 1px solid rgba(236, 102, 0, 1);
  font-weight: 400;
  color: rgba(236, 102, 0, 1);
}

/deep/ .el-upload-list--picture-card {
  height: 140px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 140px;
  height: 140px;
  margin: 0px 8px 0px 0px;
}

/deep/ .el-message-box__btns button:nth-child(2) {
  color: #fff !important;
  background: #ec6600 !important;
  border: 1px solid #ec6600 !important;
}
</style>
  