<template>
  <div class="billing-info">
    <header>
      <div class="title">
        <i class="el-icon-back" @click="back"></i>
        <div>发票管理>开票信息</div>
      </div>
    </header>
    <main>
      <div class="label-item">
        <label for="">开票金额</label>
        <div class="sum">1218.66<span>元</span></div>
      </div>
      <div class="label-item">
        <label for="">发票类型</label>
        <div>
          <el-radio-group v-model="radio">
            <el-radio :label="1">电子发票 (普票)</el-radio>
            <el-radio :label="2">纸质发票</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="label-item">
        <label for="">发票抬头</label>
        <div class="invoice-title">
          <div class="company-name">深圳市****有限公司</div>
          <div class="code">91440300192264288M</div>
          <div class="edit" @click="toPush('/billingTitle')">更改抬头</div>
        </div>
      </div>
      <div class="label-item">
        <label for="">邮寄地址</label>
        <div class="invoice-title">
          <div class="code">张三</div>
          <div class="code">18534731122</div>
          <div class="code">广东省深圳市南山区xxxxxxx5栋1102号</div>
          <div class="edit" @click="toPush('/billingAddress')">更改地址</div>
        </div>
      </div>
      <div class="label-item">
        <label for="">填写备注</label>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="btn-box">
        <el-button class="next">确认开票</el-button>
      </div>
    </main>
  </div>
</template>
    
    <script>
export default {
  name: 'BillingInfo',
  data () {
    return {
      radio: 1,
      textarea: ''
    };
  },
  watch: {},
  filters: {
  },
  methods: {
    back () {
      this.$router.back()
    },
    toPush (path) {
      this.$router.push({
        path
      })
    }
  }
};
    </script>
    <style lang="less" scoped>
.billing-info {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 20px;
        font-weight: bold;
        padding-right: 20px;
        border-right: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
  main {
    padding: 46px 120px;
    width: 100%;
    height: calc(100% - 71px);
    .label-item {
      display: flex;
      //   align-items: center;
      margin-bottom: 36px;
      label {
        width: 130px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(153, 153, 153, 1);
      }
      .sum {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(236, 102, 0, 1);
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(236, 102, 0, 1);
        }
      }
      .invoice-title {
        display: flex;
        align-items: center;
        .company-name {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(51, 51, 51, 1);
          margin-right: 40px;
        }
        .code {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(51, 51, 51, 1);
          margin-right: 8px;
        }
        .edit {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
        }
      }
    }

    .btn-box {
      margin-top: 60px;
      // text-align: center;
      margin-left: 350px;
      .next {
        width: 160px;
        height: 60px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        font-size: 20px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);

        border: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
}

/deep/ .el-radio__inner {
  width: 16px;
  height: 16px;
}
/deep/ .el-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
/deep/ .el-radio__input.is-checked .el-radio__inner::after {
  width: 8px;
  height: 8px;
  background: #fff;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(236, 102, 0, 1);
  background: rgba(236, 102, 0, 1);
}

/deep/ .el-textarea__inner {
  width: 600px;
  height: 206px;
  border-radius: 4px;
  background: #f7f7f7;
  border: 0;
}
</style>
    