<template>
  <div class="publish-housing-type">
    <p class="title">请选择您要发布的房源类型</p>
    <main>
      <div class="item" @click="toPush('/publishHousing')">厂房出租</div>
      <div class="item" @click="toPush('/publishHousingOffice')">
        写字楼出租
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: 'PublishHousing',
  components: {},
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    toPush (path) {
      this.$router.push({ path })
    }
  }
};
</script>

<style lang="less" scoped>
.publish-housing-type {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p.title {
    font-size: 30px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 20px;
  }
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      width: 120px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      border-radius: 8px;
      background: rgba(236, 102, 0, 1);
      cursor: pointer;
      margin-right: 20px;
      color: #fff;
    }
  }
}
</style>