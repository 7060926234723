<template>
  <div>
    <div
      class="list-item"
      :class="activeId === item.id ? 'active' : ''"
      v-for="item in plantList"
      :key="item.id"
    >
      <div class="img">
        <img :src="item.imgUrl" alt="" />
      </div>
      <div class="item-content">
        <div class="item-content-title">
          <div class="p">
            <p>{{ item.titel }}</p>
          </div>
        </div>
        <div class="item-content-main">
          <div class="label">
            <div class="label-line">
              <span>7000㎡</span>
              <div class="line"></div>
              <span>独栋厂房</span>
              <div class="line"></div>
              <span>全新</span>
            </div>
            <div class="label-line">
              <i class="icon icon-plant-details-location"></i>
              龙岗区坪地低碳城
            </div>
          </div>
        </div>
      </div>
      <div class="count-btn">
        <div class="count" :title="item.count">{{ item.count }}</div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'HouseListItem',
  props: {
    plantList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data () {
    return {
      activeId: ''
    }
  },
  mounted () { },
  methods: {
    typeFilter (val, type) {
      if (type !== 'color') {
        switch (val) {
          case '1':
            return '已上线';
          case '2':
            return '已下线';
          case '3':
            return '已成交';
          case '4':
            return '审核中';
          case '5':
            return '审核失败';
        }
      } else {
        switch (val) {
          case '1':
            return 'b-6D57FF';
          case '2':
            return 'b-999';
          case '3':
            return 'b-3EB012';
          case '4':
            return 'b-FFB031';
          case '5':
            return 'b-D93B3B';
        }
      }
    },
    onListItem (item) {
      this.activeId = item.id
    },
    loseMessage (item) {
      this.$emit('loseMessage', item)
    }
  }
};
  </script>
  <style lang="less" scoped>
.list-item {
  display: flex;
  padding: 20px;
  background: rgba(245, 245, 245, 1);
  padding-right: 20px;
  cursor: pointer;
  // border-bottom: 1px solid #fff;
  .img {
    width: 160px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-right: 24px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .item-content {
    width: calc(80% - 184px);
    height: 120px;
    box-sizing: border-box;
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .p {
        display: flex;
        width: 100%;
        p {
          width: 100%;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
          text-align: justify;
        }
        span {
          display: inline-block;
          width: 64px;
          text-align: center;
          border-radius: 0px 12px 0px 12px;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;
      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &-line {
          display: flex;
          align-items: center;
          justify-content: start;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(153, 153, 153, 1);
          span {
            font-size: 14px;
          }
          &:first-child {
            margin-bottom: 4px;
          }
          .line {
            width: 1px;
            height: 18px;
            background-color: rgba(221, 221, 221, 1);
            margin: 0 7px;
          }
        }
      }
    }
    &-label {
      display: flex;
      align-items: center;
      &-item {
        padding: 3px 10px;
        border-radius: 2px;
        background: rgba(245, 244, 243, 1);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .count-btn {
    width: 20%;
    .count {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 600;
      font-family: DIN;
      line-height: 24px;
      text-align: end;
      color: rgba(236, 102, 0, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 30px;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: end;
      &-item {
        width: 100px;
        height: 38px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #fffaf2;
        border: 1px solid #ec6600;
        font-size: 14px;
        font-weight: 400;
        color: #ec6600;
        cursor: pointer;
        margin-left: 16px;
      }
    }
  }
  &.active {
    background-color: #fff9f2;
  }
}

// 已上线
.b-6D57FF {
  background-color: #6d57ff;
}
// 审核中
.b-FFB031 {
  background-color: #ffb031;
}
// 已成交
.b-3EB012 {
  background-color: #3eb012;
}
// 已下架
.b-999 {
  background-color: #999;
}
// 审核失败
.b-D93B3B {
  background-color: #d93b3b;
}
</style>
    