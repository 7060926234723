<template>
  <div class="registrationCertification">
    <div class="main">
      <div class="user">用户</div>
      <p class="phone">
        您的好友<span>{{ phone | phoneFilter }}</span>
      </p>
      <p class="text">
        推荐您注册认证亿楼平台，赠送500龙币、免费接打5个租客来电、超高曝光海量客源、免费发布房源
      </p>
      <div class="btn" @click="toRegist">
        <img src="../../assets/user/btn.webp" alt="" />
      </div>
    </div>
    <div class="main">
      <p class="title">各大平台投流推广 海量优质直租客源</p>
      <main>
        <div class="content">
          <img src="../../assets/user/wx.webp" alt="" />
          <div class="content-text">
            亿楼充分利用微信朋友圈的社交属性，通过精准定位和个性化定向推广，将亿楼的服务推广给更多的潜在租客，确保亿楼的房源曝光率。
          </div>
        </div>
        <div class="content">
          <img src="../../assets/user/bd.webp" alt="" />
          <div class="content-text">
            亿楼最早与百度展开合作花费大量成本，通过关键词广告和亿楼网站的高权重，极大的提高了亿楼在搜索引擎中的曝光率。
          </div>
        </div>
        <div class="content">
          <img src="../../assets/user/dy.webp" alt="" />
          <div class="content-text">
            亿楼与抖音合作，通过短视频的形式定向推广，将有租厂房意向的客户引流到我们的网站与小程序中吸引了大量的中产商户的关注。
          </div>
        </div>
        <div class="content">
          <img src="../../assets/user/tt.webp" alt="" />
          <div class="content-text">
            今日头条的用户画像与亿楼的租客属性高度一致，亿楼利用大量的商户老板都会在今日头条看新闻资讯的特点发布了大量厂房租赁广告,提高了亿楼品牌的认知度和曝光率。
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'RegistrationCertification',
  data () {
    return {
      src: '',
      phone: ''
    };
  },
  mounted () {
    console.log(this.$route.query.phone);
    if (this.$route.query.phone) {
      this.phone = this.$route.query.phone
    }
  },
  methods: {
    toRegist () {
      this.$router.push(`/user?phone=${this.phone}`)
    }
  }
};
  </script>
  <style lang="less" scoped>
.registrationCertification {
  overflow: hidden;
  width: 100%;
  height: 2200px !important;
  padding: 0 360px;
  background: url(../../assets/user/bg.webp) !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  .main {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid #ffffff;
    border-radius: 32px;
    &:nth-child(1) {
      padding: 75px 210px 57px;
      margin-top: 1033px;
      margin-bottom: 69px;
      position: relative;
      .user {
        width: 99px;
        height: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff6c22;
        border: 5px solid #faddcd;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      p.phone {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #060505;
        line-height: 72px;
        span {
          font-size: 32px;
          font-family: DIN;
          font-weight: bold;
          color: #ff6c23;
          line-height: 72px;
        }
      }
      p.text {
        text-align: center;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        margin-top: 30px;
      }
      .btn {
        cursor: pointer;
        width: 304px;
        height: 84px;
        border-radius: 50px;
        margin: 47px auto 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &:nth-child(2) {
      padding: 68px 81px;
      p.title {
        text-align: center;
        font-size: 44px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #222222;
        line-height: 32px;
        text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.75);
        margin-bottom: 58px;
      }
      main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content {
          width: 230px;
          height: 310px;
          border-radius: 16px;
          padding: 58px 37px 30px;
          display: flex;
          align-items: center;
          flex-direction: column;
          img {
            height: 74px;
          }
          &-text {
            text-align: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 22px;
            text-shadow: 0px 2px 2px rgba(233, 95, 40, 0.75);
          }
          &:nth-child(1) {
            background: linear-gradient(0deg, #eb742f 0%, #ffb973 100%);
          }
          &:nth-child(2) {
            background: linear-gradient(0deg, #e67717 0%, #f5c158 100%);
          }
          &:nth-child(3) {
            background: linear-gradient(0deg, #a557ff 0%, #d98cff 100%);
          }
          &:nth-child(4) {
            background: linear-gradient(0deg, #f26430 11%, #ffa575 100%);
          }
        }
      }
    }
  }
}
</style>
  