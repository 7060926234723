<template>
  <header>
    <div class="nav">
      <div
        class="nav-item"
        :class="navIndex === item.id ? 'active' : ''"
        v-for="item in nav"
        :key="item.id"
        @click="toPushs(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="user">
      <div class="photo">
        <i
          style="width: 30px; height: 30px"
          v-if="!userInfo.phone || !userInfo.headImg"
          class="icon icon-user"
        ></i>
        <img
          v-else
          :src="los + userInfo.headImg + '?' + Date.now()"
          alt=""
          class="tx-img"
        />
      </div>
      <div class="name">
        <span>{{ userInfo.phone | phoneFilter }}</span>
        <i class="el-icon-caret-bottom"></i>
      </div>

      <div class="userinfo">
        <div
          class="info-item"
          v-for="item in info"
          :key="item.id"
          @click="toPush(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 退出登录弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cencal" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button class="btn" type="primary" @click="toLogout"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </header>
</template>
  
  <script>
const los = window.los
import { logout } from 'services/login';
export default {
  name: 'Header',
  data () {
    return {
      los,
      dialogVisible: false,
      navIndex: 2,
      info: [
        { id: 1, text: '个人认证', url: '/user', },
        { id: 2, text: '退出登录', },
      ],
      nav: [
        // { id: 1, url: '', name: '写字楼' },
        // { id: 2, url: '', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' }
      ],
      userInfo: {}
    };
  },
  mounted () {
    // 获取用户信息
    if (localStorage.userInfo) this.userInfo = JSON.parse(localStorage.userInfo)
    this.$eventBus.$on('getUserInfo', userInfo => {
      this.userInfo = userInfo
    })
  },
  methods: {
    // 退出-登录
    loginORquit () {
      this.dialogVisible = true
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.userInfo = {}
          localStorage.removeItem('userInfo')
          this.dialogVisible = false
          if (this.timer) clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
          }, 500)
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toPush (item) {
      this.navIndex = item.id
      if (item.id === 1) {
        this.$router.push({
          path: item.url
        })
      } else {
        this.loginORquit()
      }
    },
    toPushs (item) {
      this.$router.push({
        path: item.url
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: end;
  .nav {
    display: flex;
    align-items: center;
    margin-right: 80px;
    &-item {
      margin-left: 48px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
      &.active {
        color: #ec6600;
        font-weight: 600;
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    position: relative;
    .photo {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
      i {
        font-size: 14px;
        color: #ccc;
      }
    }
    &:hover {
      .userinfo {
        display: block;
      }
    }
    .userinfo {
      position: absolute;
      z-index: 100;
      padding: 10px 0;
      top: 24px;
      left: 30%;
      text-align: center;
      background-color: #fff;
      display: none;
      border-radius: 4px;
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.1) !important;
      .info-item {
        width: 90px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background-color: #fff9f2;
        }
        &:active {
          background-color: #fff9f2;
          color: #ec6600;
        }
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .cencal {
    display: flex;
    align-items: center;
  }
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>
  