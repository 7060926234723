<template>
  <div class="user-header">
    <div class="nav">
      <div
        class="nav-item"
        :class="navIndex === item.id ? 'active' : ''"
        v-for="item in nav"
        :key="item.id"
        @click="toUserPush(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="login">
      <div class="user">
        <i
          class="icon icon-user"
          v-if="!userInfo.phone || !userInfo.headImg"
        ></i>
        <img
          v-else
          :src="los + userInfo.headImg + '?' + Date.now()"
          alt=""
          class="tx-img"
        />
        <span v-if="userInfo.phone">{{ userInfo.phone | phoneFilter }}</span>
      </div>
      <div class="login-btn" @click="loginORquit">
        {{ !LOGINSTATUS ? '登录' : '退出' }}
      </div>
      <div class="userinfo" v-if="LOGINSTATUS">
        <div
          class="info-item"
          v-for="item in info"
          :key="item.id"
          @click="toPush(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 退出登录弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cencal" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button class="btn" type="primary" @click="toLogout"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    <script>
const los = window.los
import { isLogin, logout } from 'services/login'
export default {
  name: 'UserHeader',
  data () {
    return {
      los,
      navIndex: 2,
      dialogVisible: false,
      userInfo: {},
      LOGINSTATUS: false,
      info: [
        { id: 1, text: '顾问端首页', url: '/', },
      ],
      nav: [
        // { id: 1, url: '', name: '写字楼用户端' },
        { id: 2, url: '/', name: '厂房用户端' },
        // { id: 3, url: '', name: '仓库用户端' },
        // { id: 4, url: '', name: '商铺用户端' },
        // { id: 5, url: '', name: '土地用户端' }
      ]
    }
  },
  mounted () {
    // 获取用户信息
    if (localStorage.userInfo)
      this.userInfo = JSON.parse(localStorage.userInfo)
    isLogin().then(res => {
      if (res.data.status === 200) {
        this.$LOGINSTATUS = res.data.data
        this.LOGINSTATUS = res.data.data
        console.log(this.$LOGINSTATUS);
        if (!res.data.data) localStorage.userInfo = ''
      }
    })
  },
  methods: {
    // 退出-登录
    loginORquit () {
      if (this.$LOGINSTATUS) {
        this.dialogVisible = true
      } else {
        window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
      }
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.userInfo = {}
          localStorage.removeItem('userInfo')
          this.dialogVisible = false
          if (this.timer) clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
          }, 500)
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toUserPush (item) {
      if (item.id === 2) {
        window.location.href = window.plantIp + "/#/plant";
      }
    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    }
  }
};
    </script>
    
    <style lang="less" scoped>
.user-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  opacity: 1;
  background: rgba(51, 48, 46, 1);
  padding: 0 calc(50% - 600px);
  .nav {
    display: flex;
    align-items: center;
    &-item {
      margin-right: 20px;
      font-size: 16px;
      line-height: 40px;
      color: rgba(187, 187, 187, 1);
      cursor: pointer;
      &.active {
        color: #fff;
      }
    }
  }
  .login {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 30px;
    position: relative;
    .user {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    .icon {
      margin-right: 8px;
    }
    &-btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
    &:hover {
      .userinfo {
        display: block;
      }
    }
    .userinfo {
      position: absolute;
      z-index: 100;
      top: 25px;
      background-color: #fff;
      display: none;
      border-radius: 4px;
      .info-item {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .cencal {
    display: flex;
    align-items: center;
  }
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>
    