<template>
  <div class="pay">
    <div class="weixin">
      <header>
        <div class="logo">
          <img class="img1" src="../../assets/plant/logo.png" alt="" />
          <span>收银台</span>
        </div>
      </header>
      <main>
        <div class="payment">
          <p class="count">
            支付金额<span>￥{{ amount }}</span
            >,请使用微信扫描下方二维码完成支付
          </p>
          <div class="line"></div>
          <div class="content">
            <div class="left">
              <div class="code" ref="qrCodeDiv"></div>
              <p>微信扫描二维码支付</p>
            </div>
            <div class="right">
              <div class="item">
                <label for="">商家名称：</label>
                <span>神州龙空间技术（深圳）有限公司</span>
              </div>
              <div class="item">
                <label for="">商品名称：</label>
                <span>{{ payType == 1 ? '余额充值' : '龙币充值' }}</span>
              </div>
              <div class="item">
                <label for="">交易编号：</label>
                <span>{{ payId }}</span>
              </div>
              <div class="item">
                <label for="">创建时间：</label>
                <span>{{ formattedTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';//引入生成二维码插件
export default {
  name: 'WeiXinPay',
  data () {
    return {
      modeOfPayment: null,
      content: '',
      codeUrl: null,
      payId: null,
      payType: 1,
      amount: null,
      formattedTime: null
    };
  },
  mounted () {
    this.getTome()
    const hash = window.location.hash.split('?')[1].split('&')
    hash.map(item => {
      if (item.indexOf('codeUrl=') !== -1) this.codeUrl = item.split('codeUrl=')[1]
      if (item.indexOf('payId=') !== -1) this.payId = item.split('payId=')[1]
      if (item.indexOf('payType=') !== -1) this.payType = item.split('payType=')[1]
      if (item.indexOf('amount=') !== -1) this.amount = item.split('amount=')[1]
    })
    this.getPhoneCode(decodeURIComponent(this.codeUrl))
  },
  methods: {
    getTome () {
      const now = new Date();
      const year = now.getFullYear();
      const month = ('0' + (now.getMonth() + 1)).slice(-2);
      const day = ('0' + now.getDate()).slice(-2);
      const hours = ('0' + now.getHours()).slice(-2);
      const minutes = ('0' + now.getMinutes()).slice(-2);
      const seconds = ('0' + now.getSeconds()).slice(-2);
      this.formattedTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    getPhoneCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 260,//二维码宽度
          height: 260,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        });
      })
    },
  }
};
</script>

<style lang="less" scoped>
.pay {
  width: 100%;
  height: 100%;
  .weixin {
    width: 100%;
    height: 100%;
    // background-color: rgba(247, 151, 25, 1);
    header {
      width: 100%;
      height: 100px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      padding: 0 360px;
      .logo {
        display: flex;
        align-items: end;
        img {
          // width: 44px;
          height: 36px;
          margin-right: 6px;
        }
        span {
          font-size: 24px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    main {
      padding: 32px 360px;
      .payment {
        height: 540px;
        padding: 40px 200px;
        opacity: 1;
        background: rgba(247, 247, 247, 1);
        box-shadow: 0 6px 32px rgba(0, 0, 0, 0.13);
        p.count {
          font-size: 24px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-bottom: 40px;
          span {
            font-size: 24px;
            font-weight: 700;
            color: rgba(236, 102, 0, 1);
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background: rgba(238, 238, 238, 1);
        }
        .content {
          display: flex;
          align-items: center;
          margin-top: 50px;
          .left {
            margin-right: 60px;
            .code {
              width: 260px;
              height: 260px;
            }
            p {
              margin-top: 8px;
              text-align: center;
              font-size: 18px;
              font-weight: 400;
              line-height: 26.06px;
              color: rgba(51, 51, 51, 1);
            }
          }
          .right {
            .item {
              margin: 20px 0;
              label {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 28.96px;
                color: rgba(153, 153, 153, 1);
              }
              span {
                font-size: 20px;
                font-weight: 400;
                line-height: 28.96px;
                color: rgba(51, 51, 51, 1);
              }
            }
          }
        }
      }
    }
  }
  .zfb {
    width: 100%;
    height: 100%;
  }
}
</style>
