<template>
  <div class="gold">
    <div class="top">
      <div class="left">
        <div class="left-title">
          <span class="text">我的龙币</span>
          <span class="warn">?</span>
          <div class="warn-dialog">
            <div class="title">尊敬的用户:</div>
            <div class="content">
              您所兑换的龙币，可以用于委托找房和客户首次电话沟通。龙币目前不支持退款以及提现的操作，请谨慎兑换
            </div>
            <div class="title">单次沟通费用：</div>
            <div class="content">电话沟通98龙币 委托找房198龙币</div>
          </div>
        </div>
        <div class="left-num">
          {{ balance }}
        </div>
        <div class="left-btn flex-align">
          <el-button
            class="recharge"
            type="primary"
            @click="toPush('/recharge')"
            >充值</el-button
          >
          <!-- <el-button class="withdraw" @click="goldVisible = true"
            >龙币预警</el-button
          > -->
        </div>
      </div>
      <div class="right">
        <div class="pie" id="pie"></div>
        <div class="pie-item">
          <div class="item">
            <div class="name" style="padding-left: 20px">龙币使用总量</div>
            <div class="num">{{ totalCoin }}</div>
            <div class="percentage"></div>
          </div>
          <div class="item" v-for="item in typeCoin" :key="item.type">
            <div
              class="name phone"
              :class="
                item.type === 3 ? 'house' : item.type === 4 ? 'phone' : 'other'
              "
            >
              {{ item.typeName }}
            </div>
            <div
              class="num"
              :style="{
                color:
                  item.type === 3
                    ? '#6e7cff'
                    : item.type === 4
                    ? '#ff8b33'
                    : '#1ea4fc'
              }"
            >
              {{ item.coin }}
            </div>
            <div class="percentage" v-show="totalBill">
              {{ parseInt((item.coin / totalCoin) * 100) }}%
            </div>
          </div>
        </div>
        <div class="search">
          <el-date-picker
            v-model="totalMonth"
            format="yyyy-MM"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            @change="getCoinBillTotal"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="bottom">
      <header>
        <div class="title">
          <div>龙币明细</div>
          <!-- <el-button class="btn" @click="toPush('/billingTitle')"
            >导出账单</el-button
          > -->
        </div>
        <div class="search">
          <el-date-picker
            v-model="pageMonth"
            format="yyyy-MM"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            @change="(page = 1), getCoinBillPage()"
          >
          </el-date-picker>
        </div>
      </header>
      <main>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="calc(100% - 100px)"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="dateFormat" label="时间"> </el-table-column>
          <el-table-column prop="id" label="流水号"> </el-table-column>
          <el-table-column prop="coin" label="龙币数量"> </el-table-column>
          <el-table-column prop="typeName" label="项目"> </el-table-column>
        </el-table>
        <div class="main-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            v-show="pageSize < total"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </main>
    </div>

    <!-- 龙币预警 -->
    <el-dialog title="龙币预警" :visible.sync="goldVisible" width="480px">
      <div class="body">
        <p class="gold-p">龙币低于设置的数量时，将会短信通知您！</p>
        <div class="gold-div">
          <span>设置</span>
          <input type="number" v-model="gold" />
          <span>龙币</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goldVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="setGoldWarn"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as echarts from 'echarts';
import {
  coinBillTotal,
  coinBillPage,
  coinWallet,
} from 'services/pay'
export default {
  name: 'Gold',
  data () {
    return {
      balance: 0,
      giveBalance: 0,
      userBalance: 0,
      month: "",
      totalMonth: '',
      pageMonth: '',
      tableData: [],
      totalCoin: null,
      typeCoin: [],
      pageSize: 10,
      total: 0,
      page: 1,
      multipleSelection: [],
      goldVisible: false,
      gold: 5000,
    };
  },
  watch: {},
  filters: {
    balanceFilter (val) {
      if (Number(val) / 10000 > 1) {
        return (Number(val) / 10000).toFixed(0)
      } else {
        return val
      }
    }
  },
  mounted () {
    // 获取当月
    const now = new Date();
    const y = now.getFullYear();
    const m = ('0' + (now.getMonth() + 1)).slice(-2);
    this.month = y + m
    this.totalMonth = y + m
    this.pageMonth = y + m
    this.getCoinBillTotal()
    this.getCoinBillPage()
    this.getCoinWallet()
  },
  methods: {
    // 查询龙币钱包
    getCoinWallet () {
      coinWallet().then(res => {
        if (res.data.status === 200) {
          let { balance, giveBalance, userBalance } = res.data.data
          this.balance = balance
          this.giveBalance = giveBalance
          this.userBalance = userBalance
        }
      })
    },
    // 按月查询龙币总量
    getCoinBillTotal () {
      if (!this.totalMonth) this.totalMonth = this.month
      coinBillTotal({ month: this.totalMonth }).then(res => {
        if (res.data.status === 200) {
          let { totalCoin, typeCoin } = res.data.data
          this.totalCoin = totalCoin || 0
          if (typeCoin.length > 0) {
            this.typeCoin = typeCoin.map(item => {
              return {
                ...item,
                value: item.coin,
                name: item.typeName
              }
            })
          } else {
            this.typeCoin = [
              { value: 0, coin: 0, amount: 0, name: '来电咨询', type: 4, typeName: '来电咨询' },
              { value: 0, coin: 0, amount: 0, name: '其它', type: 6, typeName: '其它' },
            ]
          }
          let arr = []
          arr[0] = this.typeCoin.filter(e => e.type === 3)[0];
          arr[1] = this.typeCoin.filter(e => e.type === 4)[0];
          arr[2] = this.typeCoin.filter(e => e.type === 6)[0];
          console.log(arr);
          this.setPie(arr)

        }
      })
    },
    // 分页查询龙币账单
    getCoinBillPage () {
      let params = {
        page: this.page,
        month: this.pageMonth
      }
      coinBillPage(params).then(res => {
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    setPie (arr) {
      var chartDom = document.getElementById('pie');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          show: false
        },
        color: arr.filter(item => item).map(item => {
          if (item.type === 3) {
            return new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: '#6E7CFF'
              },
              {
                offset: 1,
                color: '#C5C5FC'
              }
            ])
          }
          if (item.type === 4) {
            return new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: '#FA862D'
              },
              {
                offset: 1,
                color: '#FFC880'
              }
            ])
          }
          if (item.type === 6) {
            return new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 0,
                color: '#178BFF'
              },
              {
                offset: 1,
                color: '#5ECFFF'
              }
            ])
          }
        }),
        graphic: [
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '35%',
            z: 10,
            style: {
              text: '龙币',
              textAlign: 'center',
              fill: '#333333', //文字的颜色
              fontSize: 24,
              lineHeight: 16
            }
          },
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '50%',
            z: 10,
            style: {
              text: '汇总',
              textAlign: 'center',
              fill: '#333333', //文字的颜色
              fontSize: 24,
              lineHeight: 16
            }
          },
        ],

        series: [
          {
            color: [
              new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                {
                  offset: 0,
                  color: '#FFF9F2'
                },
                {
                  offset: 1,
                  color: '#f9f0e5'
                }
              ])
            ], //内圆颜色
            type: 'pie',
            radius: '67%',
            center: ['50%', '50%'], //设置圆的位置，两个圆的位置必须一致
            data: [
              { value: 0, name: '' } //只设置一个值占整圆
            ],
            emphasis: {
              scale: false //鼠标悬停到内圆时，取消放大效果
            },
            label: {
              show: false //关闭牵引线
            }
          },
          {
            name: 'Access From',
            type: 'pie',
            radius: ['73%', '93%'],
            avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              scale: false, //鼠标悬停到内圆时，取消放大效果
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      };

      option && myChart.setOption(option);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.getCoinBillPage()
    },
    makeBilling (item) {
      console.log(item);
      this.$router.push({
        path: '/billingInfo'
      })
    },
    handleSelectionChange (val) {
      console.log(val);
      this.multipleSelection = val
    },
    // 龙币预警
    setGoldWarn () {
      this.goldVisible = false;
    },
    toPush (path) {
      this.$router.push({
        path
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.gold {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(250, 248, 245, 1) !important;
  .top {
    width: 100%;
    height: 240px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .left {
      width: 32%;
      height: 100%;
      margin-right: 20px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      padding: 35px 30px;
      background: url(../../assets/my-gold.png) #fff no-repeat;
      background-position: bottom right;
      &-title {
        display: flex;
        align-items: center;
        position: relative;
        .text {
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 34px;
          color: rgba(102, 102, 102, 1);
          margin-right: 8px;
        }
        .warn {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid rgba(236, 102, 0, 1);
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
        }
        .warn:hover + .warn-dialog {
          display: block;
        }
        .warn-dialog {
          position: absolute;
          display: none;
          top: 30px;
          padding: 16px;
          border-radius: 4px;
          background-color: #fff;
          box-shadow: 0 0 6px 0 rgba(13, 4, 9, 0.2);
          z-index: 100;
          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 36px;
            color: rgba(0, 0, 0, 1);
          }
          .content {
            font-size: 14px;
            font-weight: 400;
            line-height: 36px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      &-num {
        font-size: 46px;
        font-weight: 600;
        line-height: 56px;
        color: rgba(0, 0, 0, 1);
        font-family: DIN;
        margin: 4px 0 22px;
      }
      &-btn {
        .recharge {
          width: 96px;
          height: 42px;
          margin-right: 16px;
          border-radius: 6px;
          padding: 0;
          border: 0;
          font-size: 18px;
          background: linear-gradient(
            270deg,
            rgba(204, 209, 255, 1) 0%,
            rgba(128, 140, 255, 1) 100%
          );
        }
        .withdraw {
          width: 96px;
          height: 42px;
          padding: 0;
          border-radius: 6px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(64, 79, 214, 1);
          color: rgba(64, 79, 214, 1);
          font-size: 18px;
        }
      }
    }
    .right {
      width: calc(68% - 20px);
      height: 100%;
      border-radius: 12px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
      padding: 20px 24px;
      display: flex;
      position: relative;
      .pie {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        margin: 15px 40px 0 50px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.08) !important;
      }
      .pie-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .item {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .name {
            width: 190px;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 1);
            text-align: center;
            margin-right: 40px;
            &.phone {
              display: flex;
              align-items: center;
              &::before {
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                margin-right: 32px;
                border-radius: 50%;
                opacity: 1;
                background: #ff8b33;
              }
            }
            &.house {
              display: flex;
              align-items: center;
              &::before {
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                margin-right: 32px;
                border-radius: 50%;
                opacity: 1;
                background: #6e7cff;
              }
            }
            &.other {
              display: flex;
              align-items: center;
              &::before {
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                margin-right: 32px;
                border-radius: 50%;
                opacity: 1;
                background: #1ea4fc;
              }
            }
          }
          .num {
            width: 100px;
            // text-align: center;
            font-family: DIN;
            font-size: 28px;
            font-weight: 600;
            line-height: 34px;
            color: rgba(0, 0, 0, 1);
            margin-right: 40px;
          }
          .percentage {
            font-family: DIN;
            font-size: 28px;
            font-weight: 500;
            line-height: 38px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      .search {
        position: absolute;
        top: 20px;
        right: 24px;
      }
    }
  }
  .bottom {
    width: 100%;
    height: calc(100% - 260px);
    background-color: #fff;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 0 24px;
      border-bottom: 1px solid #f7f5f2;
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        color: rgba(51, 51, 51, 1);
        display: flex;
        align-items: center;
        .btn {
          border-radius: 4px;
          background: #ffede0;
          border: 1px solid #ffede0;
          font-size: 16px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
          margin-left: 16px;
        }
      }
    }
    main {
      padding: 20px 24px;
      width: 100%;
      height: calc(100% - 71px);
      overflow: auto;
      .main-page {
        display: flex;
        justify-content: end;
        margin-top: 24px;
        /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
          background: #ffe6d4;
          font-size: 14px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
        }
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    .picker {
      width: 270px;
      height: 38px;
      /deep/ .el-date-editor .el-range-separator {
        width: 24px;
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .gold-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-bottom: 20px;
  }
  .gold-div {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    input {
      width: 130px;
      height: 50px;
      text-align: center;
      opacity: 1;
      border-radius: 4px;
      background: #fcf4ef;
      margin: 0 16px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

/deep/ .el-table__empty-block {
  height: 300px !important;
}
</style>
  