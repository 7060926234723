<template>
  <div class="basic-info">
    <div class="title">
      <div>基本消息</div>
      <!-- <el-button class="btn" @click="toPush('/billingTitle')"
        >保存信息</el-button
      > -->
    </div>
    <main>
      <div class="info">
        <div class="label">头像</div>
        <div class="content">
          <i
            style="width: 30px; height: 30px"
            v-if="!userInfo.phone || !userInfo.headImg"
            class="icon icon-user"
          ></i>
          <img :src="los + userInfo.headImg + '?' + Date.now()" alt="" />
        </div>
        <!-- <div class="edit" @click="editPicture">修改</div> -->
      </div>
      <!-- <div class="info">
        <div class="label">我的评分</div>
        <div class="content">
          <i
            v-for="i in 5"
            class="icon icon-rate"
            style="margin-right: 5px"
            :key="i"
            :class="4 < i ? 'icon-rate-gray' : ''"
          ></i>
        </div>
      </div> -->
      <!-- <div class="info">
        <div class="label">身份类型</div>
        <div class="content">经纪人</div>
      </div> -->
      <div class="info">
        <div class="label">姓名</div>
        <div class="content">{{ userInfo.name }}</div>
        <!-- <div class="edit" @click="editNickName">修改</div> -->
      </div>
      <div class="info">
        <div class="label">绑定手机号</div>
        <div class="content">{{ userInfo.phone }}</div>
        <div class="edit" @click="editPhone">修改</div>
      </div>
      <!-- <div class="info">
        <div class="label">认证信息</div>
        <div class="content">
          <img :src="los + userInfo.certifiedImg + '?' + Date.now()" alt="" />
        </div>
      </div> -->
    </main>

    <!-- 修改姓名 -->
    <el-dialog
      title="修改姓名"
      :visible.sync="nickNameVisible"
      width="480px"
      :before-close="handleClose"
    >
      <div class="body">
        <el-input v-model="nickname" placeholder="请输入..."></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nickNameVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="nickNameVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改头像 -->
    <el-dialog
      title="修改头像"
      :visible.sync="pictureVisible"
      width="480px"
      :before-close="pictureClose"
    >
      <div class="body">
        <div class="img">
          <img :src="picture" alt="" />
        </div>
        <div class="text">
          *上传文件大于20k,小于600k,支持jpg、jpe、 png格式的图片
        </div>
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
          :limit="1"
        >
          <div class="el-upload__text">选择头像</div>
        </el-upload>
      </div>
    </el-dialog>

    <!-- 修改绑定手机号 -->
    <el-dialog
      title="修改绑定手机号"
      :visible.sync="phoneVisible"
      width="480px"
      :before-close="phoneClose"
    >
      <div class="body">
        <el-input
          style="margin-bottom: 20px"
          v-model="newPhone"
          placeholder="请输入新手机号码"
        >
          <i slot="prefix" class="icon icon-phone"></i>
          <span slot="suffix" class="code" @click="setCode">{{
            count > 59 ? '获取验证码' : count
          }}</span>
        </el-input>
        <el-input v-model="code" placeholder="请输入验证码">
          <i slot="prefix" class="icon icon-code"></i>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="phoneVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="submitChangePhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 认证信息 -->
    <el-dialog
      title="修改认证信息"
      :visible.sync="infoVisible"
      width="480px"
      :before-close="infoClose"
    >
      <div class="body">
        <div class="img">
          <img :src="authentication" alt="" />
        </div>
        <div class="text">
          *上传文件大于20k,小于600k,支持jpg、jpe、 png格式的图片
        </div>
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
          :limit="1"
        >
          <div class="el-upload__text">选择头像</div>
        </el-upload>
      </div>
    </el-dialog>

    <!-- 修改登录密码 -->
    <el-dialog
      title="修改登录密码"
      :visible.sync="passwordVisible"
      width="480px"
      :before-close="passwordClose"
    >
      <div class="body">
        <el-input
          style="margin-bottom: 20px"
          v-model="password"
          placeholder="输入新密码"
        >
        </el-input>
        <el-input v-model="newpassword" placeholder="再次输入新密码">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="passwordVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
const los = window.los
import { userInfo, changePhoneCode, changePhone } from 'services/broker';
// import Cookies from "js-cookie";
export default {
  name: 'BasicInfo',
  components: {},
  data () {
    return {
      los,
      nickname: '',
      nickNameVisible: false,
      picture: 'https://img.js.design/assets/img/65116bf3d55e5b8b472369de.png#c7ea4c02a55ed89df2cd1459276ecb03',
      pictureVisible: false,
      newPhone: '',
      code: '',
      phoneVisible: false,
      authentication: 'https://img.js.design/assets/img/651f709224d1cb4fb44de851.png#54edd210a7c739c71dabe6e682f96d3a',
      infoVisible: false,
      count: 60,
      password: '',
      newpassword: '',
      passwordVisible: false,
      userInfo: {}
    }
  },
  mounted () {
    // 获取用户信息
    this.getUserInfo()
    // Cookies.set('name', 'value')
  },
  methods: {
    getUserInfo () {
      // 获取用户信息
      userInfo().then(res => {
        if (res.data.status === 200) {
          localStorage.userInfo = JSON.stringify(res.data.data)
          this.userInfo = res.data.data
        }
      })
    },
    // 验证码
    setCode () {
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.newPhone) {
        return this.$message.warning('请输入新手机号！')
      } else if (!reg.test(this.newPhone)) {
        return this.$message.warning('请正确输入手机号')
      } else if (!this.timer) {
        changePhoneCode({ newPhone: this.newPhone }).then(res => {
          if (res.data.status === 200) {
            this.timer = setInterval(() => {
              this.count--
              console.log(this.count);
              if (this.count < 1) {
                clearInterval(this.timer)
                this.timer = null
                this.count = 60
              }
            }, 1000)
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })

      }
    },
    // 关闭修改姓名弹窗
    handleClose () {
      this.nickNameVisible = false
    },
    // 关闭头像弹窗
    pictureClose () {
      this.pictureVisible = false
    },
    // 关闭手机弹窗
    phoneClose () {
      this.phoneVisible = false
    },
    passwordClose () {
      this.passwordVisible = false
    },
    // 关闭认证信息弹窗
    infoClose () {
      this.infoVisible = false
    },
    // 修改姓名
    editNickName () {
      this.nickNameVisible = true
    },
    // 修改认证信息
    editAuthentication () {
      this.infoVisible = true
    },
    // 修改头像
    editPicture () {
      this.pictureVisible = true
    },
    // 修改手机
    editPhone () {
      this.phoneVisible = true
    },
    editPassWord () {
      this.passwordVisible = true
    },
    submitChangePhone () {
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.newPhone) {
        return this.$message.warning('请输入新手机号！')
      } else if (!reg.test(this.newPhone)) {
        return this.$message.warning('请正确输入手机号')
      } else if (!this.code) {
        return this.$message.warning('请输入验证码')
      } else {
        let params = {
          newPhone: this.newPhone,
          code: this.code
        }
        changePhone(params).then(res => {
          if (res.data.status === 200) {
            this.phoneVisible = false
            this.getUserInfo()
            this.newPhone = ''
            this.code = ''
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }

    }
  }
};
</script>

<style lang="less" scoped>
.basic-info {
  height: 100%;
  .title {
    font-size: 22px;
    font-weight: 600;
    height: 70px;
    color: rgba(51, 51, 51, 1);
    border-bottom: 1px solid rgba(247, 245, 242, 1);
    display: flex;
    align-items: center;
    padding: 0 20px;
    .btn {
      border-radius: 4px;
      background: #ffede0;
      border: 1px solid #ffede0;
      font-size: 16px;
      font-weight: 400;
      color: rgba(236, 102, 0, 1);
      margin-left: 16px;
    }
  }
  main {
    padding: 60px 100px;
    .info {
      display: flex;
      align-items: center;
      padding-bottom: 32px;
      .label {
        width: 90px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        margin-right: 120px;
        color: rgba(153, 153, 153, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        width: 260px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(51, 51, 51, 1);
        i {
          width: 100px;
          height: 100px;
          border-radius: 4px;
          border: 1px solid #eee;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 4px;
          border: 1px solid #eee;
        }
      }
      .edit {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 4px;
        color: rgba(236, 102, 0, 1);
        background: rgba(255, 243, 235, 1);
        cursor: pointer;
      }
    }
  }
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.body {
  /deep/ .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    background: rgba(247, 247, 247, 1);
    border: 0;
  }
  /deep/ .el-input__prefix {
    display: flex;
    align-items: center;
  }
  .img {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 16px;
    img {
      width: 120px;
      height: 100%;
      border: 1px solid rgba(238, 238, 238, 1);
    }
  }
  .text {
    padding: 0 60px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 20px;
  }
  .code {
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    color: rgba(236, 102, 0, 1);
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
.upload-demo {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
  /deep/ .el-upload {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: rgba(255, 243, 235, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: rgba(236, 102, 0, 1);
  }
  /deep/ .el-upload-list {
    display: none;
  }
}
</style>