<template>
  <div class="tape-record">
    <header>
      <div class="title">带看记录</div>
      <div class="search">
        <el-input
          class="inp"
          placeholder="客户姓名搜索"
          v-model="name"
          @change="(page = 1), getList()"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-input
          class="inp"
          placeholder="请输入联系电话"
          v-model="phone"
          @change="(page = 1), getList()"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-date-picker
          class="picker"
          v-model="day"
          format="yyyy-MM-dd"
          value-format="yyyyMMdd"
          type="date"
          placeholder="选择日期"
          @change="(page = 1), getList()"
        >
        </el-date-picker>
      </div>
    </header>
    <main>
      <TableTab :tab="tab" :tabIndex="tabIndex" @switchTab="switchTab" />
      <el-table
        class="table"
        :data="tableData"
        border
        height="calc(100% - 90px)"
        style="width: 100%"
        v-loading="Loading"
      >
        <el-table-column type="index" label="序号" width="60px">
        </el-table-column>
        <el-table-column prop="name" label="客户姓名"></el-table-column>
        <el-table-column prop="phone" label="电话"> </el-table-column>
        <el-table-column prop="viewTimeFormat" label="带看时间">
        </el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="scope">
            <div class="flex" v-if="scope.row.expires">已过期</div>
            <div class="flex" v-else>
              {{ scope.row.statusName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          width="240px"
        >
          <template slot-scope="scope">
            <div v-if="tabIndex !== '2'">
              <el-button
                class="look"
                v-if="scope.row.status !== 2"
                type="text"
                size="small"
              >
                修改带看时间
                <el-date-picker
                  v-model="time"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyyMMdd"
                  :clearable="false"
                  class="picker"
                  :picker-options="pickerOptions"
                  @change="takeView(scope.row)"
                >
                </el-date-picker>
              </el-button>
              <el-button
                type="text"
                v-if="scope.row.status !== 2"
                @click="setStatus(scope.row)"
                size="small"
                >确认带看</el-button
              >
              <el-dropdown @command="a => handleCommand(a, scope.row)">
                <span class="el-dropdown-link"> ... </span>
                <el-dropdown-menu style="padding: 5px 0" slot="dropdown">
                  <el-dropdown-item command="del">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              <el-button
                class="look"
                type="text"
                size="small"
                @click="del(scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 回电弹窗 -->
    <div v-if="visible" class="code" :style="{ top: top - 70 + 'px' }">
      <p>18874766241</p>
      <img
        src="https://img.js.design/assets/img/65128ee59acc63fd52e6ab72.png#a50d683c2dbf65e8df7fdedf8d406a7b"
        alt=""
      />
      <p>扫码回电更快捷</p>
    </div>
    <!-- 客户详细信息 -->
    <el-dialog
      title="客户详细信息"
      :visible.sync="remarkVisible"
      width="800px"
      :before-close="remarkHandleClose"
    >
      <div class="body">
        <div class="details-item">
          <div class="name">
            <label for="">客户姓名：</label>
            <span class="content">张无忌</span>
          </div>
          <div class="name">
            <label for="" style="width: 48px">电话：</label>
            <span class="content">1885324224</span>
          </div>
          <div class="name">
            <label for="" style="width: 48px">标签：</label>
            <span class="content label" :class="labelColor({ label: '1' })"
              >高意向</span
            >
          </div>
        </div>
        <div class="details-item">
          <label for="">沟通记录：</label>
          <div class="link-up">
            <div class="link-up-item">
              <div class="time">2023-10-08 12:11:37</div>
              <div class="link-up-type">来电</div>
              <div class="note">了解房源情况，意向比较大</div>
              <div class="line"></div>
            </div>
            <div class="link-up-item">
              <div class="time">2023-10-08 12:11:37</div>
              <div class="link-up-type">来电</div>
              <div class="note">了解房源情况，意向比较大</div>
              <div class="line"></div>
            </div>
            <div class="link-up-item active">
              <div class="time">2023-10-08 12:11:37</div>
              <div class="link-up-type">来电</div>
              <div class="note">了解房源情况，意向比较大</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="details-item">
          <label for="">沟通房源：</label>
          <div class="look-house">
            <UserLookHouse :plantList="plantList" />
          </div>
        </div>
        <div class="details-item">
          <label for="">备注：</label>
          <span class="content"
            >还想再多看看，了解下其他的房源，然后再做结论</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" type="primary" @click="onRemark">确定</el-button>
      </span>
    </el-dialog>
    <!-- 确认带看 -->
    <el-dialog title="确认带看" :visible.sync="takeVisible" width="480px">
      <div class="body">
        <p class="del">请确认是否带看？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="takeVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="yesTake">确定</el-button>
      </span>
    </el-dialog>
    <!-- 记录删除 -->
    <el-dialog
      title="记录删除"
      :visible.sync="deleteVisible"
      width="480px"
      :before-close="deleteHandleClose"
    >
      <div class="body">
        <p class="del">请确认是否删除该记录？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noDelete">取消</el-button>
        <el-button class="btn" type="primary" @click="yesDelete"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  takeViewPage,
  // takeViewCount,
  takeViewDel,
  takeViewTake,
  takeViewChangeTime
} from 'services/broker'
import TableTab from 'components/TableTab.vue';
import UserLookHouse from 'components/user/UserLookHouse.vue';
export default {
  name: 'TapeRecord',
  components: {
    TableTab,
    UserLookHouse
  },
  data () {
    return {
      day: '',
      pickerOptions: {
        disabledDate (v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      name: '',
      phone: '',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '已处理'
      }, {
        value: '2',
        label: '未处理'
      }],
      value: '',
      tab: [{
        id: '1',
        label: '未带看',
        prompt: ''
      }, {
        id: '2',
        label: '已带看',
        prompt: ''
      }, {
        id: '3',
        label: '已过期',
        prompt: ''
      }],
      expires: true,
      tabIndex: '1',
      label: '',
      labelOptions: [{
        value: '1',
        label: '高意向'
      }, {
        value: '2',
        label: '中意向'
      }, {
        value: '3',
        label: '低意向'
      }, {
        value: '4',
        label: '无意向'
      }],

      plantList: [
        {
          id: 1,
          type: '1', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租坪地低碳城商业写字楼综合体100平起租',
          count: '价格面议',
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库', '有宿舍', '有厂库',]
        },
        {
          id: 2,
          type: '2', // vr video
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          titel: '坪地低碳城商业写字楼综合体100平起租',
          count: '18/㎡/月',
          label1: ['7000㎡', '独栋厂房', '全新'],
          label2: ['近高速', '有宿舍', '有厂库']
        },
      ],
      time: '',
      value1: [],
      editName: '',
      tableData: [],
      Loading: false,
      pageSize: 10,
      total: 0,
      page: 1,
      top: 0,
      visible: false,
      selectId: null,
      selectRemark: '',
      remarkVisible: false,
      takeVisible: false,
      deleteVisible: false
    };
  },
  filters: {
    labelFilter (label) {
      if (label === '1') return '高意向'
      if (label === '2') return '中意向'
      if (label === '3') return '低意向'
      if (label === '4') return '无意向'
    }
  },
  watch: {
    deleteVisible (val) {
      if (!val) {
        this.deleteVisible = false
      }
    }
  },
  mounted () {
    if (this.$route.params.day) {
      this.day = this.$route.params.day.split('-').join('')
    }
    this.getList()
  },
  methods: {
    getList () {
      let params = {
        name: this.name,
        phone: this.phone,
        status: Number(this.tabIndex),
        expires: this.tabIndex === '3' ? this.expires : '',
        page: this.page,
        day: this.day
      }
      this.Loading = true
      takeViewPage(params).then(res => {
        this.Loading = false
        if (res.data.status === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    // tab切换
    switchTab (id) {
      console.log(id);
      this.tabIndex = id
      this.page = 1
      this.getList()
    },
    // 标签颜色函数
    labelColor (item) {
      if (item.label === '1') return 'label-r'
      if (item.label === '2') return 'label-y'
      if (item.label === '3') return 'label-b'
      if (item.label === '4') return 'label-c'
    },
    // 查看
    details (item) {
      this.selectId = item.id
      this.remarkVisible = true

    },
    edit (item) {
      item.nameShow = true
      this.editName = item.name
      this.label = item.label
    },
    setStatus (item) {
      this.selectId = item.id
      this.takeVisible = true
    },
    takeView (item) {
      if (this.time)
        takeViewChangeTime({ id: item.id, day: this.time }).then(res => {
          if (res.data.status === 200) {
            this.getList()
            return this.$message({ message: res.data.msg, type: 'success' });
          }
        })
    },
    handleCommand (command, item) {
      if (command === 'del') this.del(item)
    },
    del (item) {
      this.selectId = item.id
      this.deleteVisible = true
    },
    save (item) {
      item.nameShow = false
    },
    // phone (item, $event) {
    //   if (this.selectId !== item.id) {
    //     this.visible = true
    //     this.top = $event.clientY
    //     this.selectId = item.id
    //   } else {
    //     this.visible = false
    //     this.selectId = null
    //   }
    // },
    remarkHandleClose () {
      this.remarkVisible = false
    },
    onRemark () {
      this.remarkVisible = false
    },
    deleteHandleClose () {
      this.deleteVisible = false
      this.selectId = null
    },
    // 拒绝删除
    noDelete () {
      this.deleteVisible = false
    },
    // 确认删除
    yesDelete () {
      takeViewDel({ id: this.selectId }).then(res => {
        if (res.data.status === 200) {
          this.selectId = null
          this.deleteVisible = false
          this.page = 1
          this.getList()
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },
    // 确认带看
    yesTake () {
      takeViewTake({ id: this.selectId }).then(res => {
        if (res.data.status === 200) {
          this.selectId = null
          this.takeVisible = false
          this.page = 1
          this.getList()
          return this.$message({ message: res.data.msg, type: 'success' });
        }
      })
    },
  }
};
  </script>
  <style lang="less" scoped>
.tape-record {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
    }
    .search {
      display: flex;
      align-items: center;
      .inp {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .sel {
        margin-right: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .picker {
        width: 270px;
        height: 38px;
        /deep/ .el-date-editor .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    .table {
      .look {
        position: relative;
        cursor: pointer;
        .picker {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          opacity: 0;
          cursor: pointer;
          /deep/ .el-input__inner {
            cursor: pointer;
          }
        }
      }
      .save {
        width: 204px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        border: 0;
      }
      /deep/ .el-input__inner {
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(245, 245, 245, 1);
        border: 0;
      }
    }
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 600;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .code {
    position: absolute;
    top: 30px;
    right: 40px;
    padding: 10px 20px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px rgba(41, 18, 0, 0.04) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 110px;
      height: 114px;
    }
    p {
      &:first-child {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
      }
      &:last-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}

.label {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    margin-right: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
  }
  &-r {
    color: rgba(217, 59, 59, 1) !important;
    &::before {
      background-color: rgba(217, 59, 59, 1) !important;
    }
  }
  &-y {
    color: rgba(255, 176, 49, 1) !important;
    &::before {
      background-color: rgba(255, 176, 49, 1) !important;
    }
  }
  &-b {
    color: rgba(109, 87, 255, 1) !important;
    &::before {
      background-color: rgba(109, 87, 255, 1) !important;
    }
  }
  &-c {
    color: rgba(153, 153, 153, 1) !important;
    &::before {
      background-color: rgba(153, 153, 153, 1) !important;
    }
  }
}
/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #e6edfa;
}
/deep/ .el-button--text {
  color: #ec6600;
}
/deep/ .el-switch {
  display: flex !important;
  align-items: center;
  position: relative;
  .el-switch__label * {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .el-switch__label--left {
    position: absolute;
    left: 8px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  .el-switch__label--right {
    position: absolute;
    left: 16px;
    z-index: 99;
    &.is-active {
      display: none;
    }
  }
  &.is-checked .el-switch__core {
    &::after {
      top: 5px;
      left: 65px;
    }
  }
  .el-switch__core {
    width: 72px !important;
    height: 28px;
    opacity: 1;
    border-radius: 20px;
    &::after {
      top: 5px;
      left: 5px;
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
  .del {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .details-item {
    display: flex;
    margin-bottom: 20px;
    &:first-child {
      align-items: center;
      // justify-content: space-between;
    }
    .name {
      display: flex;
      align-items: center;
      margin-right: 100px;
    }
    label {
      width: 80px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(153, 153, 153, 1);
    }
    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .look-house {
      width: calc(100% - 100px);
      max-height: 320px;
      overflow: auto;
    }
    .link-up {
      width: calc(100% - 100px);
      position: relative;
      &::before {
        content: '';
        display: flex;
        width: 2px;
        height: 100%;
        background: #dddddd;
        position: absolute;
        left: 2px;
        z-index: 0;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &::before {
          content: '';
          display: flex;
          width: 7px;
          height: 7px;
          background: rgba(179, 179, 179, 1);
          border-radius: 50%;
          margin-right: 12px;
          z-index: 1;
        }
        .time {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(153, 153, 153, 1);
        }
        .link-up-type {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
          margin: 0 24px;
        }
        .note {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(153, 153, 153, 1);
        }
        .line {
        }
        &.active {
          &::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(236, 102, 0, 0.1);
            border: 3px solid rgba(236, 102, 0, 0.1);
            margin-left: -4px;
          }
          &::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba(236, 102, 0, 1);
            margin-left: -4px;
            position: absolute;
            left: 3px;
          }
          .time {
            color: rgba(236, 102, 0, 1);
          }
          .link-up-type {
            color: rgba(236, 102, 0, 1);
          }
          .note {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  