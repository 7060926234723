<template>
  <div class="publish-housing-office">
    <div class="steps">
      <div class="step-item">
        <div class="step-text"></div>
        <div class="step-icon"></div>
      </div>
      <div
        class="step-item"
        v-for="item in steps"
        :key="item.id"
        @click="getStep(item)"
      >
        <div class="step-text" :class="step === item.id ? 'active' : ''">
          {{ item.label }}
        </div>
        <div class="step-icon" :class="step === item.id ? 'active' : ''"></div>
      </div>
      <div class="step-item">
        <div class="step-text"></div>
        <div class="step-icon"></div>
      </div>
    </div>
    <main id="main">
      <!-- 发布信息 -->
      <p class="title" id="id1">发布信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">标题：</label>
          <el-input
            maxlength="30"
            minlength="4"
            v-model="ruleForm.title"
            placeholder="请输入房源名称"
          ></el-input>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">总面积：</label>
          <el-input
            type="Number"
            v-model.number="ruleForm.totalArea"
            @input="e => NumberCheck(e, 'totalArea')"
            placeholder="请输入总面积"
          >
            <span slot="suffix">㎡</span>
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required">工位数：</label>
          <div class="flex-align">
            <el-input
              type="Number"
              v-model.number="ruleForm.minWorkstations"
              @input="value => changeWorkstations(value, 'minWorkstations')"
              placeholder="最小工位数"
              @blur="blurMaxWorkstations"
            >
            </el-input>
            -
            <el-input
              type="Number"
              v-model.number="ruleForm.maxWorkstations"
              @input="value => changeWorkstations(value, 'maxWorkstations')"
              placeholder="最大工位数"
              @blur="blurMaxWorkstations"
            >
            </el-input>
          </div>
        </el-col>
        <!-- <el-col class="flex-align" :span="5">
          <el-checkbox v-model="checked">全景建模</el-checkbox>
          <el-tooltip class="item" effect="dark" placement="bottom-start">
            <div slot="content" style="width: 400px">
              我司运用全景建模以及全景内部可视化设计，方便客户线上直观了解您的房源，避免出现不必要的非客户兴趣房源带看的线下成本高效促进房源成交率，无论租房或是卖房，全景运用可以终身使用。
            </div>
            <div style="margin-left: 8px">
              <i class="el-icon-question"></i>
            </div>
          </el-tooltip>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="" for="">得房率：</label>
          <el-input
            max="100"
            min="0"
            type="number"
            v-model.number="ruleForm.constructionRatio"
            placeholder="请输入得房率1-100"
            @input="
              value => {
                if (value < 0) ruleForm.constructionRatio = 0;
                if (value > 100) ruleForm.constructionRatio = 100;
              }
            "
          >
            <span slot="suffix">%</span>
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required">租金总价：</label>
          <el-input
            v-if="priceType"
            type="Number"
            v-model="ruleForm.totalPrice"
            @input="e => NumberCheck(e, 'totalPrice')"
            placeholder="租金"
          >
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
        <p>
          租金单价：
          {{
            ruleForm.totalPrice / ruleForm.totalArea &&
            ruleForm.totalPrice &&
            ruleForm.totalArea
              ? parseInt(ruleForm.totalPrice / ruleForm.totalArea)
              : '0'
          }}元/㎡/月
        </p>
      </el-row>
      <!-- 选择楼盘 -->
      <p class="title" id="id2">选择楼盘</p>
      <el-row>
        <el-col :span="24">
          <label class="required" for="">位置：</label>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="provinceId"
            placeholder="选择省"
            @change="id => getAddress(id, 1)"
          >
            <el-option
              v-for="item in provinceOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="cityId"
            clearable
            placeholder="选择市"
            @change="id => getAddress(id, 2)"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div
            class="sel-buildings"
            @click="
              () => {
                page = 1;
                selectBuild();
              }
            "
          >
            {{ buildingName ? buildingName : '请选择楼盘' }}
          </div>
        </el-col>
      </el-row>
      <div class="map">
        <MapMark v-if="mapShow" ref="mapMark" :adress="adress" />
      </div>
      <!-- 基本信息 -->
      <p class="title" id="id3">基本信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">装修情况：</label>
          <el-select v-model="ruleForm.decoration" placeholder="装修情况">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col class="" :span="8">
          <label class="required" for="">楼层：</label>
          <el-input
            type="Number"
            v-model="ruleForm.floor"
            @input="e => NumberCheck(e, 'floor')"
            placeholder="楼层"
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">工商注册：</label>
          <el-select v-model="ruleForm.businessRegister" placeholder="工商注册">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">可否分割：</label>
          <el-select v-model="ruleForm.partition" placeholder="可否分割">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">朝向：</label>
          <el-select v-model="ruleForm.orientation" placeholder="朝向">
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">总层数：</label>
          <el-input
            type="Number"
            v-model="ruleForm.totalFloor"
            @input="e => NumberCheck(e, 'totalFloor')"
            placeholder="总层数"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">客梯数：</label>
          <el-input
            type="Number"
            v-model="ruleForm.passengerElevatorNumber"
            @input="e => NumberCheck(e, 'passengerElevatorNumber')"
            placeholder="客梯数"
          >
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label class="required" for="">层高：</label>
          <el-input
            type="Number"
            v-model="ruleForm.floorHeight"
            @input="e => NumberCheck(e, 'floorHeight')"
            placeholder="层高"
          >
            <span slot="suffix">米</span>
          </el-input>
        </el-col>
      </el-row>
      <!-- 出租信息 -->
      <p class="title" id="id4">出租信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">起租时间：</label>
          <el-select v-model="ruleForm.minLeaseMonth" placeholder="起租时间">
            <el-option
              v-for="item in options5"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">可租时间：</label>
          <el-date-picker
            style="width: 100%"
            v-model="ruleForm.canLeaseTime"
            type="month"
            format="yyyy-MM"
            value-format="yyyyMM"
            :picker-options="pickerOptions1"
            placeholder="选择可租时间"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">看房时间：</label>
          <el-select v-model="ruleForm.lookTime" placeholder="看房时间">
            <el-option
              v-for="item in options8"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">押金：</label>
          <el-input
            maxlength="8"
            v-model="ruleForm.cashPledge"
            placeholder="押金：如押*付*"
          >
          </el-input>
        </el-col>
      </el-row>
      <!-- 配套设施 -->
      <p class="title" id="id5">配套设施</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.state ? 'active' : ''"
          v-for="item in facilityList"
          :key="item.id"
          @click="item.state = !item.state"
        >
          {{ item.name }}
        </div>
        <!-- <div class="facility-item" @click="addWareHouse('选择仓库')">
          + 添加仓库房源
        </div>
        <div class="facility-item" @click="addWareHouse('选择宿舍')">
          + 添加宿舍房源
        </div> -->
      </div>
      <div class="add-house"></div>
      <!-- 价格详情 -->
      <p class="title" id="id6">价格详情</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">物业费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.propertyCost"
            @input="e => NumberCheck(e, 'propertyCost')"
            placeholder="请输入物业费"
          >
            <span slot="suffix">元/㎡/月</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">空调费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.airConditioningFee"
            @input="e => NumberCheck(e, 'airConditioningFee')"
            placeholder="请输入空调费"
            :disabled="airConditioningFee == '1'"
          >
            <el-select
              class="inp-sel"
              v-model="airConditioningFee"
              style="width: 120px !important"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="已包含" value="1"></el-option>
              <el-option label="其他" value="2"></el-option>
            </el-select>
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">水费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.waterFee"
            @input="e => NumberCheck(e, 'waterFee')"
            placeholder="请输入水费"
            :disabled="waterFee == '1'"
          >
            <el-select
              class="inp-sel"
              v-model="waterFee"
              style="width: 120px !important"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="已包含" value="1"></el-option>
              <el-option label="其他" value="2"></el-option>
            </el-select>
            <span slot="suffix">元/m³</span>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="" :span="8">
          <label for="">电费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.electricFee"
            @input="e => NumberCheck(e, 'electricFee')"
            placeholder="请输入电费"
            :disabled="electricFee == '1'"
          >
            <el-select
              class="inp-sel"
              v-model="electricFee"
              style="width: 120px !important"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="已包含" value="1"></el-option>
              <el-option label="其他" value="2"></el-option>
            </el-select>
            <span slot="suffix">元/kwh</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">停车费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.parkingFee"
            @input="e => NumberCheck(e, 'parkingFee')"
            placeholder="请输入停车费"
          >
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">宽带费：</label>
          <el-input
            maxlength="30"
            type="Number"
            v-model="ruleForm.netFee"
            @input="e => NumberCheck(e, 'netFee')"
            placeholder="请输入宽带费"
            :disabled="netFee == '1'"
          >
            <el-select
              class="inp-sel"
              style="width: 120px !important"
              v-model="netFee"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="已包含" value="1"></el-option>
              <el-option label="其他" value="2"></el-option>
            </el-select>
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="" :span="8">
          <label for="">其他费用：</label>
          <el-input
            maxlength="30"
            v-model="ruleForm.otherFee"
            placeholder="请输入其他费用"
          >
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">税率：</label>
          <el-select
            v-model="taxRate"
            placeholder="请选择"
            @change="changeTaxRate"
          >
            <el-option label="含税" value=""></el-option>
            <el-option label="不含税" value="1"></el-option>
          </el-select>
        </el-col>
        <el-col v-if="taxRate" :span="8">
          <el-input
            style="width: 150px !important; margin-right: 8px"
            maxlength="30"
            type="number"
            v-model="ruleForm.taxRate"
            placeholder="请输入税率"
            @input="
              value => {
                if (value < 0) ruleForm.taxRate = 0;
                if (value > 100) ruleForm.taxRate = 1;
              }
            "
          >
            <span slot="suffix">%</span>
          </el-input>
          <div>
            税费=总价*税率：￥{{
              (ruleForm.totalPrice * ruleForm.taxRate) / 100 &&
              ruleForm.taxRate &&
              ruleForm.totalPrice
                ? ((ruleForm.totalPrice * ruleForm.taxRate) / 100).toFixed(2)
                : '0'
            }}
          </div>
        </el-col>
      </el-row>
      <!-- 优惠费用 -->
      <p class="title" id="id7">优惠费用</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.id === ruleForm.preferentialId ? 'active' : ''"
          v-for="item in discounts"
          :key="item.id"
          @click="setDiscounts(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 房源特色 -->
      <p class="title" id="id8">房源特色</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.state ? 'active' : ''"
          v-for="item in feature"
          :key="item.id"
          @click="item.state = !item.state"
        >
          {{ item.name }}
        </div>
        <div class="facility-item" @click="featureVisible = true">
          + 自定义房源特色
        </div>
      </div>
      <div class="facility">
        <div
          class="facility-item active"
          style="position: relative"
          v-for="(item, index) in ruleForm.customLabels"
          :key="index"
        >
          {{ item }}
          <span
            class="el-icon-circle-close"
            @click="delCustomLabel(index)"
          ></span>
        </div>
      </div>
      <!-- 房源图片 -->
      <p class="title" id="id9">
        房源图片
        <span
          >照片将展示于您发布房源的详情页面，支持JPG/PNG/JPEG格式的图片，文件不超过5MB，照片规格按4：3上传,上传的第一张图片默认为封面</span
        >
      </p>
      <div class="picture">
        <el-upload
          :action="uploadIp + '/file/common/upload/img/build'"
          :headers="headers"
          accept="image/.jpg,image./jepg,image/.png"
          list-type="picture-card"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :on-change="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <!-- 房源视频 -->
      <!-- <p class="title">
        房源视频
        <span>照片将展示于您发布房源的详情页面，支持MP4，文件不超过5MB</span>
      </p>
      <div class="picture">
        <el-upload
          class="flex-align"
          :action="uploadIp + '/file/common/upload/img/build'"
          :headers="headers"
          accept="video/.mp4"
          list-type="picture-card"
          :limit="1"
          :before-upload="beforeVideoUpload"
          :on-change="handleVideoCardPreview"
          :on-remove="handleVideoRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div> -->
      <!-- 详细介绍 -->
      <p class="title" id="id10">
        详细介绍 <span>禁止出现电话号码、邮箱地址、微信号等一切联系方式</span>
      </p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="ruleForm.details"
        maxlength="500"
        show-word-limit
      >
      </el-input>

      <div class="btn-box">
        <!-- <el-button class="cancel">取消</el-button> -->
        <el-button type="primary" class="next" @click="submit"
          >提交房源信息</el-button
        >
      </div>
    </main>
    <!-- 裁剪图片 -->
    <CropperDialog ref="cropper" @subUploadSucceed="subUploadSucceed" />
    <!-- 预览图片 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 添加自定义房源特色 -->
    <el-dialog
      title="添加自定义房源特色"
      :visible.sync="featureVisible"
      width="480px"
      :before-close="featureClose"
    >
      <div class="body">
        <el-input
          v-model="customLabel"
          maxlength="6"
          placeholder="请输入..."
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="featureVisible = false"
          >取消</el-button
        >
        <el-button class="btn" type="primary" @click="addCustomLabel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择楼盘 -->
    <el-dialog
      title="选择楼盘"
      :visible.sync="selBuildingsVisible"
      width="480px"
    >
      <div class="body">
        <el-row>
          <el-col :span="12" style="margin-right: 10px">
            <el-select
              v-model="districtId"
              clearable
              placeholder="选择区县"
              @change="
                id => {
                  getAddress(id, 3);
                  page = 1;
                  selectBuild();
                }
              "
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="streetId"
              clearable
              placeholder="选择街道/乡镇"
              @change="
                id => {
                  getAddress(id, 4);
                  page = 1;
                  selectBuild();
                }
              "
            >
              <el-option
                v-for="item in streetOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-input
          v-model="keywords"
          maxlength="30"
          placeholder="请输入..."
          @change="
            () => {
              page = 1;
              selectBuild();
            }
          "
        ></el-input>
        <div class="buildings-list" v-infinite-scroll="load">
          <ul style="overflow: auto">
            <li
              class="list-item"
              v-for="item in buildList"
              :key="item.id"
              @click="selBuildingId(item)"
              :class="selBuild.id === item.id ? 'active' : ''"
              :title="`${item.name} ${
                item.alias1 || item.alias2 || item.alias3
                  ? `（${item.alias1 ? item.alias1 : ''}${
                      item.alias1 && item.alias2 ? '、' : ''
                    }${item.alias2 ? item.alias2 : ''}${
                      item.alias2 && item.alias3 ? '、' : ''
                    }${item.alias3 ? item.alias3 : ''}）`
                  : ''
              }`"
            >
              {{ item.name }}
              {{
                item.alias1 || item.alias2 || item.alias3
                  ? `（${item.alias1 ? item.alias1 : ''}${
                      item.alias1 && item.alias2 ? '、' : ''
                    }${item.alias2 ? item.alias2 : ''}${
                      item.alias2 && item.alias3 ? '、' : ''
                    }${item.alias3 ? item.alias3 : ''}）`
                  : ''
              }}
            </li>
          </ul>
        </div>
        <div class="add-buildings">
          <div class="text">申请创建楼盘信息</div>
          <div class="btn" @click="drawer = true">创建</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="button btn-f-14"
          style="font-size: 14px"
          @click="selBuildingsVisible = false"
          >取消</el-button
        >
        <el-button
          class="btn btn-f-14"
          style="font-size: 14px"
          type="primary"
          @click="addBuildId"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择仓库、宿舍 -->
    <el-dialog
      :title="warehouseTitle"
      :visible.sync="warehouseVisible"
      width="50%"
      :before-close="warehouseClose"
    >
      <div class="body scroll">
        <PublishHouseListItem :plantList="plantList" @onListItem="onListItem" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="warehouseVisible = false"
          >新增仓库房源</el-button
        >
        <el-button class="btn" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <!-- 提交完成 -->
    <el-dialog title="提示" :visible.sync="subSuccessVisible" width="50%">
      <div class="body">
        房源已发布，需等后台管理系统审核完毕后上架。是否进入我的房源？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="subSuccessVisible = false"
          >取消</el-button
        >
        <el-button class="btn" type="primary" @click="subSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      size="40%"
      title="申请创建楼盘"
      :wrapperClosable="false"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="demo-drawer__content">
        <el-row>
          <el-col :span="24">
            <label class="required" for="">楼盘名称：</label>
            <el-input
              style="width: calc(100% - 100px)"
              v-model="buildsForm.name"
              placeholder="请输入楼盘名称"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="label" style="text-align: end" for="">别名：</label>
            <el-input
              style="width: 25%; margin-right: 10px"
              v-model="buildsForm.alias1"
              placeholder="请输入别名1"
            ></el-input>
            <el-input
              style="width: 25%; margin-right: 10px"
              v-model="buildsForm.alias2"
              placeholder="请输入别名2"
            ></el-input>
            <el-input
              style="width: 25%; margin-right: 10px"
              v-model="buildsForm.alias3"
              placeholder="请输入别名3"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="required" for="">总楼栋数：</label>
            <el-input
              type="number"
              style="width: calc(100% - 100px)"
              v-model="buildsForm.totalBuilding"
              placeholder="请输入总楼栋数"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="required" for="">位置：</label>
            <el-select
              style="width: 20%; margin-right: 10px"
              v-model="provinceId1"
              placeholder="选择省"
              @change="id => getAddress1(id, 1)"
            >
              <el-option
                v-for="item in provinceOptions1"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 20%; margin-right: 10px"
              v-model="cityId1"
              clearable
              placeholder="选择市"
              @change="id => getAddress1(id, 2)"
            >
              <el-option
                v-for="item in cityOptions1"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 20%; margin-right: 10px"
              v-model="districtId1"
              clearable
              placeholder="选择区县"
              @change="id => getAddress1(id, 3)"
            >
              <el-option
                v-for="item in areaOptions1"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 20%"
              v-model="streetId1"
              clearable
              placeholder="选择街道/乡镇"
              @change="id => getAddress1(id, 4)"
            >
              <el-option
                v-for="item in streetOptions1"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="required" for="">详细地址：</label>
            <el-input
              style="width: calc(100% - 100px)"
              v-model="buildsForm.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-col>
        </el-row>
        <div class="map">
          <b-map
            ref="map"
            v-if="mapShow"
            :adress="adress1"
            @setPoint="setPoint"
          >
            <!-- <map-mark></map-mark> -->
          </b-map>
        </div>
        <el-row>
          <el-col :span="24">
            <label style="text-align: end" class="" for="">物业公司：</label>
            <el-input
              style="width: calc(100% - 100px)"
              v-model="buildsForm.propertyCompany"
              placeholder="请输入物业公司"
            ></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="required" for="">联系电话：</label>
            <el-input
              type="number"
              style="width: calc(100% - 100px)"
              v-model="buildsForm.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-col>
        </el-row>

        <div class="picture">
          <el-upload
            :action="uploadIp + '/file/common/upload/img/build'"
            :headers="headers"
            accept="image/.jpg,image./jepg,image/.png"
            list-type="picture-card"
            :file-list="buildsFileList"
            :before-upload="beforeUpload"
            :on-change="handlePictureBuildsPreview"
            :on-remove="handleBuildsRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="demo-drawer__footer dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button class="btn" type="primary" @click="submitBuildings"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import { InfiniteScroll } from "element-ui";
import { uploadBuild } from 'services/file'
import { certificationStatus, officeLeasePublish, addOfficeBuilding, officeLeaseDetail, editOfficeLease, officeSearchQuick } from 'services/broker'
import PublishHouseListItem from 'components/house/PublishHouseListItem.vue';
import BMap from 'components/map/BMap.vue';
import MapMark from 'components/map/MapMark.vue';
import { getAreaByParent, facilitiesList, labelList } from 'services/common';
import CropperDialog from 'components/CropperDialog.vue';
export default {
  directives: {
    "infinite-scroll": InfiniteScroll,
  },
  name: 'PiblishHousingOffice',
  components: {
    PublishHouseListItem,
    BMap,
    MapMark,
    CropperDialog
  },
  data () {
    return {
      steps: [
        {
          id: 'id1',
          label: '发布信息'
        }, {
          id: 'id2',
          label: '选择楼盘'
        }, {
          id: 'id3',
          label: '基本信息'
        }, {
          id: 'id4',
          label: '出租信息'
        }, {
          id: 'id5',
          label: '配套设施'
        }, {
          id: 'id6',
          label: '价格详情'
        }, {
          id: 'id7',
          label: '优惠费用'
        }, {
          id: 'id8',
          label: '房源特色'
        }, {
          id: 'id9',
          label: '房源图片'
        }, {
          id: 'id10',
          label: '详情介绍'
        }
      ],
      step: 'id1',
      adress: { name: '深圳' },
      adress1: { name: '深圳' },
      mapShow: false,
      uploadIp: window.uploadIp,
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      priceType: true,
      customLabel: '',
      select: 1,
      airConditioningFee: '',
      waterFee: '',
      electricFee: '',
      netFee: '',
      taxRate: '',
      taxation: 0,
      ruleForm: {
        buildingId: null,
        title: '',
        totalArea: '',
        totalPrice: '',
        floor: '',
        totalFloor: '',
        minWorkstations: '',
        maxWorkstations: '',
        decoration: 1,
        orientation: 1,
        minLeaseMonth: 3,
        canLeaseTime: '',
        lookTime: 1,
        businessRegister: true,
        partition: true,
        constructionRatio: null,
        passengerElevatorNumber: null,
        floorHeight: '',
        cashPledge: '',
        propertyCost: '',
        airConditioningFee: '',
        waterFee: '',
        electricFee: '',
        parkingFee: '',
        netFee: '',
        otherFee: '',
        taxRate: '',

        facilitiesIds: [], // 配套设施
        labelIds: [], // 房源特色标签
        customLabels: [], // 自定义房源特色标签
        preferential: [], // 优惠标签
        preferentialId: [], // 优惠标签
        coverImg: '',
        images: [],
        videos: [],
        details: "",
      },
      buildsForm: {
        name: '',
        address: '',
        propertyCompany: '',
        phone: '',
        coverImg: '',
        images: '',
        lon: '',
        lat: '',
        totalBuilding: '',
        alias1: "",
        alias2: "",
        alias3: "",
      },
      buildsId: null,
      buildingName: '',
      provinceId: null,
      provinceOptions: [],
      cityId: null,
      cityOptions: [],
      districtId: null,
      areaOptions: [],
      streetId: null,
      streetOptions: [],
      provinceId1: null,
      provinceOptions1: [],
      cityId1: null,
      cityOptions1: [],
      districtId1: null,
      areaOptions1: [],
      streetId1: null,
      streetOptions1: [],
      options: [{
        value: '2',
        label: '全部'
      }, {
        value: '1',
        label: '已上线'
      }],
      options1: [{
        value: 1,
        label: '毛坯 '
      }, {
        value: 2,
        label: '简装'
      }, {
        value: 3,
        label: '精装'
      }, {
        value: 4,
        label: '豪装'
      }],
      options2: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      options3: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      options4: [{
        value: 1,
        label: '东'
      }, {
        value: 2,
        label: '南'
      }, {
        value: 3,
        label: '西'
      }, {
        value: 4,
        label: '北'
      }, {
        value: 5,
        label: '东南'
      }, {
        value: 6,
        label: '西南'
      }, {
        value: 7,
        label: '东北'
      }, {
        value: 8,
        label: '西北'
      }, {
        value: 9,
        label: '南北通透'
      }],
      options5: [{
        value: 3,
        label: '三个月'
      }, {
        value: 6,
        label: '六个月'
      }, {
        value: 12,
        label: '一年'
      }, {
        value: 24,
        label: '两年'
      }, {
        value: 36,
        label: '三年'
      }, {
        value: 60,
        label: '五年'
      }],
      options7: [{
        value: 1,
        label: '随时'
      }, {
        value: 2,
        label: '下月'
      }, {
        value: 3,
        label: '下季'
      }, {
        value: 4,
        label: '明年'
      }],
      options8: [{
        value: 1,
        label: '随时可看'
      }, {
        value: 2,
        label: '工作日可看'
      }, {
        value: 3,
        label: '周末节假日可看'
      }],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      value: "",
      switch1: true,
      rentalMethod: [{
        value: true,
        label: '分租'
      }, {
        value: false,
        label: '整租'
      }],
      checked: true,
      buildType: 2,
      facilityList: [], // 配套设施
      feature: [], // 房源特色
      discounts: [], // 优惠费用
      featureVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      warehouseTitle: '选择仓库',
      warehouseVisible: false,
      subSuccessVisible: false,
      plantList: [],
      loseVisible: false,
      fileList: [],
      buildsFileList: [],
      selBuildingsVisible: false,
      page: 1,
      keywords: '',
      selBuild: {},
      total: 0,
      buildList: [],
      drawer: false,
      direction: 'rtl',
    }
  },
  watch: {
    provinceId (val) {
      console.log(val);
      if (val) {
        this.cityId = null
        this.buildingName = ''
        this.selBuild = {}
      }
    },
    cityId (val) {
      console.log(val);
      this.districtId = null
      this.areaOptions = []
      this.buildingName = ''
      this.selBuild = {}
    },
    districtId (val) {
      console.log(val);
      this.streetId = null
      this.streetOptions = []
    },
    provinceId1 (val) {
      console.log(val);
      if (val) {
        this.cityId1 = null
      }
    },
    cityId1 (val) {
      console.log(val);
      this.districtId1 = null
      this.areaOptions1 = []
    },
    districtId1 (val) {
      console.log(val);
      this.streetId1 = null
      this.streetOptions1 = []
    },
    drawer (val) {
      if (!val) {
        this.buildsFileList = []
        this.provinceId1 = ''
        this.cityId1 = ''
        this.districtId1 = ''
        this.streetId1 = ''
        this.cityOptions1 = []
        this.areaOptions1 = []
        this.streetOptions1 = []
        this.buildsForm = {
          name: '',
          address: '',
          propertyCompany: '',
          phone: '',
          coverImg: '',
          images: '',
          lon: '',
          lat: '',
          totalBuilding: '',
          alias1: "",
          alias2: "",
          alias3: "",
        }
      }
    },
    airConditioningFee (val) {
      if (val == '1') this.ruleForm.airConditioningFee = 0
      else this.ruleForm.airConditioningFee = null
    },
    waterFee (val) {
      if (val == '1') this.ruleForm.waterFee = 0
      else this.ruleForm.waterFee = null
    },
    electricFee (val) {
      if (val == '1') this.ruleForm.electricFee = 0
      else this.ruleForm.electricFee = null
    },
    netFee (val) {
      if (val == '1') this.ruleForm.netFee = 0
      else this.ruleForm.netFee = null
    },
  },
  beforeRouteEnter (to, from, next) {
    // ...
    console.log(to, from);
    next()
    if (from.path !== '/') {
      // window.location.reload();
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    console.log(to, from);
    let isBoss = confirm("跳转其他页面将不会保存发布房源输入的内容, 是否继续?");
    if (isBoss) {
      next(true)
    } else {
      this.$eventBus.$emit('eventDefActive')
    }
  },
  beforeDestroy () {
    console.log('关闭');
    // window.removeEventListener('beforeunload');
  },
  async mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e), false)
    // 判断认证状态
    const res = await certificationStatus()
    console.log('判断认证状态', res);
    if (res.data.status === 200)
      if (res.data.data.status === 1) this.$router.push('/user')
    // 获取标签
    await this.getLabel()
    await this.getAddress()
    await this.getAddress1()
    // 我的房源进来
    if (this.$route.query?.id) {
      this.getDetails(this.$route.query.id)
    } else {
      this.mapShow = true
      this.adress = {
        name: '深圳',
        zoom: 12
      }
    }
  },
  methods: {
    addBuildId () {
      this.ruleForm.buildingId = this.selBuild.id
      this.buildingName = this.selBuild.name
      this.selBuildingsVisible = false
      this.$refs.mapMark.addMark(this.selBuild.lon, this.selBuild.lat, 16)
    },
    selBuildingId (item) {
      this.selBuild = item
    },
    load () {
      if (this.page < this.totalPage + 1) {
        this.page++
      }
      if (this.page < this.totalPage + 1) {
        let params = {
          cityId: this.cityId,
          keywords: this.keywords,
          districtId: this.districtId,
          streetId: this.streetId,
          page: this.page,
        }
        officeSearchQuick(params).then(res => {
          if (res.data.status === 200) {
            this.buildList = this.buildList.concat(res.data.data)
            this.pageSize = res.data.pageSize
            this.total = res.data.total
            this.totalPage = res.data.totalPage
          }
        })
      }
    },

    selectBuild () {
      if (!this.provinceId) {
        return this.$message.warning('请选择省')
      } else if (!this.cityId) {
        return this.$message.warning('请选择市')
      } else {
        this.selBuildingsVisible = true
        let params = {
          cityId: this.cityId,
          keywords: this.keywords,
          districtId: this.districtId,
          streetId: this.streetId,
          page: this.page,
        }
        officeSearchQuick(params).then(res => {
          if (res.data.status === 200) {
            this.buildList = res.data.data
            this.pageSize = res.data.pageSize
            this.total = res.data.total
            this.totalPage = res.data.totalPage
          }
        })
      }
    },
    handleClose () {
      this.$refs.map.clearMark()
      this.drawer = false
    },
    submitBuildings () {
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      // let { lon, lat } = this.ruleForm
      // this.$refs.mapMark.addMark(lon, lat)
      if (!this.buildsForm.name) {
        return this.$message.warning('请输入楼盘名称')
      } else if (!this.buildsForm.address) {
        return this.$message.warning('请输入楼盘地址')
      } else if (!this.buildsForm.phone) {
        return this.$message.warning('请输入联系人手机号')
      } else if (!reg.test(this.buildsForm.phone)) {
        this.$message.warning('请正确输入手机号')
      } else if (!this.buildsForm.totalBuilding) {
        return this.$message.warning('请输入总楼栋数')
      } else if (!this.provinceId1) {
        return this.$message.warning('请选择省')
      } else if (!this.cityId1) {
        return this.$message.warning('请选择市')
      } else if (!this.districtId1) {
        return this.$message.warning('请选择区县')
      } else if (!this.streetId1) {
        return this.$message.warning('请选择街道、乡镇')
      } else if (!this.buildsForm.lon && !this.buildsForm.lat) {
        return this.$message.warning('请在地图上标明地址')
      } else {
        let params = {
          ...this.buildsForm,
          provinceId: this.provinceId1,
          cityId: this.cityId1,
          districtId: this.districtId1,
          streetId: this.streetId1,
          coverImg: this.buildsForm.images[0] || ''
        }
        addOfficeBuilding(params).then(res => {
          if (res.data.status === 200) {
            this.$refs.map.clearMark()
            this.drawer = false
            this.buildsId = res.data.data
          }
        })
      }
    },
    async getDetails (id) {
      await officeLeaseDetail({ id }).then(async res => {
        if (res.data.status === 200) {
          this.mapShow = true

          let {
            buildingId,
            buildingName,
            provinceId,
            cityId,
            districtId,
            streetId,
            lon,
            lat,
            title,
            totalArea,
            totalPrice,
            floor,
            totalFloor,
            minWorkstations,
            maxWorkstations,
            decoration,
            orientation,
            minLeaseMonth,
            canLeaseTime,
            lookTime,
            businessRegister,
            partition,
            constructionRatio,
            passengerElevatorNumber,
            floorHeight,
            cashPledge,
            details,
            propertyCost,
            airConditioningFee,
            waterFee,
            electricFee,
            parkingFee,
            netFee,
            otherFee,
            taxRate,
            facilitiesIds, // 配套设施
            labelList,
            preferential, // 优惠标签
            preferentialId, // 优惠标签
            coverImg,
            images,
            facilitiesList
          } = res.data.data
          if (airConditioningFee == '0') this.airConditioningFee = '1'
          if (airConditioningFee != '0' && airConditioningFee) this.airConditioningFee = '2'
          if (waterFee == '0') this.waterFee = '1'
          if (waterFee != '0' && waterFee) this.waterFee = '2'
          if (electricFee == '0') this.electricFee = '1'
          if (electricFee != '0' && electricFee) this.electricFee = '2'
          if (netFee == '0') this.netFee = '1'
          if (netFee != '0' && netFee) this.netFee = '2'
          this.$nextTick(async () => {
            let labelIds = []
            let customLabels = []
            if (labelList) {
              labelIds = labelList.filter(item => item.id).map(item => item.id)
              customLabels = labelList.filter(item => item.customLabel).map(item => item.customLabel)
              console.log('this.feature', this.feature);
              labelIds.forEach(id => {
                this.feature.forEach(item => {
                  if (id === item.id) item.state = true
                })
              })
            }
            this.ruleForm = {
              buildingId,
              provinceId,
              cityId,
              districtId,
              streetId,
              lon,
              lat,
              title,
              totalArea,
              totalPrice,
              floor,
              totalFloor,
              minWorkstations,
              maxWorkstations,
              decoration,
              orientation,
              minLeaseMonth,
              canLeaseTime: canLeaseTime.toString(),
              lookTime,
              businessRegister,
              partition,
              constructionRatio,
              passengerElevatorNumber,
              floorHeight,
              cashPledge,
              details,
              propertyCost,
              airConditioningFee,
              waterFee,
              electricFee,
              parkingFee,
              netFee,
              otherFee,
              taxRate: taxRate * 100,
              facilitiesIds, // 配套设施
              labelList,
              customLabels,
              preferential, // 优惠标签
              preferentialId, // 优惠标签
              coverImg,
              images,
              details,
              facilitiesList
            }
            if (facilitiesList)
              this.facilityList = facilitiesList.map(item => {
                return {
                  ...item,
                  state: item.enable
                }
              })
            this.provinceId = provinceId
            await this.getPoint(provinceId, 1)
            this.cityId = cityId
            await this.getPoint(cityId, 2)
            this.districtId = districtId
            await this.getPoint(districtId, 3)
            this.streetId = streetId
            this.$refs.mapMark.addMark(lon, lat, 16)
            if (images) this.fileList = images.map(item => {
              return {
                url: window.los + item + '?' + Date.now(),
                response: {
                  data: [{ fileUrl: item }],
                  status: 200
                }
              }
            })
            this.buildingName = buildingName
            this.selBuild = {
              name: buildingName,
              id: buildingId,
            }
            if (taxRate) this.taxRate = '1'
          })
        }
      })
    },
    getStep (item) {
      this.step = item.id
      const element = document.getElementById(item.id)
      const main = document.getElementById('main')
      main.scrollTo({
        top: element.offsetTop - 120,
        behavior: 'smooth'
      })
    },
    beforeunloadHandler (e) {
      console.log('----', e);
      this._beforeUnload_time = new Date().getTime()
      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    },
    unloadHandler (e) {
      console.log('----', e);
      this._beforeUnload_time = new Date().getTime()
    },
    // 限制输入框
    NumberCheck (num, val) {
      let str = val === 'totalArea' ? Number(Number(num).toFixed(0)) : Number(Number(num).toFixed(2));
      if (str < 0) str = str * -1;
      if (str.toString().length > 10) str = str.toString().substring(0, 10);
      this.ruleForm[val] = str
    },
    changeWorkstations (value, key) {
      let str = Number(Number(value).toFixed(0));
      if (str < 0) str = str * -1;
      if (str.toString().length > 10) str = str.toString().substring(0, 10);
      this.ruleForm[key] = str
    },
    blurMaxWorkstations () {
      console.log(this.ruleForm.maxWorkstations < this.ruleForm.minWorkstations);
      if (this.ruleForm.maxWorkstations && this.ruleForm.minWorkstations && this.ruleForm.maxWorkstations < this.ruleForm.minWorkstations) {
        const maxWorkstations = this.ruleForm.minWorkstations
        this.ruleForm.minWorkstations = this.ruleForm.maxWorkstations
        this.ruleForm.maxWorkstations = maxWorkstations
      }
    },
    setPriceType () {
      this.priceType = !this.priceType
      // if (!this.priceType) {
      //     this.ruleForm.maxPrice = this.ruleForm.price
      //   } else {
      //   this.ruleForm.maxPrice = null
      // }
    },
    subSuccess () {
      this.$router.push('/myHousing')
    },
    getLabel () {
      let obj = {
        type: this.buildType
      }
      // 配套设施
      facilitiesList(obj).then(res => {
        if (res.data.status === 200) {
          this.facilityList = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
      // 房源特色
      labelList({ buildType: this.buildType, category: 1 }).then(res => {
        if (res.data.status === 200) {
          this.feature = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
      // 优惠标签
      labelList({ buildType: this.buildType, category: 2 }).then(res => {
        if (res.data.status === 200) {
          this.discounts = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
    },
    // 获取省市区街道
    getAddress (id, treeNode) {
      getAreaByParent({ parentId: id }).then(async res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions = res.data.data;
          }
          if (treeNode === 1 && id) {
            this.cityOptions = res.data.data
            this.province = await this.provinceOptions.filter(item => item.id === id)[0].name
            let obj = this.provinceOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 8 }
          }
          if (treeNode === 2 && id) {
            this.areaOptions = res.data.data
            this.city = await this.cityOptions.filter(item => item.id === id)[0].name
            let obj = this.cityOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 12, name: this.province + this.city }
          }
          if (treeNode === 3 && id) {
            this.streetOptions = res.data.data
            this.area = await this.areaOptions.filter(item => item.id === id)[0].name
            let obj = this.areaOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 14, name: this.province + this.city + this.area }
          }
          if (treeNode === 4 && id) {
            this.street = this.streetOptions.filter(item => item.id === id)[0].name
            let obj = this.streetOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 16, name: this.province + this.city + this.area + this.street }
          }
        }
      })
    },
    // 获取省市区街道
    getAddress1 (id, treeNode) {
      getAreaByParent({ parentId: id }).then(async res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions1 = res.data.data;
          }
          if (treeNode === 1 && id) {
            this.cityOptions1 = res.data.data
            this.province1 = await this.provinceOptions1.filter(item => item.id === id)[0].name
            let obj = this.provinceOptions1.filter(item => item.id === id)[0]
            this.adress1 = { ...obj, zoom: 8 }
          }
          if (treeNode === 2 && id) {
            this.areaOptions1 = res.data.data
            this.city1 = await this.cityOptions1.filter(item => item.id === id)[0].name
            let obj = this.cityOptions1.filter(item => item.id === id)[0]
            this.adress1 = { ...obj, zoom: 12, name: this.province1 + this.city1 }
          }
          if (treeNode === 3 && id) {
            this.streetOptions1 = res.data.data
            this.area1 = await this.areaOptions1.filter(item => item.id === id)[0].name
            let obj = this.areaOptions1.filter(item => item.id === id)[0]
            this.adress1 = { ...obj, zoom: 14, name: this.province1 + this.city1 + this.area1 }
          }
          if (treeNode === 4 && id) {
            this.street1 = this.streetOptions1.filter(item => item.id === id)[0].name
            let obj = this.streetOptions1.filter(item => item.id === id)[0]
            this.adress1 = { ...obj, zoom: 16, name: this.province1 + this.city1 + this.area1 + this.street1 }
          }
        }
      })
    },
    getPoint (id, treeNode) {
      getAreaByParent({ parentId: id }).then(async res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions = res.data.data;
          }
          if (treeNode === 1) {
            this.cityOptions = res.data.data
            this.province = await this.provinceOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 2) {
            this.areaOptions = res.data.data
            this.city = await this.cityOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 3) {
            this.streetOptions = res.data.data
            this.area = await this.areaOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 4) {
            this.street = this.streetOptions.filter(item => item.id === id)[0].name
          }
        }
      })
    },
    // 获取地图点位置坐标
    setPoint (arr) {
      console.log('point', arr);
      this.buildsForm.lon = arr[0]
      this.buildsForm.lat = arr[1]
    },
    featureClose () {
      this.featureVisible = false
    },
    warehouseClose () {
      this.warehouseVisible = false
    },
    async beforeUpload (file) {
      console.log('---------', file);
      const isLt2M = file.size / 1024 / 1024 < 5
      const img = new Image()
      const _this = this
      return new Promise((resolve, reject) => {
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          reject(false)
          return
        }
        img.src = URL.createObjectURL(file)
        img.onload = function () {
          const width = img.width
          const height = img.height
          const ratio = width / height
          _this.$refs.cropper.showCropper = true
          _this.$refs.cropper.getCropperImg(file)
          reject(false)
        }
      })
    },
    async beforeVideoUpload (flile) { },
    handlePictureCardPreview (file, fileList) {
      console.log(file, fileList, this.fileList);
      if (file.response) {
        console.log(file, fileList.filter(item => item?.response.status === 200));
        this.ruleForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
          return item.response.data[0].fileUrl
        })
        this.ruleForm.coverImg = this.ruleForm.images[0]
      }
    },
    // 楼盘图片上传
    handlePictureBuildsPreview (file, fileList) {
      if (file.response) {
        this.buildsForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
          return item.response.data[0].fileUrl
        })
        this.buildsForm.coverImg = this.buildsForm.images[0]
      }
    },
    subUploadSucceed (formData) {
      uploadBuild(formData).then(res => {
        let ruleForm = 'ruleForm'
        let fileList = 'fileList'
        if (this.drawer) {
          ruleForm = 'buildsForm'
          fileList = 'buildsFileList'
        }
        this[fileList].push({
          url: window.los + res.data.data[0].fileUrl + '?' + Date.now(),
          response: {
            data: [{ fileUrl: res.data.data[0].fileUrl }],
            status: 200
          }
        })
        this[ruleForm].images = this[fileList].filter(item => item?.response.status === 200).map(item => {
          return item.response.data[0].fileUrl
        })
        this[ruleForm].coverImg = this[ruleForm].images[0]
        this.$refs.cropper.showCropper = false
      })
    },
    handleVideoCardPreview (file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videos = fileList
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
      this.ruleForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
        return item.response.data[0].fileUrl
      })
      console.log(file, fileList, this.ruleForm.images);
      if (!this.ruleForm.images.length) this.ruleForm.coverImg = ''
    },
    handleBuildsRemove (file, fileList) {
      console.log(file, fileList);
      this.buildsFileList = fileList
      this.buildsForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
        return item.response.data[0].fileUrl
      })
      if (!this.buildsForm.images.length) this.buildsForm.coverImg = ''
    },
    handleVideoRemove (file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videos = fileList
    },
    addWareHouse (str) {
      this.warehouseTitle = str;
      this.warehouseVisible = true
    },
    addCustomLabel () {
      this.ruleForm.customLabels.push(this.customLabel)
      this.customLabel = ''
      this.featureVisible = false
    },
    delCustomLabel (index) {
      this.ruleForm.customLabels = this.ruleForm.customLabels.filter((item, i) => index !== i)
    },
    onListItem (item) {
      this.plantList.forEach(i => {
        if (item.id === i.id) i.state = !i.state
      })
    },
    setDiscounts (item) {
      if (this.ruleForm.preferentialId === item.id) {
        this.ruleForm.preferentialId = null
      } else {
        this.ruleForm.preferential = item.name
        this.ruleForm.preferentialId = item.id
      }
    },
    submit () {
      if (!this.ruleForm.title) {
        return this.$message.warning('请输入房源名称')
      } else if (!this.ruleForm.totalArea) {
        return this.$message.warning('请输入房源总面积')
      } else if (!this.ruleForm.totalPrice) {
        return this.$message.warning('请输入房源总租金')
      } else if (!this.ruleForm.buildingId) {
        return this.$message.warning('请选择楼盘')
      } else if (!this.ruleForm.floor) {
        return this.$message.warning('请输入楼层')
      } else if (!this.ruleForm.totalFloor) {
        return this.$message.warning('请输入总层数')
      } else if (!this.ruleForm.minWorkstations && !this.ruleForm.maxWorkstations && this.ruleForm.maxWorkstations >= this.ruleForm.minWorkstations) {
        return this.$message.warning('请输入最小工位数和最大工位数且最小工位数不能大于最大工位数')
      } else if (!this.ruleForm.decoration) {
        return this.$message.warning('请选择装修情况')
      } else if (!this.ruleForm.orientation) {
        return this.$message.warning('请选择朝向')
      } else if (!this.ruleForm.minLeaseMonth) {
        return this.$message.warning('请选择起租时间')
      } else if (!this.ruleForm.canLeaseTime) {
        return this.$message.warning('请选择可租时间')
      } else if (!this.ruleForm.lookTime) {
        return this.$message.warning('请选择看房时间')
        // } else if (!this.ruleForm.constructionRatio) {
        //   return this.$message.warning('请输入得房率')
      } else if (!this.ruleForm.passengerElevatorNumber) {
        return this.$message.warning('请输入客梯数量')
      } else if (!this.ruleForm.floorHeight) {
        return this.$message.warning('请输入层高')
      } else if (!this.ruleForm.propertyCost) {
        return this.$message.warning('请输入物业费')
      } else {
        let params = {
          ...this.ruleForm,
          facilitiesIds: this.facilityList.filter(item => item.state).map(item => item.id),
          labelIds: this.feature.filter(item => item.state).map(item => item.id),
          coverImg: this.ruleForm.images[0] || ''
        }
        if (!this.taxRate) delete params.taxRate
        if (this.ruleForm.taxRate) params.taxRate = this.ruleForm.taxRate / 100
        let req
        if (this.$route.query?.id) {
          req = editOfficeLease
          params.id = this.$route.query?.id
        } else {
          req = officeLeasePublish
        }
        req(params).then(res => {
          console.log(res);
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
            this.subSuccessVisible = true
          }
        })
      }

    },
    changeTaxRate (val) {
      if (val) {

      }
    }
  }
};
</script>

<style lang="less" scoped>
.publish-housing-office {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 16px 0;
  overflow: hidden;
  .steps {
    width: 160px;
    height: 100%;
    padding: 24px 32px;
    .step-item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      position: relative;
      cursor: pointer;
      &:nth-child(1) {
        margin-bottom: 8px;
        .step-icon {
          width: 10px;
          height: 10px;
          margin-right: -1px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          border-radius: 50%;
          border: 2px solid rgba(219, 217, 215, 1);
          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 2.5px;
            width: 1px;
            height: 25px;
            background: rgba(230, 230, 230, 1);
          }
        }
      }
      &:nth-child(11) {
        margin-bottom: 8px;
        .step-icon::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 2.5px;
          width: 1px;
          height: 25px;
          background: rgba(230, 230, 230, 1);
        }
      }
      &:nth-child(12) {
        .step-icon {
          width: 10px;
          height: 10px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          border-radius: 50%;
          margin-right: -1px;
          border: 2px solid rgba(219, 217, 215, 1);
          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 2.5px;
            width: 1px;
            height: 0px;
            background: rgba(230, 230, 230, 1);
          }
        }
      }
      .step-text {
        width: 64px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(153, 153, 153, 1);
        &.active {
          color: rgba(236, 102, 0, 1);
        }
      }
      .step-icon {
        width: 8px;
        height: 8px;
        opacity: 1;
        background: rgba(219, 217, 215, 1);
        border-radius: 50%;
        border: 1px solid rgba(219, 217, 215, 1);
        margin-left: 24px;
        position: relative;
        &.active {
          background-image: url(../../assets/steps.png);
          width: 25px;
          height: 25px;
          background-size: 100% 100%;
          background-color: #fff;
          border-radius: 0;
          border: 0;
          position: absolute;
          right: -9px;
          &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: 11.5px;
            width: 1px;
            height: 60px;
            background: rgba(230, 230, 230, 1);
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 2.5px;
          width: 1px;
          height: 60px;
          background: rgba(230, 230, 230, 1);
        }
      }
    }
  }
  main {
    width: calc(100% - 160px);
    height: 100%;
    padding: 0px 24px;
    overflow: auto;
    label {
      width: 100px;
      text-align: right;
      justify-content: end;
    }
    .sel-buildings {
      width: 200px;
      height: 38px;
      line-height: 38px;
      padding-left: 15px;
      border-radius: 4px;
      border: 1px solid #dbd9d7;
      color: #999;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
    margin-top: 16px;
    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .map {
    // width: calc(83.3% + 60px);
    height: 280px;
    margin-bottom: 8px;
    // background: url(https://img.js.design/assets/img/651fa99e992fbaa8dc1d7f23.png#8e037f32d622ef2bbd363889c8001f51);
    background-size: 100% 100%;
    border: 1px solid rgba(245, 243, 240, 1);
  }
  .switch {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    &-txt {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(51, 51, 51, 1);
      margin-right: 8px;
      &:last-child {
        color: rgba(153, 153, 153, 1);
        margin-left: 12px;
      }
    }
  }
  .facility {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &-item {
      width: 200px;
      height: 44px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(245, 245, 245, 1);
      text-align: center;
      line-height: 44px;
      margin-bottom: 16px;
      margin-right: 20px;
      cursor: pointer;
      span {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
      }
      &.active {
        background: rgba(255, 237, 224, 1);
        border: 0.6px solid rgba(236, 102, 0, 1);
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .picture {
    padding: 18px 20px;
    border-radius: 4px;
    background: rgba(245, 245, 245, 1);

    border: 1px solid rgba(245, 243, 240, 1);
  }

  .btn-box {
    border-top: 1px solid rgba(238, 238, 238, 1);
    margin-top: 28px;
    text-align: center;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel {
      width: 160px;
      height: 60px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(255, 237, 224, 1);
      border: 1px solid rgba(255, 237, 224, 1);
      font-size: 20px;
      font-weight: 600;
      color: rgba(236, 102, 0, 1);
    }
    .next {
      width: 160px;
      height: 60px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(236, 102, 0, 1);
      font-size: 20px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);

      border: 1px solid rgba(236, 102, 0, 1);
    }
  }
}
.demo-drawer__content {
  padding: 20px 40px;
  label {
    width: 100px;
  }
  /deep/.el-input__inner {
  }
}
.demo-drawer__footer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    .buildings-list {
      max-height: 200px;
      overflow: auto;
      margin: 16px 0;
      ul li.list-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
        &.active {
          background: rgba(255, 243, 235, 1);
          color: rgba(236, 102, 0, 1);
        }
      }
    }
    .add-buildings {
      display: flex;
      align-items: center;
      margin-top: 16px;
      .text {
        padding-left: 16px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(153, 153, 153, 1);
      }
      .btn {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(236, 102, 0, 1);
        padding-left: 4px;
        cursor: pointer;
      }
    }
  }
  .scroll {
    height: 50vh;
    overflow: auto;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

/deep/ .el-button {
  height: 36px;
  display: flex;
  align-items: center;
  // background: #ff9a4d !important;
  // border: 1px solid #ff9a4d !important;
  // color: #fff;
}
/deep/ .el-row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
/deep/ .el-col-5 {
  margin-right: 20px;
}
/deep/.el-input__inner {
  height: 38px;
  line-height: 38px;
  opacity: 1;
  border-radius: 4px;
  border: 1px solid rgba(219, 217, 215, 1);
}
/deep/ .el-input__suffix {
  line-height: 44px;
  right: 10px;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 165px;
  height: 124px;
  opacity: 1;
  border-radius: 4px;
  border: 0;
}
/deep/ .el-upload--picture-card {
  width: 165px;
  height: 124px;
  line-height: 124px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px dashed rgba(238, 236, 233, 1);
  margin-bottom: 8px;
  i {
    color: rgba(236, 102, 0, 1);
    font-size: 16px;
  }
}

/deep/ .el-textarea__inner {
  // width: calc(83.3% + 60px);
  white-space: pre-wrap;
  height: 160px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(245, 245, 245, 1);
}
/deep/ .el-textarea .el-input__count {
  background: rgba(245, 245, 245, 1);
}
/deep/ .el-input__suffix-inner {
  pointer-events: all;
  height: 100%;
  display: flex;
  align-items: center;
}
/deep/ .el-popper {
  padding: 0;
}
/deep/ .el-col-24 {
  display: flex;
  align-items: center;
}
/deep/ .el-col-8 {
  display: flex;
  align-items: center;
  .el-select {
    width: calc(100% - 140px) !important;
    .el-input {
      width: 100% !important;
    }
  }
  .el-input {
    width: calc(100% - 140px) !important;
  }
}
.inp-sel {
  /deep/ .el-input .el-input__inner {
    border: 0px !important;
    cursor: pointer !important;
  }
}

/deep/ .el-input-group__prepend {
  background-color: #fff;
  overflow: hidden;
}
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
}
</style>