<template>
  <div class="my-housing">
    <header>
      <div class="left">
        <div class="title">我的房源</div>
        <div class="btn" @click="toPush('/publishHousing')">发布房源</div>
      </div>
      <div class="search">
        <el-input
          class="inp"
          placeholder="请输入关键字搜索"
          v-model="keywords"
          @change="getList"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-select
          class="sel"
          v-model="status"
          placeholder="请选择"
          @change="getList"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </header>
    <div class="tab">
      <div
        class="tab-item"
        v-for="item in tabList"
        :key="item.id"
        :class="tab === item.id ? 'active' : ''"
        @click="setTab(item)"
      >
        {{ item.text }}
      </div>
    </div>
    <main class="main">
      <div
        class="main-list"
        :class="!plantList.length ? 'empty' : ''"
        v-loading="Loading"
      >
        <HouseListItem
          :plantList="plantList"
          @loseMessage="loseMessage"
          @setStatus="setStatus"
          :tab="tab"
        />
      </div>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 评价弹窗 -->
    <el-dialog
      title="审核失败原因"
      :visible.sync="loseVisible"
      width="480px"
      :before-close="loseClose"
    >
      <div class="body">
        <p v-html="reason.replace(/\n|\r\n/g, '<br>').replace(/ /g, '  ')"></p>
      </div>
    </el-dialog>

    <!-- 成交房源 -->
    <el-dialog title="提示" :visible.sync="knockdownVisible" width="480px">
      <div class="body">
        <p class="del">{{ content }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="knockdownVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="changeHouseState"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 房源转录提示 -->
    <el-dialog
      title="房源转录提示"
      :visible.sync="promptsVisible"
      width="480px"
    >
      <div class="body">
        <p class="del">
          转录成功后，该房源信息会转移到其他用户的账号下，请谨慎操作。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="promptsVisible = false">放弃</el-button>
        <el-button class="btn" type="primary" @click="houseTranscription"
          >房源转录</el-button
        >
      </span>
    </el-dialog>

    <!-- 房源转录提示 -->
    <el-dialog
      title="房源转录"
      :visible.sync="transcriptionVisible"
      width="480px"
    >
      <div class="body">
        <el-input
          placeholder="请输入手机号"
          v-model="phone"
          class="input-with-select"
        >
          <div class="append-search" slot="append" @click="searchPhone">
            查询
          </div>
        </el-input>
        <p class="tit">接收房源的用户信息：</p>
        <div class="list" v-if="JSON.stringify(searchPhoneUser) !== '{}'">
          <div class="list-item">
            <img :src="los + searchPhoneUser.headImg" alt="" />
            <div class="name-phone">
              <div class="name">{{ searchPhoneUser.name }}</div>
              <div class="phone">{{ searchPhoneUser.phone }}</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transcriptionVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="houseTranscriptionSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import HouseListItem from 'components/house/HouseListItem.vue';
import {
  buildPage,
  officeLeasePage,
  changeBuildStatus,
  changeOfficeStatus,
  buildingFactoryReviewRecord,
  getOfficeReviewRecord,
  getByPhone,
  propertyTransfer,
  officePropertyTransfer,
} from 'services/broker'
const los = window.los
export default {
  name: 'MyHousing',
  components: {
    HouseListItem,
  },
  data () {
    return {
      los,
      keywords: '',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '已上线'
      }, {
        value: 3,
        label: '已下线'
      }, {
        value: 5,
        label: '已成交'
      }, {
        value: 6,
        label: '审核失败'
      }],
      status: '',
      tabList: [
        { id: 1, text: '厂房出租' },
        // { id: 2, text: '厂房出售' },
        { id: 3, text: '写字楼出租' },
        // { id: 4, text: '写字楼出售' },
        // { id: 5, text: '仓库出租' },
        // { id: 6, text: '仓库出售' },
        // { id: 7, text: '商铺出租' },
        // { id: 8, text: '商铺出售' },
        // { id: 9, text: '土地出租' },
        // { id: 10, text: '土地出售' },
      ],
      tab: 1,
      navIndex: 1,
      pageSize: 5,
      total: 0,
      page: 1,
      plantList: [],
      Loading: false,
      loseVisible: false,
      knockdownVisible: false,
      promptsVisible: false,
      phone: '',
      toBrokerId: '',
      searchPhoneUser: {},
      transcriptionVisible: false,
      selItem: {},
      state: null,
      content: '',
      reason: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    searchPhone () {
      getByPhone({ phone: this.phone }).then(res => {
        if (res.data.status === 200) {
          this.searchPhoneUser = res.data.data
        }
      })
    },
    setTab (item) {
      this.tab = item.id
      this.page = 1
      this.keywords = ''
      this.status = ''
      this.getList()
    },
    getList () {
      let params = {
        page: this.page,
        keywords: this.keywords,
        status: this.status
      }
      this.Loading = true
      let req = ''
      if (this.tab === 1) { req = buildPage }
      if (this.tab === 3) { req = officeLeasePage }
      req(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data
          this.total = res.data.total
        }
        this.Loading = false
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    toPush (path) {
      this.$router.push({
        path
      })
    },
    loseClose () {
      this.loseVisible = false
    },
    loseMessage (item) {
      let req
      if (this.tab === 1) req = buildingFactoryReviewRecord
      if (this.tab === 3) req = getOfficeReviewRecord
      // type 房源类型 1.出租 2.出售
      req({ id: item.id, type: 1 }).then(res => {
        if (res.data.status === 200) {
          this.loseVisible = true;
          this.reason = res.data.data.reason
        }
      })
    },
    setStatus (item, status) {
      this.selItem = item
      this.state = status
      this.toBrokerId = ''
      this.phone = ''
      this.searchPhoneUser = {}
      if (status) {
        this.knockdownVisible = true
        if (status === 2) this.content = '再次发布将重新上架该房源'
        if (status === 3) this.content = '确认下架该房源吗？'
        if (status === 4) this.content = '请确认删除该房源？'
        if (status === 5) this.content = '请确认成交？'
      } else {
        this.promptsVisible = true
      }
    },
    changeHouseState () {
      let params = {
        id: this.selItem.id,
        status: this.state
      }
      let req
      if (this.tab === 1) req = changeBuildStatus
      if (this.tab === 3) req = changeOfficeStatus
      req(params).then(res => {
        if (res.data.status === 200) {
          this.knockdownVisible = false
          this.page = 1
          this.getList()
          return this.$message({
            type: 'success',
            message: res.data.msg
          })
        }
      })
    },
    // 房源转录
    houseTranscription () {
      this.promptsVisible = false;
      this.transcriptionVisible = true
    },
    houseTranscriptionSubmit () {
      if (JSON.stringify(this.searchPhoneUser) !== '{}') {
        this.transcriptionVisible = false
        this.phone = ''
        this.toBrokerId = this.searchPhoneUser.brokerId

        let params = {
          id: this.selItem.id,
          toBrokerId: this.toBrokerId
        }
        let req
        if (this.tab === 1) req = propertyTransfer
        if (this.tab === 3) req = officePropertyTransfer
        req(params).then(res => {
          if (res.data.status === 200) {
            this.transcriptionVisible = false
            this.page = 1
            this.getList()
            this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      } else {
        this.$message.warning('请先确定转录账号')
      }

    }
  }
};
</script>

<style lang="less" scoped>
.my-housing {
  height: 100%;
  padding: 16px 24px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        color: rgba(51, 51, 51, 1);
        margin-right: 16px;
      }
      .btn {
        width: 100px;
        height: 38px;
        border-radius: 4px;
        background: rgba(255, 230, 212, 1);
        border: 1px solid rgba(255, 230, 212, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: rgba(236, 102, 0, 1);
        cursor: pointer;
      }
    }

    .search {
      display: flex;
      align-items: center;
      .inp {
        margin-left: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
      .sel {
        margin-left: 16px;
        width: 270px;
        height: 38px;
        /deep/ .el-input__inner {
          width: 270px;
          height: 38px;
          opacity: 1;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid #dbd9d7;
        }
      }
    }
  }
  .tab {
    height: 40px;
    background: rgba(246, 245, 244, 1);
    border: 1px solid rgba(246, 245, 244, 1);
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 16px;
    .tab-item {
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 1);
        color: rgba(236, 102, 0, 1);
        border-top: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
  .main {
    height: calc(100% - 100px);
    &-list {
      overflow: auto;
      height: calc(100% - 60px);
      &.empty {
        height: calc(100% - 60px);
        background: url(../assets/table-empty.png);
        background-size: 200px 200px;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    .tit {
      padding: 16px 0 12px;
      font-size: 16px;
      line-height: 26px;
      color: rgba(153, 153, 153, 1);
    }
    .list {
      overflow: auto;
      max-height: 200px;
      &-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          margin-right: 24px;
          border-radius: 4px;
          border: 1px solid rgba(238, 238, 238, 1);
        }
        .name-phone {
          .name {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            color: rgba(51, 51, 51, 1);
          }
          .phone {
            font-size: 16px;
            font-weight: 400;
            line-height: 23.17px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
}

.input-with-select {
  /deep/ .el-input__inner {
    border-radius: 4px 0px, 0px, 4px;
    background: rgba(245, 245, 245, 1);
    border: 0.6px solid rgba(245, 245, 245, 1);
  }
  /deep/ .el-input-group__append {
    background: rgba(236, 102, 0, 1);
    border: 1px solid rgba(236, 102, 0, 1);
    cursor: pointer;
    .append-search {
      background: rgba(236, 102, 0, 1);
      color: #fff;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>