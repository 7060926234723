<template>
  <div class="publish-housing">
    <div class="steps">
      <div class="step-item">
        <div class="step-text"></div>
        <div class="step-icon"></div>
      </div>
      <div
        class="step-item"
        v-for="item in steps"
        :key="item.id"
        @click="getStep(item)"
      >
        <div class="step-text" :class="step === item.id ? 'active' : ''">
          {{ item.label }}
        </div>
        <div class="step-icon" :class="step === item.id ? 'active' : ''"></div>
      </div>
      <div class="step-item">
        <div class="step-text"></div>
        <div class="step-icon"></div>
      </div>
    </div>
    <main id="main">
      <!-- 发布信息 -->
      <p class="title" id="id1">发布信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">标题：</label>
          <el-input
            maxlength="30"
            minlength="4"
            v-model="ruleForm.title"
            placeholder="请输入房源名称"
          ></el-input>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">总面积：</label>
          <el-input
            type="Number"
            v-model="ruleForm.totalArea"
            @input="e => NumberCheck(e, 'totalArea')"
            placeholder="请输入总面积"
          >
            <span slot="suffix">㎡</span>
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required">租金：</label>
          <el-input
            v-if="priceType"
            type="Number"
            v-model="ruleForm.price"
            @input="e => NumberCheck(e, 'price')"
            placeholder="租金"
          >
            <span slot="suffix">元/㎡/月</span>
          </el-input>
          <div class="flex-align" v-else>
            <el-input
              type="Number"
              v-model="ruleForm.price"
              @input="e => NumberCheck(e, 'price')"
              placeholder="最小租金"
              @blur="blurMaxPrice"
            >
              <span slot="suffix">元/㎡/月</span>
            </el-input>
            -
            <el-input
              type="Number"
              v-model="ruleForm.maxPrice"
              @input="e => NumberCheck(e, 'maxPrice')"
              placeholder="最大租金"
              @blur="blurMaxPrice"
            >
              <span slot="suffix">元/㎡/月</span>
            </el-input>
          </div>

          <el-button
            class="refresh"
            type="primary"
            icon="el-icon-refresh"
            style="margin-left: 5px; width: 80px"
            @click="setPriceType"
            >{{ priceType ? '范围' : '精准' }}</el-button
          >
        </el-col>
        <!-- <el-col class="flex-align" :span="5">
          <el-checkbox v-model="checked">全景建模</el-checkbox>
          <el-tooltip class="item" effect="dark" placement="bottom-start">
            <div slot="content" style="width: 400px">
              我司运用全景建模以及全景内部可视化设计，方便客户线上直观了解您的房源，避免出现不必要的非客户兴趣房源带看的线下成本高效促进房源成交率，无论租房或是卖房，全景运用可以终身使用。
            </div>
            <div style="margin-left: 8px">
              <i class="el-icon-question"></i>
            </div>
          </el-tooltip>
        </el-col> -->
      </el-row>
      <!-- 房源位置 -->
      <p class="title" id="id2">房源位置</p>
      <el-row>
        <el-col :span="24">
          <label class="required" for="">位置：</label>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="provinceId"
            placeholder="选择省"
            @change="id => getAddress(id, 1)"
          >
            <el-option
              v-for="item in provinceOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="cityId"
            clearable
            placeholder="选择市"
            @change="id => getAddress(id, 2)"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 200px; margin-right: 10px"
            v-model="districtId"
            clearable
            placeholder="选择区县"
            @change="id => getAddress(id, 3)"
          >
            <el-option
              v-for="item in areaOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 200px"
            v-model="streetId"
            clearable
            placeholder="选择街道/乡镇"
            @change="id => getAddress(id, 4)"
          >
            <el-option
              v-for="item in streetOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="bmap">
        <b-map ref="map" v-if="mapShow" :adress="adress" @setPoint="setPoint">
          <!-- <map-mark></map-mark> -->
        </b-map>
      </div>
      <div class="switch">
        <span class="switch-txt">展示精准位置</span>
        <el-switch v-model="ruleForm.accurateLocation" active-color="#FFB031">
        </el-switch>
        <span class="switch-txt"
          >如果你不希望用户看到房源精准位置，可关闭精准位置，精准位置只用于后台审核</span
        >
      </div>
      <!-- 基本信息 -->
      <p class="title" id="id3">基本信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">厂房类型：</label>
          <el-select v-model="ruleForm.buildingType" placeholder="厂房类型">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">厂房结构：</label>
          <el-select v-model="ruleForm.structureType" placeholder="厂房结构">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">层数：</label>
          <el-select v-model="ruleForm.singleFloor" placeholder="层数">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">楼层：</label>
          <el-select v-model="ruleForm.floorType" placeholder="楼层">
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col class="" :span="8">
          <label for="">层高：</label>
          <el-input
            type="Number"
            v-model="ruleForm.floorHeight"
            @input="e => NumberCheck(e, 'floorHeight')"
            placeholder="层高"
          >
            <span slot="suffix">米</span>
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">建筑年份：</label>
          <el-date-picker
            style="width: 100%"
            v-model="ruleForm.buildYear"
            type="year"
            format="yyyy"
            value-format="yyyy"
            :picker-options="pickerOptions"
            placeholder="选择建筑年份"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">楼板承重：</label>
          <el-input
            type="Number"
            v-model="ruleForm.bearing"
            @input="e => NumberCheck(e, 'bearing')"
            placeholder="楼板承重"
          >
            <span slot="suffix">吨</span>
          </el-input>
        </el-col>
      </el-row>
      <!-- 出租信息 -->
      <p class="title" id="id4">出租信息</p>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">出租方式：</label>
          <el-select v-model="ruleForm.sublet" placeholder="出租方式">
            <el-option
              v-for="item in rentalMethod"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">起租时间：</label>
          <el-select v-model="ruleForm.minLeaseMonth" placeholder="起租时间">
            <el-option
              v-for="item in options5"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">可租时间：</label>
          <el-date-picker
            style="width: 100%"
            v-model="ruleForm.canLeaseTime"
            type="month"
            format="yyyy-MM"
            value-format="yyyyMM"
            :picker-options="pickerOptions1"
            placeholder="选择可租时间"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <label class="required" for="">看房时间：</label>
          <el-select v-model="ruleForm.lookTime" placeholder="看房时间">
            <el-option
              v-for="item in options8"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col v-if="ruleForm.sublet" class="" :span="8">
          <label for="">起租面积：</label>
          <el-input
            type="Number"
            v-model="ruleForm.minArea"
            @input="e => NumberCheck(e, 'minArea')"
            placeholder="起租面积"
          >
            <span slot="suffix">㎡</span>
          </el-input>
        </el-col>
        <el-col :span="8">
          <label class="required" for="">押金：</label>
          <el-input
            maxlength="8"
            v-model="ruleForm.cashPledge"
            placeholder="押金：如押*付*"
          >
          </el-input>
        </el-col>
        <!-- <el-col class="" :span="5">
          <el-input
            maxlength="30"
            v-model="ruleForm.name"
            placeholder="行业限制"
          >
          </el-input>
        </el-col> -->
      </el-row>
      <el-row>
        <el-col class="flex-align" :span="8">
          <label for="">可办环评：</label>
          <!-- <el-checkbox v-model="ruleForm.canEia">可办环评</el-checkbox> -->
          <el-select v-model="ruleForm.canEia" placeholder="可办环评">
            <el-option label="是" :value="true"> </el-option>
            <el-option label="否" :value="false"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- 配套设施 -->
      <p class="title" id="id5">配套设施</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.state ? 'active' : ''"
          v-for="item in facilityList"
          :key="item.id"
          @click="item.state = !item.state"
        >
          {{ item.name }}
        </div>
        <!-- <div class="facility-item" @click="addWareHouse('选择仓库')">
          + 添加仓库房源
        </div>
        <div class="facility-item" @click="addWareHouse('选择宿舍')">
          + 添加宿舍房源
        </div> -->
      </div>
      <div class="add-house"></div>
      <!-- 价格详情 -->
      <p class="title" id="id6">价格详情</p>
      <el-row>
        <el-col class="" :span="8">
          <label for="">电费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.electricRate"
            @input="e => NumberCheck(e, 'electricRate')"
            placeholder="请输入电费"
          >
            <span slot="suffix">元/kwh</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">水费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.waterRate"
            @input="e => NumberCheck(e, 'waterRate')"
            placeholder="请输入水费"
          >
            <span slot="suffix">元/m³</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">空调费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.airConditioningFee"
            @input="e => NumberCheck(e, 'airConditioningFee')"
            placeholder="请输入空调费"
          >
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="" :span="8">
          <label for="">管理费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.propertyCost"
            @input="e => NumberCheck(e, 'propertyCost')"
            placeholder="请输入物业管理费"
          >
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">停车费：</label>
          <el-input
            type="Number"
            v-model="ruleForm.parkingFee"
            @input="e => NumberCheck(e, 'parkingFee')"
            placeholder="请输入停车费"
          >
            <span slot="suffix">元/月</span>
          </el-input>
        </el-col>
        <el-col class="" :span="8">
          <label for="">其他费用：</label>
          <el-input
            maxlength="30"
            v-model="ruleForm.otherFee"
            placeholder="请输入其他费用"
          >
          </el-input>
        </el-col>
      </el-row>
      <!-- 优惠费用 -->
      <p class="title" id="id7">优惠费用</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.id === ruleForm.preferentialId ? 'active' : ''"
          v-for="item in discounts"
          :key="item.id"
          @click="setDiscounts(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 房源特色 -->
      <p class="title" id="id8">房源特色</p>
      <div class="facility">
        <div
          class="facility-item"
          :class="item.state ? 'active' : ''"
          v-for="item in feature"
          :key="item.id"
          @click="item.state = !item.state"
        >
          {{ item.name }}
        </div>
        <div class="facility-item" @click="featureVisible = true">
          + 自定义房源特色
        </div>
      </div>
      <div class="facility">
        <div
          class="facility-item active"
          style="position: relative"
          v-for="(item, index) in ruleForm.customLabels"
          :key="index"
        >
          {{ item }}
          <span
            class="el-icon-circle-close"
            @click="delCustomLabel(index)"
          ></span>
        </div>
      </div>
      <!-- 房源图片 -->
      <p class="title" id="id9">
        房源图片
        <span
          >照片将展示于您发布房源的详情页面，支持JPG/PNG/JPEG格式的图片，文件不超过5MB，照片规格按4：3上传,上传的第一张图片默认为封面</span
        >
      </p>
      <div class="picture">
        <el-upload
          :action="uploadIp + '/file/common/upload/img/build'"
          :headers="headers"
          accept="image/.jpg,image./jepg,image/.png"
          list-type="picture-card"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :on-change="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <!-- 房源视频 -->
      <!-- <p class="title">
        房源视频
        <span>照片将展示于您发布房源的详情页面，支持MP4，文件不超过5MB</span>
      </p>
      <div class="picture">
        <el-upload
          class="flex-align"
          :action="uploadIp + '/file/common/upload/img/build'"
          :headers="headers"
          accept="video/.mp4"
          list-type="picture-card"
          :limit="1"
          :before-upload="beforeVideoUpload"
          :on-change="handleVideoCardPreview"
          :on-remove="handleVideoRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div> -->
      <!-- 详细介绍 -->
      <p class="title" id="id10">
        详细介绍 <span>禁止出现电话号码、邮箱地址、微信号等一切联系方式</span>
      </p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="ruleForm.details"
        maxlength="500"
        show-word-limit
      >
      </el-input>

      <div class="btn-box">
        <!-- <el-button class="cancel">取消</el-button> -->
        <el-button type="primary" class="next" @click="submit"
          >提交房源信息</el-button
        >
      </div>
    </main>
    <!-- 裁剪图片 -->
    <CropperDialog ref="cropper" @subUploadSucceed="subUploadSucceed" />
    <!-- 预览图片 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 添加自定义房源特色 -->
    <el-dialog
      title="添加自定义房源特色"
      :visible.sync="featureVisible"
      width="480px"
      :before-close="featureClose"
    >
      <div class="body">
        <el-input
          v-model="customLabel"
          maxlength="6"
          placeholder="请输入..."
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="featureVisible = false"
          >取消</el-button
        >
        <el-button class="btn" type="primary" @click="addCustomLabel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择仓库、宿舍 -->
    <el-dialog
      :title="warehouseTitle"
      :visible.sync="warehouseVisible"
      width="50%"
      :before-close="warehouseClose"
    >
      <div class="body scroll">
        <PublishHouseListItem :plantList="plantList" @onListItem="onListItem" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="warehouseVisible = false"
          >新增仓库房源</el-button
        >
        <el-button class="btn" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <!-- 提交完成 -->
    <el-dialog title="提示" :visible.sync="subSuccessVisible" width="50%">
      <div class="body">
        房源已发布，需等后台管理系统审核完毕后上架。是否进入我的房源？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="subSuccessVisible = false"
          >取消</el-button
        >
        <el-button class="btn" type="primary" @click="subSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择房源类型 -->
    <el-dialog
      title="选择房源类型"
      :visible.sync="buildTypeVisible"
      width="470px"
    >
      <div class="body">
        <div class="type">
          <div
            class="type-item"
            :class="publishBuildType == 'plant_hire' ? 'active' : ''"
            @click="publishBuildType = 'plant_hire'"
          >
            厂房出租 <i class="el-icon-check"></i>
          </div>
          <div
            class="type-item"
            :class="publishBuildType == 'office_hire' ? 'active' : ''"
            @click="publishBuildType = 'office_hire'"
          >
            写字楼出租 <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="type-btn"
          type="primary"
          @click="submitPublishBuildType"
          >下一步</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import autofit from 'autofit.js'
import { uploadBuild } from 'services/file'
import { certificationStatus, publishBuild, buildDetail, editPublishBuild } from 'services/broker'
import PublishHouseListItem from 'components/house/PublishHouseListItem.vue';
import BMap from 'components/map/BMap.vue';
import { getAreaByParent, facilitiesList, labelList } from 'services/common';
import CropperDialog from 'components/CropperDialog.vue';
export default {
  name: 'PiblishHousing',
  components: {
    PublishHouseListItem,
    BMap,
    CropperDialog
  },
  data () {
    return {
      steps: [
        {
          id: 'id1',
          label: '发布信息'
        }, {
          id: 'id2',
          label: '房源位置'
        }, {
          id: 'id3',
          label: '基本信息'
        }, {
          id: 'id4',
          label: '出租信息'
        }, {
          id: 'id5',
          label: '配套设施'
        }, {
          id: 'id6',
          label: '价格详情'
        }, {
          id: 'id7',
          label: '优惠费用'
        }, {
          id: 'id8',
          label: '房源特色'
        }, {
          id: 'id9',
          label: '房源图片'
        }, {
          id: 'id10',
          label: '详情介绍'
        }
      ],
      step: 'id1',
      adress: {},
      mapShow: false,
      uploadIp: window.uploadIp,
      headers: {
        Authorization: 'Bearer ' + localStorage.token
      },
      priceType: true,
      customLabel: '',
      ruleForm: {
        title: '',
        totalArea: '',
        price: '',
        maxPrice: '',
        lng: '',
        lat: '',
        accurateLocation: true,
        floorHeight: '',
        bearing: '',
        sublet: false,
        minArea: '',
        cashPledge: '',
        buildYear: null,
        floorType: 1,
        buildingType: 1,
        structureType: 1,
        singleFloor: true,
        canEia: true,
        minLeaseMonth: 3,
        canLeaseTime: null,
        lookTime: 1,
        electricRate: '',
        waterRate: '',
        airConditioningFee: '',
        propertyCost: '',
        parkingFee: "",
        otherFee: "",
        facilitiesIds: [], // 配套设施
        labelIds: [], // 房源特色标签
        customLabels: [], // 自定义房源特色标签
        preferential: [], // 优惠标签
        preferentialId: [], // 优惠标签
        coverImg: '',
        images: [],
        videos: [],
        details: "",
      },
      provinceId: null,
      provinceOptions: [],
      cityId: null,
      cityOptions: [],
      districtId: null,
      areaOptions: [],
      streetId: null,
      streetOptions: [],
      options: [{
        value: '2',
        label: '全部'
      }, {
        value: '1',
        label: '已上线'
      }],
      options1: [{
        value: 1,
        label: '标准厂房'
      }, {
        value: 2,
        label: '独院厂房'
      }, {
        value: 3,
        label: '园区厂房'
      }, {
        value: 4,
        label: '特种厂房'
      }, {
        value: 5,
        label: '简易厂房'
      }, {
        value: 6,
        label: '其他'
      }],
      options2: [{
        value: 1,
        label: '钢结构'
      }, {
        value: 2,
        label: '钢混结构'
      }, {
        value: 3,
        label: '砖混结构'
      }, {
        value: 4,
        label: '水泥结构'
      }, {
        value: 5,
        label: '铁皮厂房'
      }, {
        value: 6,
        label: '其他'
      }],
      options3: [{
        value: true,
        label: '单层'
      }, {
        value: false,
        label: '多层'
      }],
      options4: [{
        value: 1,
        label: '一楼'
      }, {
        value: 2,
        label: '二楼及以上'
      }, {
        value: 3,
        label: '独栋'
      }, {
        value: 4,
        label: '其他'
      }],
      options5: [{
        value: 3,
        label: '三个月'
      }, {
        value: 6,
        label: '六个月'
      }, {
        value: 12,
        label: '一年'
      }, {
        value: 24,
        label: '两年'
      }, {
        value: 36,
        label: '三年'
      }, {
        value: 60,
        label: '五年'
      }],
      options7: [{
        value: 1,
        label: '随时'
      }, {
        value: 2,
        label: '下月'
      }, {
        value: 3,
        label: '下季'
      }, {
        value: 4,
        label: '明年'
      }],
      options8: [{
        value: 1,
        label: '随时可看'
      }, {
        value: 2,
        label: '工作日可看'
      }, {
        value: 3,
        label: '周末节假日可看'
      }],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      value: "",
      switch1: true,
      rentalMethod: [{
        value: true,
        label: '分租'
      }, {
        value: false,
        label: '整租'
      }],
      checked: true,
      buildType: 1,
      facilityList: [], // 配套设施
      feature: [], // 房源特色
      discounts: [], // 优惠费用
      featureVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      warehouseTitle: '选择仓库',
      warehouseVisible: false,
      subSuccessVisible: false,
      buildTypeVisible: false,
      publishBuildType: 'plant_hire',
      plantList: [],
      loseVisible: false,
      fileList: []
    }
  },
  watch: {
    provinceId (val) {
      console.log(val);
      if (val) {
        this.cityId = null
      }
    },
    cityId (val) {
      console.log(val);
      this.districtId = null
      this.areaOptions = []
    },
    districtId (val) {
      console.log(val);
      this.streetId = null
      this.streetOptions = []
    },
  },
  beforeRouteEnter (to, from, next) {
    // ...
    console.log(to, from);
    next()
    if (from.path !== '/') {
      // window.location.reload();
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    console.log(to, from);
    let isBoss = confirm("跳转其他页面将不会保存发布房源输入的内容, 是否继续?");
    if (isBoss) {
      next(true)
    } else {
      this.$eventBus.$emit('eventDefActive')
    }
  },
  beforeDestroy () {
    console.log('关闭');
    // window.removeEventListener('beforeunload');
  },
  async mounted () {
    let vm = this
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e), false)
    // 判断认证状态
    const res = await certificationStatus()
    console.log('判断认证状态', res);
    if (res.data.status === 200)
      if (res.data.data.status === 1) this.$router.push('/user')
    // 获取标签
    await this.getLabel()
    await this.getAddress()
    // 我的房源进来
    if (this.$route.query?.id) {
      this.getDetails(this.$route.query.id)
    } else {
      this.buildTypeVisible = true
      this.mapShow = true
      this.adress = {
        name: '深圳',
        zoom: 12
      }
    }
  },
  methods: {
    submitPublishBuildType () {
      let path = ''
      if (this.publishBuildType == 'plant_hire') path = '/publishHousing'
      if (this.publishBuildType == 'office_hire') path = '/publishHousingOffice'
      this.buildTypeVisible = false
      if (this.publishBuildType != 'plant_hire') this.$router.push({ path })
    },
    async getDetails (id) {
      await buildDetail({ id }).then(async res => {
        if (res.data.status === 200) {
          this.mapShow = true
          let {
            title,
            totalArea,
            price,
            maxPrice,
            provinceId,
            cityId,
            districtId,
            streetId,
            lng,
            lat,
            accurateLocation,
            floorHeight,
            bearing,
            sublet,
            minArea,
            cashPledge,
            buildYear,
            floorType,
            buildingType,
            structureType,
            singleFloor,
            canEia,
            minLeaseMonth,
            canLeaseTime,
            lookTime,
            electricRate,
            waterRate,
            airConditioningFee,
            propertyCost,
            parkingFee,
            otherFee,
            facilitiesIds, // 配套设施
            labelList,
            preferential, // 优惠标签
            preferentialId, // 优惠标签
            coverImg,
            images,
            videos,
            details,
            facilitiesList
          } = res.data.data
          this.$nextTick(async () => {
            // 当最小租金等于最大租金时，为精准
            if (price === maxPrice) this.priceType = true
            let labelIds = []
            let customLabels = []
            if (labelList) {
              labelIds = labelList.filter(item => item.id).map(item => item.id)
              customLabels = labelList.filter(item => item.customLabel).map(item => item.customLabel)
              console.log('this.feature', this.feature);
              labelIds.forEach(id => {
                this.feature.forEach(item => {
                  if (id === item.id) item.state = true
                })
              })
            }
            this.ruleForm = {
              title,
              totalArea,
              price,
              maxPrice,
              lng,
              lat,
              accurateLocation,
              floorHeight,
              bearing,
              sublet,
              minArea,
              cashPledge,
              buildYear: buildYear.toString(),
              floorType,
              buildingType,
              structureType,
              singleFloor,
              canEia,
              minLeaseMonth,
              canLeaseTime: canLeaseTime.toString(),
              lookTime,
              electricRate,
              waterRate,
              airConditioningFee,
              propertyCost,
              parkingFee,
              otherFee,
              facilitiesIds, // 配套设施
              labelIds, // 房源特色标签
              customLabels, // 自定义房源特色标签
              preferential, // 优惠标签
              preferentialId,
              coverImg,
              images,
              videos,
              details,
            }
            if (facilitiesList)
              this.facilityList = facilitiesList.map(item => {
                return {
                  ...item,
                  state: item.enable
                }
              })
            this.provinceId = provinceId
            await this.getPoint(provinceId, 1)
            this.cityId = cityId
            await this.getPoint(cityId, 2)
            this.districtId = districtId
            await this.getPoint(districtId, 3)
            this.streetId = streetId
            this.$refs.map.point = [lng, lat]
            this.fileList = images.map(item => {
              return {
                url: window.los + item + '?' + Date.now(),
                response: {
                  data: [{ fileUrl: item }],
                  status: 200
                }
              }
            })
          })
        }
      })
    },
    getStep (item) {
      this.step = item.id
      const element = document.getElementById(item.id)
      const main = document.getElementById('main')
      main.scrollTo({
        top: element.offsetTop - 120,
        behavior: 'smooth'
      })
    },
    beforeunloadHandler (e) {
      console.log('----', e);
      this._beforeUnload_time = new Date().getTime()
      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    },
    unloadHandler (e) {
      console.log('----', e);
      this._beforeUnload_time = new Date().getTime()
    },
    // 限制输入框
    NumberCheck (num, val) {
      let str = val === 'totalArea' ? Number(Number(num).toFixed(0)) : Number(Number(num).toFixed(2));
      if (str < 0) str = str * -1;
      if (str.toString().length > 10) str = str.toString().substring(0, 10);
      this.ruleForm[val] = str
    },
    blurMaxPrice () {
      if (this.ruleForm.maxPrice && this.ruleForm.price && this.ruleForm.maxPrice < this.ruleForm.price) {
        const maxPrice = this.ruleForm.price
        this.ruleForm.price = this.ruleForm.maxPrice
        this.ruleForm.maxPrice = maxPrice
      }
    },
    setPriceType () {
      this.priceType = !this.priceType
      // if (!this.priceType) {
      //     this.ruleForm.maxPrice = this.ruleForm.price
      //   } else {
      //   this.ruleForm.maxPrice = null
      // }
    },
    subSuccess () {
      this.$router.push('/myHousing')
    },
    getLabel () {
      let obj = {
        type: this.buildType
      }
      // 配套设施
      facilitiesList(obj).then(res => {
        if (res.data.status === 200) {
          this.facilityList = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
      // 房源特色
      labelList({ buildType: this.buildType, category: 1 }).then(res => {
        if (res.data.status === 200) {
          this.feature = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
      // 优惠标签
      labelList({ buildType: this.buildType, category: 2 }).then(res => {
        if (res.data.status === 200) {
          this.discounts = res.data.data.map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
    },
    // 获取省市区街道
    getAddress (id, treeNode) {
      getAreaByParent({ parentId: id }).then(async res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions = res.data.data;
          }
          if (treeNode === 1 && id) {
            this.cityOptions = res.data.data
            this.province = await this.provinceOptions.filter(item => item.id === id)[0].name
            let obj = this.provinceOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 8 }
          }
          if (treeNode === 2 && id) {
            this.areaOptions = res.data.data
            this.city = await this.cityOptions.filter(item => item.id === id)[0].name
            let obj = this.cityOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 12, name: this.province + this.city }
          }
          if (treeNode === 3 && id) {
            this.streetOptions = res.data.data
            this.area = await this.areaOptions.filter(item => item.id === id)[0].name
            let obj = this.areaOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 14, name: this.province + this.city + this.area }
          }
          if (treeNode === 4 && id) {
            this.street = this.streetOptions.filter(item => item.id === id)[0].name
            let obj = this.streetOptions.filter(item => item.id === id)[0]
            this.adress = { ...obj, zoom: 16, name: this.province + this.city + this.area + this.street }
          }
        }
      })
    },
    getPoint (id, treeNode) {
      getAreaByParent({ parentId: id }).then(async res => {
        if (res.data.status === 200) {
          if (!treeNode) {
            this.provinceOptions = res.data.data;
          }
          if (treeNode === 1) {
            this.cityOptions = res.data.data
            this.province = await this.provinceOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 2) {
            this.areaOptions = res.data.data
            this.city = await this.cityOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 3) {
            this.streetOptions = res.data.data
            this.area = await this.areaOptions.filter(item => item.id === id)[0].name
          }
          if (treeNode === 4) {
            this.street = this.streetOptions.filter(item => item.id === id)[0].name
          }
        }
      })
    },
    // 获取地图点位置坐标
    setPoint (arr) {
      console.log('point', arr);
      this.ruleForm.lng = arr[0]
      this.ruleForm.lat = arr[1]
    },
    featureClose () {
      this.featureVisible = false
    },
    warehouseClose () {
      this.warehouseVisible = false
    },
    async beforeUpload (file) {
      console.log('---------', file);
      const isLt2M = file.size / 1024 / 1024 < 5
      const img = new Image()
      const _this = this
      return new Promise((resolve, reject) => {
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          reject(false)
          return
        }
        img.src = URL.createObjectURL(file)
        img.onload = function () {
          const width = img.width
          const height = img.height
          const ratio = width / height
          _this.$refs.cropper.showCropper = true
          _this.$refs.cropper.getCropperImg(file)
          // if (ratio < 1.30 || ratio > 1.35) {
          //   _this.$message.error('上传图片尺寸比例应为 4:3!')
          //   reject(false)
          // } else {
          //   resolve(true)
          // }
          // resolve(true)
          reject(false)
        }
      })
    },
    async beforeVideoUpload (flile) { },
    handlePictureCardPreview (file, fileList) {
      console.log(file, fileList, this.fileList);
      if (file.response) {
        console.log(file, fileList.filter(item => item?.response.status === 200));
        this.ruleForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
          return item.response.data[0].fileUrl
        })
        this.ruleForm.coverImg = this.ruleForm.images[0]
      }
    },
    subUploadSucceed (formData) {
      uploadBuild(formData).then(res => {
        console.log('截图===>', res);
        this.fileList.push({
          url: window.los + res.data.data[0].fileUrl + '?' + Date.now(),
          response: {
            data: [{ fileUrl: res.data.data[0].fileUrl }],
            status: 200
          }
        })
        this.ruleForm.images = this.fileList.filter(item => item?.response.status === 200).map(item => {
          return item.response.data[0].fileUrl
        })
        this.ruleForm.coverImg = this.ruleForm.images[0]
        this.$refs.cropper.showCropper = false
      })
    },
    handleVideoCardPreview (file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videos = fileList
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList
      this.ruleForm.images = fileList.filter(item => item?.response.status === 200).map(item => {
        return item.response.data[0].fileUrl
      })
      if (!this.ruleForm.images.length) this.ruleForm.coverImg = ''
    },
    handleVideoRemove (file, fileList) {
      console.log(file, fileList);
      this.ruleForm.videos = fileList
    },
    addWareHouse (str) {
      this.warehouseTitle = str;
      this.warehouseVisible = true
    },
    addCustomLabel () {
      this.ruleForm.customLabels.push(this.customLabel)
      this.customLabel = ''
      this.featureVisible = false
    },
    delCustomLabel (index) {
      this.ruleForm.customLabels = this.ruleForm.customLabels.filter((item, i) => index !== i)
    },
    onListItem (item) {
      this.plantList.forEach(i => {
        if (item.id === i.id) i.state = !i.state
      })
    },
    setDiscounts (item) {
      if (this.ruleForm.preferentialId === item.id) {
        this.ruleForm.preferentialId = null
      } else {
        this.ruleForm.preferential = item.name
        this.ruleForm.preferentialId = item.id
      }
    },
    submit () {
      if (!this.ruleForm.title) {
        return this.$message.warning('请输入房源名称')
      } else if (!this.ruleForm.totalArea) {
        return this.$message.warning('请输入房源总面积')
      } else if (!this.ruleForm.price) {
        return this.$message.warning('请输入房源租金单价或最小租金')
      } else if (!this.priceType && !this.ruleForm.maxPrice) {
        return this.$message.warning('请输入房源最大租金')
      } else if (!this.provinceId) {
        return this.$message.warning('请选择省')
      } else if (!this.cityId) {
        return this.$message.warning('请选择市')
      } else if (!this.districtId) {
        return this.$message.warning('请选择区县')
      } else if (!this.streetId) {
        return this.$message.warning('请选择街道、乡镇')
      } else if (!this.ruleForm.lng && !this.ruleForm.lat) {
        return this.$message.warning('请在地图上标明地址')
      } else if (!this.ruleForm.lng && !this.ruleForm.lat) {
        return this.$message.warning('请在地图上标明地址')
      } else if (!this.ruleForm.buildYear) {
        return this.$message.warning('请选择建筑年份')
      } else if (!this.ruleForm.bearing) {
        return this.$message.warning('请输入楼板承重')
      } else if (!this.ruleForm.bearing) {
        return this.$message.warning('请输入楼板承重')
      } else if (this.ruleForm.sublet && this.ruleForm.minArea >= this.ruleForm.totalArea) {
        return this.$message.warning('起租面积不能大于或等于总面积')
      } else if (!this.ruleForm.canLeaseTime) {
        return this.$message.warning('请选择可租时间')
      } else if (!this.ruleForm.cashPledge) {
        return this.$message.warning('请输入押金')
      } else {
        let params = {
          ...this.ruleForm,
          provinceId: this.provinceId,
          cityId: this.cityId,
          districtId: this.districtId,
          streetId: this.streetId,
          facilitiesIds: this.facilityList.filter(item => item.state).map(item => item.id),
          labelIds: this.feature.filter(item => item.state).map(item => item.id),
          coverImg: this.ruleForm.images[0] || ''
        }
        if (this.priceType) params.maxPrice = params.price
        if (!params.sublet) params.minArea = null
        let req
        if (this.$route.query?.id) {
          req = editPublishBuild
          params.id = this.$route.query?.id
        } else {
          req = publishBuild
        }
        req(params).then(res => {
          console.log(res);
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
            this.subSuccessVisible = true
          }
        })
      }

    },
  }
};
</script>

<style lang="less" scoped>
.publish-housing {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 16px 0;
  overflow: hidden;
  .steps {
    width: 160px;
    height: 100%;
    padding: 24px 32px;
    .step-item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      position: relative;
      cursor: pointer;
      &:nth-child(1) {
        margin-bottom: 8px;
        .step-icon {
          width: 10px;
          height: 10px;
          margin-right: -1px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          border-radius: 50%;
          border: 2px solid rgba(219, 217, 215, 1);
          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 2.5px;
            width: 1px;
            height: 25px;
            background: rgba(230, 230, 230, 1);
          }
        }
      }
      &:nth-child(11) {
        margin-bottom: 8px;
        .step-icon::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 2.5px;
          width: 1px;
          height: 25px;
          background: rgba(230, 230, 230, 1);
        }
      }
      &:nth-child(12) {
        .step-icon {
          width: 10px;
          height: 10px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
          border-radius: 50%;
          margin-right: -1px;
          border: 2px solid rgba(219, 217, 215, 1);
          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 2.5px;
            width: 1px;
            height: 0px;
            background: rgba(230, 230, 230, 1);
          }
        }
      }
      .step-text {
        width: 100px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(153, 153, 153, 1);
        &.active {
          color: rgba(236, 102, 0, 1);
        }
      }
      .step-icon {
        width: 8px;
        height: 8px;
        opacity: 1;
        background: rgba(219, 217, 215, 1);
        border-radius: 50%;
        border: 1px solid rgba(219, 217, 215, 1);
        // margin-left: 24px;
        position: relative;
        &.active {
          background-image: url(../../assets/steps.png);
          width: 25px;
          height: 25px;
          background-size: 100% 100%;
          background-color: #fff;
          border-radius: 0;
          border: 0;
          position: absolute;
          right: -9px;
          &::before {
            content: '';
            position: absolute;
            top: 20px;
            left: 11.5px;
            width: 1px;
            height: 60px;
            background: rgba(230, 230, 230, 1);
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 2.5px;
          width: 1px;
          height: 60px;
          background: rgba(230, 230, 230, 1);
        }
      }
    }
  }
  main {
    width: calc(100% - 160px);
    height: 100%;
    padding: 0px 24px;
    overflow: auto;
    label {
      width: 120px;
      text-align: right;
      justify-content: end;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
    margin-top: 16px;
    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .bmap {
    width: calc(83.3% + 60px);
    height: 280px;
    margin-bottom: 8px;
    // background: url(https://img.js.design/assets/img/651fa99e992fbaa8dc1d7f23.png#8e037f32d622ef2bbd363889c8001f51);
    background-size: 100% 100%;
    border: 1px solid rgba(245, 243, 240, 1);
  }
  .switch {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    &-txt {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(51, 51, 51, 1);
      margin-right: 8px;
      &:last-child {
        color: rgba(153, 153, 153, 1);
        margin-left: 12px;
      }
    }
  }
  .facility {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &-item {
      width: 200px;
      height: 44px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(245, 245, 245, 1);
      text-align: center;
      line-height: 44px;
      margin-bottom: 16px;
      margin-right: 20px;
      cursor: pointer;
      span {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
      }
      &.active {
        background: rgba(255, 237, 224, 1);
        border: 0.6px solid rgba(236, 102, 0, 1);
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .picture {
    padding: 18px 20px;
    border-radius: 4px;
    background: rgba(245, 245, 245, 1);

    border: 1px solid rgba(245, 243, 240, 1);
  }

  .btn-box {
    border-top: 1px solid rgba(238, 238, 238, 1);
    margin-top: 28px;
    text-align: center;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel {
      width: 160px;
      height: 60px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(255, 237, 224, 1);
      border: 1px solid rgba(255, 237, 224, 1);
      font-size: 20px;
      font-weight: 600;
      color: rgba(236, 102, 0, 1);
    }
    .next {
      width: 160px;
      height: 60px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(236, 102, 0, 1);
      font-size: 20px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);

      border: 1px solid rgba(236, 102, 0, 1);
    }
  }
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    .type {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .type-item {
        width: 160px;
        height: 90px;
        line-height: 90px;
        border-radius: 6px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        cursor: pointer;
        i {
          display: none;
        }
        &.active {
          border: 2px solid rgba(236, 102, 0, 1);
          position: relative;
          i {
            display: block;
            position: absolute;
            right: 2px;
            bottom: 2px;
            z-index: 1;
          }
          &::after {
            content: '';
            position: absolute;
            right: -25px;
            bottom: -25px;
            border: 25px solid;
            border-color: transparent transparent #ec6600 transparent;
            transform: rotate(135deg);
          }
        }
        &:nth-child(1) {
          background: url(../../assets/plant/workshop-hire.png);
        }
        &:nth-child(2) {
          background: url(../../assets/plant/office-hire.png);
        }
      }
    }
  }
  .scroll {
    height: 50vh;
    overflow: auto;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  .type-btn {
    width: 200px;
    height: 40px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 233, 217, 1) !important;
    font-size: 16px;
    font-weight: 400;
    color: rgba(236, 102, 0, 1);
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

/deep/ .el-button {
  height: 36px;
  display: flex;
  align-items: center;
  background: #ff9a4d !important;
  border: 1px solid #ff9a4d !important;
  color: #fff;
}
/deep/ .el-row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
/deep/ .el-col-5 {
  margin-right: 20px;
}
/deep/.el-input__inner {
  height: 38px;
  line-height: 38px;
  opacity: 1;
  border-radius: 4px;
  border: 1px solid rgba(219, 217, 215, 1);
}
/deep/ .el-input__suffix {
  line-height: 44px;
  right: 10px;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 165px;
  height: 124px;
  opacity: 1;
  border-radius: 4px;
  border: 0;
}
/deep/ .el-upload--picture-card {
  width: 165px;
  height: 124px;
  line-height: 124px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px dashed rgba(238, 236, 233, 1);
  margin-bottom: 8px;
  i {
    color: rgba(236, 102, 0, 1);
    font-size: 16px;
  }
}

/deep/ .el-textarea__inner {
  // width: calc(83.3% + 60px);
  white-space: pre-wrap;
  height: 160px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(245, 245, 245, 1);
}
/deep/ .el-textarea .el-input__count {
  background: rgba(245, 245, 245, 1);
}
/deep/ .el-input__suffix-inner {
  pointer-events: all;
  height: 100%;
  display: flex;
  align-items: center;
}
/deep/ .el-popper {
  padding: 0;
}
/deep/ .el-col-24 {
  display: flex;
  align-items: center;
}
/deep/ .el-col-8 {
  display: flex;
  align-items: center;
  .el-select {
    width: calc(100% - 140px) !important;
    .el-input {
      width: 100% !important;
    }
  }
  .el-input {
    width: calc(100% - 140px) !important;
  }
}
</style>