<template>
  <div class="user-steps">
    <p class="p1">欢迎进行用户认证</p>
    <p class="p2">完成用户认证即可发布房源</p>
    <div class="steps">
      <div class="steps-item" v-for="item in stepsList" :key="item.id">
        <div
          class="steps-item-icon"
          :class="steps > item.id || steps === item.id ? 'active' : ''"
        ></div>
        <div
          class="text"
          :class="steps > item.id || steps === item.id ? 'active' : ''"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  name: 'UserSteps',
  props: {
    steps: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      stepsList: [
        { id: 1, text: '填写信息' },
        // { id: 2, text: '缴纳押金' },
        { id: 2, text: '身份认证' },
        { id: 3, text: '认证结果' },
      ]
    }
  },
  methods: {
  }
};
    </script>
    
    <style lang="less" scoped>
.user-steps {
  width: 100%;
  height: 260px;
  background: url(../../assets/plant/user/banner.png);
  background-size: 100% 100%;
  .p1 {
    font-size: 44px;
    font-weight: 400;
    letter-spacing: 3px;
    line-height: 64px;
    color: rgba(255, 255, 255, 1);
    padding-top: 40px;
    text-align: center;
  }
  .p2 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 30px;
    color: rgba(255, 232, 194, 1);
    padding-top: 10px;
    text-align: center;
  }
  .steps {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 33px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 120px;
      &:last-child {
        margin-right: 0;
        .steps-item-icon::after {
          content: '';
          width: 0;
          display: none;
        }
      }
      &-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: rgba(255, 196, 102, 1);
        margin-bottom: 3px;
        &::after {
          content: '';
          border-bottom: 1px dashed rgba(255, 178, 54, 1);
          display: inline-block;
          width: 155px;
          margin-bottom: 12px;
          margin-left: 20px;
        }
        &.active {
          background: #fff;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: calc(-50% + 1px);
            left: calc(-50% + 1px);
            display: flex;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.4);
          }
          &::after {
            content: '';
            border-bottom: 1px dashed #fff;
          }
        }
      }
      .text {
        color: rgba(255, 214, 148, 1);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        &.active {
          color: #fff;
        }
      }
    }
  }
}
</style>
    