<template>
  <div class="billing-history">
    <header>
      <div class="title">
        <i class="el-icon-back" @click="back"></i>
        <div>发票管理>开票历史</div>
      </div>
      <div class="search">
        <el-date-picker
          class="picker"
          v-model="value1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
    </header>
    <main>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="date" label="流水号"> </el-table-column>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="address" label="订单时间"> </el-table-column>
        <el-table-column prop="address" label="开票时间"> </el-table-column>
        <el-table-column prop="remark" label="已开发票金额" width="150px">
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="address"
          label="操作"
          width="100px"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="del(scope.row)" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          v-show="pageSize < total"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </main>
    <!-- 删除 -->
    <el-dialog
      title="删除开票记录"
      :visible.sync="billVisible"
      width="480px"
      :before-close="billClose"
    >
      <div class="body">是否删除该条开票记录</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" @click="billVisible = false">取消</el-button>
        <el-button class="btn" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: 'BillingHistory',
  data () {
    return {
      keywords: '',
      value: '',
      value1: [],
      tableData: [{
        id: 1,
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        remark: '意向强烈，近期看房',
        type: '1',
        show: true,
      }, {
        id: 2,
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄',
        remark: '意向强烈，近期看房',
        type: '1',
        show: true,
      }, {
        id: 3,
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄',
        remark: '意向强烈，近期看房',
        type: '3',
        show: true,
      }, {
        id: 4,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '2',
        show: true,
      }, {
        id: 5,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '1',
        show: true,
      }, {
        id: 6,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '3',
        show: true,
      }, {
        id: 7,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '1',
        show: true,
      }, {
        id: 8,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '1',
        show: true,
      }, {
        id: 9,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '3',
        show: true,
      }, {
        id: 10,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        remark: '意向强烈，近期看房',
        type: '2',
        show: true,
      }],
      pageSize: 10,
      total: 100,
      currentPage3: 1,
      multipleSelection: [],
      billVisible: false,
      delItem: {}
    };
  },
  watch: {},
  filters: {
  },
  methods: {
    billClose () {
      this.billVisible = false
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    del (item) {
      console.log(item);
      this.delItem = item
      this.billVisible = true
    },
    handleSelectionChange (val) {
      console.log(val);
      this.multipleSelection = val
    },
    back () {
      this.$router.back()
    }
  }
};
  </script>
  <style lang="less" scoped>
.billing-history {
  width: 100%;
  height: 100%;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 24px;
    border-bottom: 1px solid #f7f5f2;
    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      color: rgba(51, 51, 51, 1);
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 20px;
        font-weight: bold;
        padding-right: 20px;
        border-right: 1px solid #ccc;
        cursor: pointer;
      }
    }
    .search {
      display: flex;
      align-items: center;
      .picker {
        width: 270px;
        height: 38px;
        /deep/ .el-date-editor .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  main {
    padding: 20px 24px;
    width: 100%;
    height: calc(100% - 71px);
    .main-page {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #ffe6d4;
        font-size: 14px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background: #f5f5f5;
  height: 50px;
  line-height: 50px;
  padding: 0;
  border-right: 0;
}
/deep/ .el-table td.el-table__cell {
  height: 50px;
  padding: 0;
  border-right: 0;
  div {
    // height: 50px;
    // display: flex;
    // align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-button--text {
  color: #ec6600;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec6600;
  border-color: #ec6600;
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  