<template>
  <div class="recharge">
    <div class="recharge-top">
      <header>
        <div class="title">
          <i class="el-icon-back" @click="back"></i>
          <div>龙币管理>龙币充值</div>
        </div>
      </header>
      <main>
        <div class="s">
          <div
            class="gold-item"
            :class="coinPriceId === item.id ? 'active' : ''"
            v-for="item in goldList"
            :key="item.id"
            @click="getGold(item)"
          >
            <img src="../../assets/gold.png" alt="" />
            <div class="gold-num">
              <div class="gold">{{ item.coin }}龙币</div>
              <div class="money">￥{{ item.price }}</div>
            </div>
            <div class="send-out" v-if="item.giveCoin">
              送{{ item.giveCoin }}龙币
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="recharge-bottom">
      <div class="pay-money">
        <label for="">支付金额：</label>
        <div class="money">{{ goldItem.price }}元</div>
        <div class="sent-out" v-if="goldItem.giveCoin">
          送{{ goldItem.giveCoin }}龙币
        </div>
      </div>
      <div class="pay-type">
        <label for="">支付方式：</label>
        <div
          class="type-item"
          :class="payIndex === 'wx' ? 'active' : ''"
          @click="pay('wx')"
        >
          <img src="../../assets/weixin.png" alt="" />
          <span>微信支付</span>
        </div>
        <div
          class="type-item"
          :class="payIndex === 'zfb' ? 'active' : ''"
          @click="pay('zfb')"
        >
          <img src="../../assets/zfb.png" alt="" />
          <span>支付宝支付</span>
        </div>
        <div v-if="amount > canUseBalanceYuan">
          <div class="type-item disabled">
            <span>账户可用余额（￥{{ canUseBalanceYuan }}）</span>
          </div>
          <p style="color: red; text-align: center; width: 240px">
            当前可用余额不足
          </p>
        </div>
        <div
          v-else
          class="type-item"
          :class="payIndex === 'ye' ? 'active' : ''"
          @click="pay('ye')"
        >
          <span>账户可用余额（￥{{ canUseBalanceYuan }}）</span>
        </div>
      </div>
      <div class="pay-btn">
        <el-button class="btn" type="primary" @click="toRecharge"
          >立即充值</el-button
        >
      </div>
    </div>
    <!-- 余额充值 -->
    <el-dialog title="充值" :visible.sync="balanceVisible" width="480px">
      <div class="body">
        <p class="del">请确认是否使用余额充值龙币？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="balanceVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="yesBalance"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  billBalance,
  coinPrice,
  coinCharge
} from 'services/pay'
export default {
  name: 'Recharge',
  components: {
  },
  data () {
    return {
      goldList: [],
      coinPriceId: 1,
      payIndex: 'wx', // wx 微信 zfb 支付宝 ye 余额
      amount: 0,
      balance: null,
      canUseBalanceYuan: null,
      goldItem: {},
      balanceVisible: false
    };
  },
  watch: {
  },
  filters: {
  },
  mounted () {
    this.getCoinPrice()
    this.getCoinWallet()
  },
  methods: {
    getCoinPrice () {
      coinPrice().then(res => {
        if (res.data.status === 200) {
          this.goldList = res.data.data.filter(item => item.enable)
          this.coinPriceId = this.goldList[0]?.id
          this.amount = this.goldList[0]?.price
          this.goldItem = this.goldList[0]
        }
      })
    },
    // 查询余额钱包
    getCoinWallet () {
      billBalance().then(res => {
        if (res.data.status === 200) {
          let { balance, canUseBalanceYuan } = res.data.data
          this.balance = balance
          this.canUseBalanceYuan = canUseBalanceYuan
        }
      })
    },
    back () {
      this.$router.back()
    },
    getGold (item) {
      this.coinPriceId = item.id
      this.amount = item.price
      this.goldItem = item
    },
    pay (val) {
      this.payIndex = val
    },
    yesBalance () {
      let params = {
        coinPriceId: this.coinPriceId,
        payChannel: 3,
        payMethod: 3
      }
      coinCharge(params).then(res => {
        if (res.data.status === 200) {
          this.balanceVisible = false
          this.getCoinWallet()
          return this.$message({
            type: 'success',
            message: res.data.msg
          })
        }
      })
    },
    toRecharge () {
      if (this.payIndex === 'ye') {
        this.balanceVisible = true
      } else {
        let params = {
          coinPriceId: this.coinPriceId,
          payChannel: this.payIndex === 'zfb' ? 1 : this.payIndex === 'wx' ? 2 : 3,
          payMethod: 3
        }
        coinCharge(params).then(res => {
          if (res.data.status === 200) {
            if (this.payIndex === 'zfb') {
              const win = document.open('', '', 'width=1200,height=845')
              win.document.write(res.data.data.responseBody)
            } else {
              // 支付方式为微信
              let routeUrl = this.$router.resolve({
                path: '/weiXinPay'
              })
              window.open(routeUrl.href + '?codeUrl=' + res.data.data.codeUrl + '&payId=' + res.data.data.payId + '&payType=2&amount=' + this.amount, '_blank');
            }
          }
        })
      }
    }
  }
};
  </script>
  <style lang="less" scoped>
.recharge {
  width: 100%;
  height: 100%;
  position: relative;
  background: #faf8f5 !important;
  &-top {
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 12px rgba(26, 133, 255, 0.04);
    // height: 420px;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 0 24px;
      border-bottom: 1px solid #f7f5f2;
      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        color: rgba(51, 51, 51, 1);
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          margin-right: 20px;
          font-weight: bold;
          padding-right: 20px;
          border-right: 1px solid #ccc;
          cursor: pointer;
        }
        .btn {
          border-radius: 4px;
          background: #ffede0;
          border: 1px solid #ffede0;
          font-size: 16px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
          margin-left: 16px;
        }
      }
    }
    main {
      padding: 40px 48px;
      width: 100%;
      height: calc(100% - 71px);
      overflow: auto;
      .s {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .gold-item {
        width: 290px;
        height: 120px;
        border-radius: 16px;
        background: rgba(245, 245, 245, 1);
        border: 1px solid rgba(221, 221, 221, 1);
        padding: 20px 12px 20px 26px;
        display: flex;
        position: relative;
        margin-bottom: 28px;
        margin-right: 28px;
        cursor: pointer;
        img {
          width: 36px;
          height: 36px;
          margin-right: 18px;
        }
        .gold-num {
          .gold {
            font-size: 30px;
            font-weight: 500;
            line-height: 44px;
            color: rgba(51, 51, 51, 1);
          }
          .money {
            font-size: 22px;
            font-weight: 400;
            line-height: 32px;
            color: rgba(153, 153, 153, 1);
          }
        }
        .send-out {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 96px;
          height: 32px;
          border-radius: 16px 0 12px 0;
          background: rgba(204, 204, 204, 1);
          border: 1px solid rgba(204, 204, 204, 1);
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          line-height: 32px;
          color: rgba(255, 255, 255, 1);
        }
        &.active {
          background: rgba(255, 237, 224, 1);
          border: 2px solid rgba(236, 102, 0, 1);
          .gold-num {
            .gold {
              color: rgba(236, 102, 0, 1);
            }
            .money {
              color: rgba(236, 102, 0, 1);
            }
          }
          .send-out {
            background: rgba(236, 102, 0, 1);
            border: 1px solid rgba(236, 102, 0, 1);
          }
        }
      }
    }
  }
  &-bottom {
    width: 100%;
    height: calc(100% - 440px);
    margin-top: 20px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 12px rgba(26, 133, 255, 0.04);
    padding: 48px 40px;
    .pay-money {
      display: flex;
      align-items: center;
      label {
        width: 90px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: rgba(102, 102, 102, 1);
      }
      .money {
        margin-left: 12px;
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
        color: rgba(236, 102, 0, 1);
      }
      .sent-out {
        margin-left: 12px;
        padding: 2px 8px;
        border-radius: 12px 12px 12px 0px;
        background: rgba(236, 102, 0, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .pay-type {
      display: flex;
      // align-items: center;
      margin-top: 36px;
      label {
        width: 90px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        color: rgba(102, 102, 102, 1);
      }
      .type-item {
        width: 240px;
        height: 64px;
        border-radius: 8px;
        background: rgba(245, 245, 245, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        cursor: pointer;
        &.disabled {
          background-color: #ddd;
        }
        img {
          width: 26px;
          height: 26px;
          margin-right: 18px;
        }
        span {
          font-size: 20px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
        &.active {
          background: rgba(255, 237, 224, 1);
          border: 2px solid rgba(236, 102, 0, 1);
          span {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
    .pay-btn {
      margin-top: 54px;
      padding-left: 90px;
      .btn {
        width: 240px;
        height: 64px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        border: 1px solid rgba(236, 102, 0, 1);
        font-size: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
}
.body {
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
</style>
  