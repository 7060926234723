<template>
  <div class="table-tab">
    <div
      class="tab-item"
      :class="tabIndex === item.id ? 'active' : ''"
      v-for="item in tab"
      :key="item.id"
      @click="toPush(item)"
    >
      {{ item.label }}
    </div>
    <span class="prompt">{{ prompt }}</span>
  </div>
</template>
  
  <script>
export default {
  name: 'TableTab',
  props: {
    tab: {
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      prompt: ''
    };
  },
  watch: {
    tabIndex (val) {
      this.tab.map(item => {
        if (item.id === val) {
          this.prompt = item.prompt
        }
      })
    }
  },
  methods: {
    toPush (item) {
      console.log(item.id);
      this.prompt = item.prompt
      this.$emit("switchTab", item.id)
    }
  }
};
  </script>
  <style lang="less" scoped>
.table-tab {
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 12px;
  .tab-item {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28px;
    color: rgba(51, 51, 51, 1);
    margin-left: 72px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &.active {
      color: rgba(236, 102, 0, 1);
    }
  }
  .prompt {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }
}
</style>
  