<template>
  <div>
    <template v-if="tab === 1">
      <div
        class="list-item"
        v-for="item in plantList"
        :key="item.id"
        @click="onListItem(item)"
      >
        <div class="img">
          <el-image
            v-if="item.coverImg"
            :src="los + item.coverImg + '?' + Date.now()"
            lazy
          ></el-image>
          <el-image v-else :src="require('../../assets/no-img-list.png?')" lazy>
            <div slot="error" class="image-slot">
              <img
                style="width: 100%"
                :src="'../../assets/no-img-list.png' + '?' + Date.now()"
                alt=""
              />
            </div>
          </el-image>
        </div>
        <div class="item-content">
          <div class="item-content-title">
            <div class="p">
              <p>{{ item.title }}</p>
              <span :class="typeFilter(item.status, 'color')">{{
                item.statusName
              }}</span>
              <i
                v-if="item.status === 6"
                class="message el-icon-warning"
                @click.stop="loseMessage(item)"
              ></i>
              <!-- <span :class="typeFilter(item.status, 'color')">已建模</span> -->
            </div>
          </div>
          <div class="item-content-main">
            <div class="label">
              <div class="label-line">
                <span>{{ item.buildingTypeName }}</span>
                <div class="line"></div>
                <span>{{ item.structureTypeName }}</span>
                <div class="line"></div>
                <span>{{ item.floorTypeName }}</span>
              </div>
              <div class="label-line">
                <span style="color: rgba(51, 51, 51, 1)"
                  >{{
                    item.totalArea !== item.minArea
                      ? `${item.minArea}-${item.totalArea}`
                      : item.totalArea
                  }}㎡</span
                >
                <div class="line"></div>
                <!-- <i class="icon icon-plant-details-location"></i> -->
                {{ item.district }}-{{ item.street }}
              </div>
            </div>
          </div>
          <div class="item-content-label">
            <div
              class="item-content-label-item"
              v-for="(i, index) in labelFilter(item.labels)"
              :key="index"
              :title="i"
            >
              {{ i }}
            </div>
          </div>
        </div>
        <div class="count-btn">
          <div class="time" v-if="item.createDate">
            发布于 {{ item.createDate | timeFilter }}
          </div>
          <div class="time" v-else>无数据</div>
          <div class="count" :title="item.count">{{ item.price }}元/㎡/月</div>
          <div class="btn">
            <!-- <div class="btn-item">房源建模</div> -->
            <div
              class="btn-item"
              v-if="item.status === 2 || item.status === 5"
              @click="$emit('setStatus', item, 0)"
            >
              房源转录
            </div>
            <div
              class="btn-item"
              v-if="item.status === 2"
              @click="$emit('setStatus', item, 5)"
            >
              成交房源
            </div>
            <div
              class="btn-item"
              v-if="item.status === 2 || item.status === 3 || item.status === 6"
              @click="edmitHouse(item)"
            >
              修改房源
            </div>
            <div class="btn-item" @click="lookHouse(item)">查看房源</div>
            <div
              class="btn-item"
              v-if="item.status === 2"
              @click="$emit('setStatus', item, 3)"
            >
              下架房源
            </div>
            <div
              class="btn-item"
              v-if="item.status === 3 || item.status === 5"
              @click="$emit('setStatus', item, 2)"
            >
              再次发布
            </div>
            <div
              class="btn-item"
              v-if="
                item.status === 1 ||
                item.status === 3 ||
                item.status === 5 ||
                item.status === 6
              "
              @click="$emit('setStatus', item, 4)"
            >
              删除房源
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="tab === 3">
      <div
        class="list-item"
        v-for="item in plantList"
        :key="item.id"
        @click="onListItem(item)"
      >
        <div class="img">
          <el-image
            v-if="item.coverImg"
            :src="los + item.coverImg + '?' + Date.now()"
            lazy
          ></el-image>
          <el-image v-else :src="require('../../assets/no-img-list.png')" lazy>
            <div slot="error" class="image-slot">
              <img
                style="width: 100%"
                :src="'../../assets/no-img-list.png' + '?' + Date.now()"
                alt=""
              />
            </div>
          </el-image>
        </div>
        <div class="item-content">
          <div class="item-content-title">
            <div class="p">
              <p>{{ item.title }}</p>
              <span :class="typeFilter(item.status, 'color')">{{
                item.statusName
              }}</span>
              <i
                v-if="item.status === 6"
                class="message el-icon-warning"
                @click.stop="loseMessage(item)"
              ></i>
              <!-- <span :class="typeFilter(item.status, 'color')">已建模</span> -->
            </div>
          </div>
          <div class="item-content-main">
            <div class="label">
              <div class="label-line">
                <span>{{ item.decorationName }}</span>
                <div class="line"></div>
                <span>朝向{{ item.orientationName }}</span>
                <div class="line"></div>
                <span>{{ item.floor }}层</span>
              </div>
              <div class="label-line">
                <span style="color: rgba(51, 51, 51, 1)"
                  >{{ item.totalArea }}㎡</span
                >
                <div class="line"></div>
                <!-- <i class="icon icon-plant-details-location"></i> -->
                {{ item.district }}-{{ item.street }}-{{ item.buildingName }}
              </div>
            </div>
          </div>
          <div class="item-content-label">
            <div
              class="item-content-label-item"
              v-for="(i, index) in labelFilter(item.labels)"
              :key="index"
              :title="i"
            >
              {{ i }}
            </div>
          </div>
        </div>
        <div class="count-btn">
          <div class="time" v-if="item.time">
            发布于 {{ item.time | timeFilter }}
          </div>
          <div class="time" v-else>无数据</div>
          <div class="count" :title="item.count">{{ item.price }}元/㎡/月</div>
          <div class="btn">
            <!-- <div class="btn-item">房源建模</div> -->
            <div
              class="btn-item"
              v-if="item.status === 2 || item.status === 5"
              @click="$emit('setStatus', item, 0)"
            >
              房源转录
            </div>
            <div
              class="btn-item"
              v-if="item.status === 2"
              @click="$emit('setStatus', item, 5)"
            >
              成交房源
            </div>
            <div
              class="btn-item"
              v-if="item.status === 2 || item.status === 3 || item.status === 6"
              @click="edmitHouse(item)"
            >
              修改房源
            </div>
            <div class="btn-item" @click="lookHouse(item)">查看房源</div>
            <div
              class="btn-item"
              v-if="item.status === 2"
              @click="$emit('setStatus', item, 3)"
            >
              下架房源
            </div>
            <div
              class="btn-item"
              v-if="item.status === 3 || item.status === 5"
              @click="$emit('setStatus', item, 2)"
            >
              再次发布
            </div>
            <div
              class="btn-item"
              v-if="
                item.status === 1 ||
                item.status === 3 ||
                item.status === 5 ||
                item.status === 6
              "
              @click="$emit('setStatus', item, 4)"
            >
              删除房源
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
  <script>
const los = window.los
export default {
  name: 'HouseListItem',
  props: {
    plantList: {
      type: Array,
      default: () => []
    },
    tab: {
      type: Number,
      default: 1
    }
  },
  components: {},
  data () {
    return {
      los,
      activeId: ''
    }
  },
  filters: {
    timeFilter (val) {
      if (val) {
        let now = new Date(val)
        let y = now.getFullYear()
        let m = now.getMonth() + 1
        let d = now.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
        //  + " " + now.toTimeString().substr(0, 8)
      }
    }
  },
  mounted () { },
  methods: {
    typeFilter (val, type) {
      if (type !== 'color') {
        switch (val) {
          case 1:
            return '待审核';
          case 2:
            return '已上线';
          case 3:
            return '已下线';
          case 5:
            return '已成交';
          case 6:
            return '审核失败';
        }
      } else {
        switch (val) {
          case 2:
            return 'b-6D57FF';
          case 3:
            return 'b-999';
          case 5:
            return 'b-3EB012';
          case 1:
            return 'b-FFB031';
          case 6:
            return 'b-D93B3B';
        }
      }
    },
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    onListItem (item) {
      this.activeId = item.id
    },
    loseMessage (item) {
      this.$emit('loseMessage', item)
    },
    edmitHouse (item) {
      let path = ''
      if (this.tab === 1) {
        path = '/publishHousing'
      }
      if (this.tab === 3) {
        path = '/publishHousingOffice'
      }
      this.$router.push({
        path,
        query: {
          id: item.id
        }
      })
    },
    lookHouse (item) {
      if (this.tab === 1) window.open(window.plantIp + "/#/brokerDetails/" + item.id + '?broker=' + localStorage.token, '_blank');
      if (this.tab === 3) window.open(window.officeIp + "/#/brokerDetails/" + item.id + '?broker=' + localStorage.token, '_blank');
    }
  }
};
  </script>
  <style lang="less" scoped>
.list-item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-right: 20px;
  cursor: pointer;
  &:hover {
    background-color: #fff9f2;
  }
  .img {
    width: 160px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-right: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item-content {
    width: calc(60% - 184px);
    height: 35%;
    box-sizing: border-box;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .p {
        display: flex;
        align-items: center;
        width: calc(100% - 120px);
        p {
          max-width: calc(100% - 140px);
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;
        }
        span {
          display: inline-block;
          max-width: 74px;
          padding: 0 8px;
          text-align: center;
          border-radius: 0px 12px 12px 12px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          height: 20px;
          color: rgba(255, 255, 255, 1);
        }
      }
      .message {
        color: red;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;
      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &-line {
          display: flex;
          align-items: center;
          justify-content: start;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(153, 153, 153, 1);
          &:first-child {
            margin-bottom: 4px;
          }
          span {
            font-size: 14px;
          }
          .line {
            width: 1px;
            height: 14px;
            background-color: rgba(221, 221, 221, 1);
            margin: 0 10px;
          }
        }
      }
    }
    &-label {
      display: flex;
      align-items: center;
      &-item {
        padding: 3px 10px;
        border-radius: 2px;
        background: rgba(245, 244, 243, 1);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .count-btn {
    width: 40%;
    .time {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(153, 153, 153, 1);
      text-align: end;
      margin-bottom: 9px;
    }
    .count {
      margin-left: 38px;
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      text-align: end;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 25px;
    }
    .btn {
      display: none;
      align-items: center;
      justify-content: end;
      &-item {
        width: 94px;
        height: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #fffaf2;
        border: 1px solid #ec6600;
        font-size: 14px;
        font-weight: 400;
        color: #ec6600;
        cursor: pointer;
        margin-left: 16px;
      }
    }
  }
  &:hover {
    .btn {
      display: flex;
    }
  }
  &.active {
    background-color: #fff9f2;
  }
}

// 已上线
.b-6D57FF {
  background-color: #6d57ff;
}
// 审核中
.b-FFB031 {
  background-color: #ffb031;
}
// 已成交
.b-3EB012 {
  background-color: #3eb012;
}
// 已下架
.b-999 {
  background-color: #999;
}
// 审核失败
.b-D93B3B {
  background-color: #d93b3b;
}
</style>
    